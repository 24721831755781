import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getClassTimeTable,
  getSchoolLevelGradePeriods,
  generateClassTimeTable,
} from "../store/actions";

import types from "../store/types";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

const ClassTimeTableDialog = (props) => {
  const {
    store,
    schoolLevel,
    schoolLevelGrade,
    classRoom,
    isTimeTableDialogOpen,
    setIsTimeTableDialogOpen,
    getClassTimeTable,
    getSchoolLevelGradePeriods,
    generateClassTimeTable,
  } = props;

  const dispatch = useDispatch();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isTimeTableDialogOpen) {
      getSchoolLevelGradePeriods(schoolLevelGrade.id);
      getClassTimeTable(classRoom.id);
    }
  }, [isTimeTableDialogOpen]);

  const getTimeTableSubject = (period, dayId) => {
    const timeTableSubject = {
      name: "",
      teacher: "",
    };

    if (period.periodTypeId === 2) timeTableSubject.name = "Break";
    else if (period.periodTypeId === 3 && schoolLevel.levelId === 1)
      timeTableSubject.name = "Snack Time";
    else if (period.periodTypeId === 3 && schoolLevel.levelId !== 1)
      timeTableSubject.name = "Lunch";
    else if (!!store.appData.classTimeTable) {
      const item = store.appData.classTimeTable.find(
        (classTimeTable) =>
          classTimeTable.period.id === period.id &&
          classTimeTable.dayId === dayId
      );
      if (!!item) {
        timeTableSubject.name = item.subject.name;
        timeTableSubject.teacher =
          item.teacher.firstName + " " + item.teacher.lastName;
      }
    }

    return timeTableSubject;
  };

  return (
    <Dialog open={isTimeTableDialogOpen} fullScreen ref={componentRef}>
      <style type="text/css" media="print">
        {" @page { size: landscape; } "}
      </style>
      <DialogTitle style={{ backgroundColor: "#0f7197", color: "white" }}>
        <span>
          {schoolLevel.level.name}
          {schoolLevelGrade.grade.name}
          {classRoom.name} TimeTable |{" "}
          <Chip
            variant="outlined"
            className="text-light mr-3"
            avatar={
              <Avatar alt="school">
                <span className="material-icons text-light">school</span>
              </Avatar>
            }
            label={store.selectedSchool.name.toUpperCase()}
          />
          <Button
            variant="contained"
            className="d-print-none mr-2"
            color="info"
            size="small"
            onClick={() => generateClassTimeTable(classRoom.id)}
            disabled={store.isLoading}
          >
            Generate Timetable
          </Button>
          <button
            disabled={store.isLoading}
            onClick={handlePrint}
            className="d-print-none btn btn-sm"
          >
            <span className="material-icons">print</span>
          </button>
        </span>

        <IconButton
          className="close-btn mr-3 d-print-none"
          onClick={() => {
            dispatch({
              type: types.SET_CLASS_TIMETABLE,
              data: [],
            });
            setIsTimeTableDialogOpen(false);
          }}
        >
          <span className="material-icons text-white">close</span>
        </IconButton>
        <span className="close-btn d-none d-print-inline">
          <strong className="mr-4">
            <span style={{ color: "#4bb9dd" }}>Keza</span>Class
          </strong>
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="container-fluid pt-2">
          <div className="row d-flex justify-content-center">
            <div className="col-2 text-center time-table-header period display-4">
              Period
            </div>
            <div className="col-2 text-center time-table-header display-4">
              Mo
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Tu
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              We
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Th
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Fr
            </div>
          </div>

          {!!store.appData.gradePeriods &&
            store.appData.gradePeriods.map((gradePeriod, index) => (
              <div
                className="row d-flex justify-content-center"
                key={gradePeriod.id}
              >
                <div
                  className={`col-2  text-center time-table-body-period display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {gradePeriod.period.startAt} - {gradePeriod.period.endAt}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(gradePeriod.period, 1).name}{" "}
                  {!!getTimeTableSubject(gradePeriod.period, 1).teacher && (
                    <span className="teacher-name">
                      <i className="fas fa-user text-info"></i>{" "}
                      {getTimeTableSubject(gradePeriod.period, 1).teacher}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(gradePeriod.period, 2).name}{" "}
                  {!!getTimeTableSubject(gradePeriod.period, 2).teacher && (
                    <span className="teacher-name">
                      <i className="fas fa-user text-info"></i>{" "}
                      {getTimeTableSubject(gradePeriod.period, 2).teacher}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(gradePeriod.period, 3).name}{" "}
                  {!!getTimeTableSubject(gradePeriod.period, 3).teacher && (
                    <span className="teacher-name">
                      <i className="fas fa-user text-info"></i>{" "}
                      {getTimeTableSubject(gradePeriod.period, 3).teacher}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(gradePeriod.period, 4).name}{" "}
                  {!!getTimeTableSubject(gradePeriod.period, 4).teacher && (
                    <span className="teacher-name">
                      <i className="fas fa-user text-info"></i>{" "}
                      {getTimeTableSubject(gradePeriod.period, 4).teacher}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    gradePeriod.period.periodTypeId === 1
                      ? "teaching"
                      : gradePeriod.period.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(gradePeriod.period, 5).name}{" "}
                  {!!getTimeTableSubject(gradePeriod.period, 5).teacher && (
                    <span className="teacher-name">
                      <i className="fas fa-user text-info"></i>{" "}
                      {getTimeTableSubject(gradePeriod.period, 5).teacher}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-3"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getClassTimeTable,
  getSchoolLevelGradePeriods,
  generateClassTimeTable,
})(ClassTimeTableDialog);
