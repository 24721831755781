import _ from "lodash";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SchoolLevel from "../components/SchoolLevelCard";
import LevelFormDialog from "../components/LevelFormDialog";
import {
  getLevels,
  getSelectedSchool,
  getSchoolSbjects,
  removeSchoolSubject,
} from "../store/actions";
import { Link } from "react-router-dom";
import SubjectFormDialog from "../components/SubjectFormDialog";

const Subjects = (props) => {
  const {
    store,
    getLevels,
    getSelectedSchool,
    getSchoolSbjects,
    removeSchoolSubject,
  } = props;

  const [levelFormDialogOpen, setLevelFormDialogOpen] = useState(false);
  const handleLevelFormDialogOpen = () => {
    setLevelFormDialogOpen(true);
  };
  const handleLevelFormDialogClose = () => {
    setSubject({
      id: null,
      schoolId: store.selectedSchool.id,
      name: "",
      subjectTypeId: "",
      periods: 0,
    });
    setLevelFormDialogOpen(false);
  };

  useEffect(() => {
    // getLevels();
    if (store.selectedSchool.id) getSchoolSbjects(store.selectedSchool.id);
  }, [store.selectedSchool]);

  const [subject, setSubject] = useState({
    id: null,
    schoolId: store.selectedSchool.id,
    name: "",
    subjectTypeId: "",
    periods: 0,
  });

  useEffect(() => {
    if (store.selectedSchool.id)
      setSubject({
        // ...subject,
        id: subject.id,
        schoolId: subject.schoolId,
        name: subject.name,
        subjectTypeId: subject.subjectTypeId,
        periods: subject.periods || 0,
        schoolId: store.selectedSchool.id,
      });
  }, [store.selectedSchool]);

  const filterSchoolSubjects = (schoolLevel) => {
    if (!!store.appData.schoolSubjects)
      return store.appData.schoolSubjects.filter(
        ({ schoolLevelId }) => schoolLevelId === schoolLevel.id
      );
    else return [];
  };

  const [selectedSchoolLevel, setSelectedSchoolLevel] = useState(null);

  return (
    <>
      <div className="row mt-2 d-flex justify-content-center  ">
        {store &&
          store.selectedSchool.schoolLevels &&
          store.selectedSchool.schoolLevels.map((schoolLevel, index) => (
            <div
              key={"schoolLevel-" + schoolLevel.id.toString()}
              className={`mb-4 col-12 ${
                store.selectedSchool.schoolLevels.length === 4
                  ? "col-lg-3"
                  : store.selectedSchool.schoolLevels.length === 3
                  ? "col-lg-4"
                  : store.selectedSchool.schoolLevels.length === 2
                  ? "col-lg-6"
                  : ""
              }  `}
            >
              <Card variant="outlined" className="level-card mt-2 mb-3">
                <div
                  className=" d-flex align-items-center text-white p-2"
                  style={{ backgroundColor: "#0f7197" }}
                >
                  <Typography
                    sx={{ fontSize: 16 }}
                    className="pl-3"
                    gutterBottom
                  >
                    {store && store.appData.schoolSubjects && (
                      <strong>
                        {filterSchoolSubjects(schoolLevel).length}{" "}
                      </strong>
                    )}{" "}
                    {schoolLevel.level.name} subjects
                  </Typography>
                  <Button
                    variant="contained"
                    color="info"
                    className="ml-2"
                    size="small"
                    onClick={() => {
                      handleLevelFormDialogOpen();
                      setSelectedSchoolLevel(schoolLevel);
                    }}
                  >
                    Add Subject
                  </Button>
                </div>
                <Divider />
                <CardContent>
                  <List>
                    <Divider />

                    {store &&
                      store.appData.schoolSubjects &&
                      filterSchoolSubjects(schoolLevel).map(
                        (subject, index) => (
                          <SubjectListItem
                            setSubject={setSubject}
                            subject={subject}
                            key={subject.id}
                            index={index}
                            schoolLevel={schoolLevel}
                            setLevelFormDialogOpen={setLevelFormDialogOpen}
                            removeSchoolSubject={removeSchoolSubject}
                            setSelectedSchoolLevel={setSelectedSchoolLevel}
                          />
                        )
                      )}
                  </List>
                  {store && !filterSchoolSubjects(schoolLevel).length && (
                    <div className="text-center">No subject found</div>
                  )}
                </CardContent>
              </Card>
            </div>
          ))}
      </div>

      <SubjectFormDialog
        selectedSchoolLevel={selectedSchoolLevel}
        // setSelectedSchoolLevel={setSelectedSchoolLevel}
        handleLevelFormDialogClose={handleLevelFormDialogClose}
        levelFormDialogOpen={levelFormDialogOpen}
        subject={subject}
        setSubject={setSubject}
      />

      {/* {store &&
        store.selectedSchool.schoolLevels &&
        store.selectedSchool.schoolLevels.length && (
          <div className="fab-container pr-3 pb-3">
            <Tooltip title="Add new Subject">
              <span className="fab-btn">
                <Fab color="primary" onClick={handleLevelFormDialogOpen}>
                  <span className="material-icons">add</span>
                </Fab>
              </span>
            </Tooltip>
          </div>
        )} */}
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getLevels,
  getSelectedSchool,
  getSchoolSbjects,
  removeSchoolSubject,
})(Subjects);

const SubjectListItem = (props) => {
  const {
    subject,
    index,
    setLevelFormDialogOpen,
    setSubject,
    removeSchoolSubject,
    schoolLevel,
    setSelectedSchoolLevel,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderWeight = (subject) => {
    return (
      <>
        <span className="mr-2 badge badge-primary">
          Weight: {subject.weight}%
        </span>
        <span className="mr-2 mt-1 badge badge-info">
          {subject.periods} Periods
        </span>
      </>
    );
    // if (subject.schoolLevel.level.id === 1)
    //   return (
    //     <>
    //       <span className="mr-2 badge badge-primary">
    //         Weight: {subject.weight}%
    //       </span>
    //       <span className="mr-2 mt-1 badge badge-info">
    //         {subject.periods} Periods
    //       </span>
    //     </>
    //   );

    // if (subject.schoolLevel.level.id === 2)
    //   return (
    //     <>
    //       <span className="mr-2 badge badge-primary">
    //         Weight: {subject.lowerPrimaryWeight}%
    //       </span>
    //       <span className="mr-2 mt-1 badge badge-info">
    //         {subject.lowerPrimaryPeriods} Periods
    //       </span>
    //     </>
    //   );

    // if (subject.schoolLevel.level.id === 3)
    //   return (
    //     <>
    //       <span className="mr-2 badge badge-primary">
    //         Weight: {subject.upperPrimaryWeight}%
    //       </span>
    //       <span className="mr-2 mt-1 badge badge-info">
    //         {subject.upperPrimaryPeriods} Periods
    //       </span>
    //     </>
    //   );

    // if (subject.schoolLevel.level.id === 4)
    //   return (
    //     <>
    //       <span className="mr-2 badge badge-primary">
    //         Weight: {subject.lowerSecondaryWeight}%
    //       </span>
    //       <span className="mr-2 mt-1 badge badge-info">
    //         {subject.lowerSecondaryPeriods} Periods
    //       </span>
    //     </>
    //   );

    // if (subject.schoolLevel.level.id === 5)
    //   return (
    //     <>
    //       <span className="mr-2 badge badge-primary">
    //         Weight: {subject.upperSecondaryWeight || 0}%
    //       </span>
    //       <span className="mr-2 mt-1 badge badge-info">
    //         {subject.upperSecondaryPeriods} Periods
    //       </span>
    //     </>
    //   );
  };

  return (
    <React.Fragment>
      <ListItem
        className="mb-1 mt-1"
        style={{
          backgroundColor: "#e4eef5",
        }}
      >
        <ListItemAvatar>
          <Avatar>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<span className="mr-2">{subject.name}</span>}
          secondary={
            <span className="d-flex align-items-center flex-wrap">
              <span
                className={`mr-2 badge badge-${
                  subject.subjectType.name === "Core"
                    ? "success"
                    : subject.subjectType.name === "Elective"
                    ? "primary"
                    : "dark"
                }`}
              >
                {subject.subjectType.name}
              </span>
              {renderWeight(subject)}
            </span>
          }
        />
        <Box className="d-flex align-items-center">
          <IconButton
            className=""
            onClick={handleOpenMenu}
            aria-label="settings"
            size="small"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                setSubject({
                  id: subject.id,
                  schoolId: subject.schoolId,
                  name: subject.name,
                  subjectTypeId: subject.subjectTypeId,
                  periods: subject.periods || 0,
                });
                setSelectedSchoolLevel(subject.schoolLevel);
                handleCloseMenu();
                setLevelFormDialogOpen(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">edit</span> Edit
            </MenuItem>
            {!!subject.schoolId && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    removeSchoolSubject(subject.id);
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-2">delete</span> Delete
                </MenuItem>
              </span>
            )}
          </Menu>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
