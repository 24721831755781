import { toast } from "react-toastify";
import axios from "axios";

export const defaultState = {
  auth: {
    isAuthenticated: false,
    user: {},
    token: null,
  },
  isLoading: false,
  lookups: {},
  appData: {},
  selectedSchool: {},
};

export const onSuccess = (data = "") => {
  if (data.message) return toast.success(data.message);
  toast.success("success");
};

export const onError = (error = "") => {
  if (error.response) return toast.error(error.response.data.message);
  if (error.request) return toast.error(error.request.message);
  toast.error(error.message);
};

export const getAuthState = () => {
  const auth = JSON.parse(localStorage.getItem("auth-user"));
  if (!auth) return defaultState.auth;

  try {
    //  const {user,token} = auth;

    return { isAuthenticated: true, ...auth };
  } catch (error) {
    return defaultState.auth;
  }
};

export const setAuthorizationToken = (token) => {
  if (token)
    return (axios.defaults.headers.common["Authorization"] = `Bearer ${token}`);
  delete axios.defaults.headers.common["Authorization"];
};
