import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Drawer from "./components/Drawer";
import Header from "./components/Header";
import Attendances from "./pages/Attendances";
import Security from "./pages/Security";
import TimeTables from "./pages/TimeTables";
import Settings from "./pages/Settings";
import Footer from "./components/Footer";
import { isEmpty } from "lodash/isEmpty";

import { getSelectedSchool } from "./store/actions";
import Dashboard from "./pages/Dashboard";
import ClassSettings from "./pages/ClassSettings";
import ClassAttendances from "./pages/ClassAttendances";
import SchoolAttendances from "./pages/SchoolAttendances";
import Profile from "./pages/Profile";

const Layout = (props) => {
  const { store, getSelectedSchool } = props;

  const [drawerState, setDrawerState] = useState(false);

  return (
    <React.Fragment>
      <Header drawerState={drawerState} setDrawerState={setDrawerState} />
      <Drawer drawerState={drawerState} setDrawerState={setDrawerState} />
      <div className="container-fluid">
        {store.selectedSchool && (
          <Switch>
            <Route path="/in/dashboard" component={Dashboard} />
            <Route path="/in/time-tables" component={TimeTables} />
            {/* <Route path="/in/class-attendances" component={ClassAttendances} /> */}
            <Route
              path="/in/school-attendances"
              component={SchoolAttendances}
            />
            <Route path="/in/settings" component={Settings} />
            <Route path="/in/class-settings" component={ClassSettings} />
            <Route path="/in/security" component={Security} />
            <Route path="/in/profile" component={Profile} />
            {/* <Route
              path="/in"
              component={() => <Redirect to="/in/settings" />}
            /> */}
          </Switch>
        )}

        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { getSelectedSchool })(Layout);
