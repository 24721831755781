import React, { useEffect } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";

import Layout from "./Layout";
import Welcome from "./pages/Welcome";

import { getSelectedSchool } from "./store/actions";

function App(props) {
  const { isLoading, auth, getSelectedSchool } = props;

  useEffect(() => {
    if (auth && auth.user && auth.user.schools)
      getSelectedSchool(auth.user.schools[0].id);
  }, [auth]);

  TopBarProgress.config({
    barColors: {
      0: "#f00",
      0.5: "#f00",
      1.0: "#f00",
    },
    shadowBlur: 5,
  });

  return (
    <React.Fragment>
      {isLoading && <TopBarProgress />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover
      />
      <Switch>
        <Route path="/in" component={Layout} />
        <Route path="/" component={Welcome} />
      </Switch>
    </React.Fragment>
  );
}

const mapStateToProps = ({ isLoading, auth }) => {
  return { isLoading, auth };
};

export default connect(mapStateToProps, { getSelectedSchool })(App);
