import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import DailyTimaTableCard from "../components/DailyTimaTableCard";

import { connect } from "react-redux";
import { getDailyTeacherPeriods } from "../store/actions";

const MyDailyTimatable = (props) => {
  const { store, getDailyTeacherPeriods } = props;

  useEffect(() => {
    getDailyTeacherPeriods();
  }, []);
  return (
    <>
      <div className="row justify-content-center">
        {store &&
          store.appData.dailyTeacherPeriods &&
          store.appData.dailyTeacherPeriods.map((period, index) => (
            <div
              key={period.dayId.toString() + "-" + period.periodId.toString()}
              className="col-12 col-md-6 col-lg-4 col-xl-3"
            >
              <DailyTimaTableCard period={period} />
            </div>
          ))}
        {store &&
          store.appData.dailyTeacherPeriods &&
          !store.appData.dailyTeacherPeriods.length && (
            <div>No period found</div>
          )}
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getDailyTeacherPeriods,
})(MyDailyTimatable);
