import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  getClassStudents,
  removeStudent,
  uploadClassStudents,
} from "../store/actions";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import AttendanceDetailCard from "./AttendanceDetailCard";
import StudentFormDialog from "./StudentFormDialog";
import STUDENTS_TEMPLATE from "../assets/Students_Template.xlsx";
import UploadExcelFile from "./UploadExcelFile";
import { onError } from "../store/utils";
import readXlsxFile from "read-excel-file";

const ClassStudents = (props) => {
  const {
    store,
    isDialogOpen,
    closeDialog,
    teacherClass,
    getClassStudents,
    removeStudent,
    uploadClassStudents,
  } = props;

  const [openStudentFormDialog, setOpenStudentFormDialog] = useState(false);

  useEffect(() => {
    if (isDialogOpen) getClassStudents(teacherClass.classId || teacherClass.id);
  }, [isDialogOpen]);

  const maxDateOfBirth = new Date();

  maxDateOfBirth.setFullYear(maxDateOfBirth.getFullYear() - 2);

  const [formData, setFormData] = useState({
    id: null,
    sdmsStaffCode: null,
    firstName: "",
    lastName: "",
    genderId: "",
    dateOfBirth: maxDateOfBirth,
    guardianFirstName: "",
    guardianLastName: "",
    guardianPhone: "",
    guardianEmail: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const sortStudents = () => {
    return store.appData.classStudents.sort((a, b) =>
      (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)
    );
  };

  const [file, setFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const doUploadFile = async () => {
    const dataToUpload = await readXlsxFile(file).then((rows) => {
      let tempData = [];

      if (!!rows.length) {
        const header = rows[0];

        if (
          header &&
          header[0] &&
          header[0].toLowerCase().includes("sdmsstaffcode") &&
          header[1] &&
          header[1].toLowerCase().includes("lastname") &&
          header[2] &&
          header[2].toLowerCase().includes("firstname") &&
          header[3] &&
          header[3].toLowerCase().includes("dateofbirth") &&
          header[4] &&
          header[4].toLowerCase().includes("gender") &&
          header[5] &&
          header[5].toLowerCase().includes("guardianlastname") &&
          header[6] &&
          header[6].toLowerCase().includes("guardianfirstname") &&
          header[7] &&
          header[7].toLowerCase().includes("guardianphone") &&
          header[8] &&
          header[8].toLowerCase().includes("guardianemail")
        ) {
          rows.shift();
          rows.forEach((cols) => {
            const item = {
              classId: teacherClass.classId,
              sdmsStaffCode: cols[0] && cols[0] !== "" ? parseInt(cols[0]) : "",
              lastName: cols[1] && cols[1] !== "" ? cols[1].toString() : "",
              firstName: cols[2] && cols[2] !== "" ? cols[2].toString() : "",
              dateOfBirth:
                cols[3] && cols[3] !== "" ? new Date(cols[3].toString()) : null,
              genderId: cols[4] && cols[4] !== "" ? cols[4].toString() : "",
              guardianLastName:
                cols[5] && cols[5] !== "" ? cols[5].toString() : "",
              guardianFirstName:
                cols[6] && cols[6] !== "" ? cols[6].toString() : "",
              guardianPhone:
                cols[7] && cols[7] !== "" ? cols[7].toString() : "",
              guardianEmail:
                cols[8] && cols[8] !== "" ? cols[8].toString() : "",
            };
            tempData.push(item);
          });
        } else {
          onError({ message: "Invalid excel file format" });
          return false;
        }
      } else {
        onError({ message: "Invalid excel file format" });

        return false;
      }

      return tempData;
    });

    if (!!dataToUpload && !!dataToUpload.length)
      uploadClassStudents({ data: dataToUpload }, setShowDialog);
  };

  return (
    <>
      <Dialog open={isDialogOpen} fullWidth maxWidth="md" scroll="paper">
        <DialogTitle className="text-primary d-inline-flex align-items-center">
          <span>Students</span>{" "}
          <span className="text-dark d-none d-md-inline mr-2">
            {" "}
            @{teacherClass.levelName || teacherClass.level.name}{" "}
            {teacherClass.gradeId || teacherClass.grade.id}
            {teacherClass.className || teacherClass.name}
          </span>
          <span className="text-dark d-inline d-md-none mr-2 ">
            {" "}
            @{teacherClass.acronym || teacherClass.level.acronym}
            {teacherClass.gradeId || teacherClass.grade.id}
            {teacherClass.className || teacherClass.name}
          </span>
          {!!teacherClass.classId && (
            <span className="d-inline-flex align-items-center">
              <a
                className="d-inline-flex align-items-center mr-2"
                href={STUDENTS_TEMPLATE}
              >
                <span className="material-icons">file_download</span>
                <span className="d-none d-md-inline">Download template</span>
              </a>
              <Button
                variant="contained"
                size="small"
                component="label"
                color="secondary"
                onClick={() => setShowDialog(true)}
              >
                <span className="material-icons">file_upload</span>
                <span className="d-none d-md-inline"> Upload Students</span>
              </Button>
            </span>
          )}
          <IconButton className="close-btn mr-3" onClick={closeDialog}>
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "70vh" }}>
          <List>
            <Divider />

            {!!store.appData.classStudents &&
              sortStudents().map((student) => (
                <ClassStudentListItem
                  key={student.id}
                  student={student}
                  teacherClass={teacherClass}
                  removeStudent={removeStudent}
                  setFormData={setFormData}
                  setIsEditing={setIsEditing}
                  setOpenStudentFormDialog={setOpenStudentFormDialog}
                />
              ))}
          </List>

          {store &&
            store.appData.classStudents &&
            !store.appData.classStudents.length && (
              <div className="text-center">No student found</div>
            )}
        </DialogContent>
        <DialogActions>
          {!!teacherClass.classId && (
            <Button
              variant="contained"
              className="d-inline-flex "
              color="info"
              size="small"
              onClick={() => setOpenStudentFormDialog(true)}
            >
              <span className="material-icons ">person_add_alt_1</span>
              <span className="d-none d-md-inline ml-1">Add New</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <StudentFormDialog
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        teacherClass={teacherClass}
        openStudentFormDialog={openStudentFormDialog}
        setOpenStudentFormDialog={setOpenStudentFormDialog}
        maxDateOfBirth={maxDateOfBirth}
      />

      {showDialog && (
        <UploadExcelFile
          file={file}
          setFile={setFile}
          setShowDialog={setShowDialog}
          showDialog={showDialog}
          onSave={() => doUploadFile()}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getClassStudents,
  removeStudent,
  uploadClassStudents,
})(ClassStudents);

const ClassStudentListItem = (props) => {
  const {
    student,
    teacherClass,
    removeStudent,
    setFormData,
    setIsEditing,
    setOpenStudentFormDialog,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar
            alt={student.firstName}
            sx={{ width: 50, height: 50 }}
            src={
              student.profilePicture
                ? `data:image/png;base64,${student.profilePicture}`
                : defaultProfile
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {student.firstName} {student.lastName}
            </span>
          }
          secondary={
            <span>
              {student.guardianPhone}
              {student.isActive && (
                <span className="badge badge-success ml-1">Active</span>
              )}
            </span>
          }
        />

        <Box>
          {!!student.sdmsStaffCode && (
            <Tooltip title="SDMS Staff Code">
              <Chip
                style={{ borderRadius: "0px" }}
                variant="outlined"
                className="small-chip ml-2 py-0"
                size="small"
                label={student.sdmsStaffCode}
              />
            </Tooltip>
          )}
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                setFormData({
                  ...student,
                  dateOfBirth: new Date(student.dateOfBirth),
                });

                setIsEditing(true);

                setOpenStudentFormDialog(true);
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">visibility</span>{" "}
              Details/Update
            </MenuItem>
            <Divider className="my-1" />

            {/* <MenuItem
              disabled
              onClick={() => {
                handleCloseMenu();
                // openDialog();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">arrow_outward</span>{" "}
              Transfer
            </MenuItem>
            <Divider className="my-1" /> */}

            {!!teacherClass.classId && (
              <MenuItem
                onClick={() => {
                  removeStudent(
                    student.id,
                    teacherClass.classId || teacherClass.id,
                    handleCloseMenu
                  );
                }}
                className="text-danger font-weight-light"
              >
                <span className="material-icons mr-2">delete</span> Delete
              </MenuItem>
            )}
          </Menu>
        </Box>
      </ListItem>
      <Divider className="my-1" />

      {/* <ClassStudents
        teacherclassName={teacherClass}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
      /> */}
    </React.Fragment>
  );
};
