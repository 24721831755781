import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  getWeeklyTeacherTimeTable,
  getSchoolLevelGradePeriods,
  generateClassTimeTable,
  getTeacherPeriods,
} from "../store/actions";

import types from "../store/types";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

const MyWeeklyTimeTable = (props) => {
  const {
    store,
    schoolLevel,
    schoolLevelGrade,
    classRoom,
    isTimeTableDialogOpen,
    setIsTimeTableDialogOpen,
    getWeeklyTeacherTimeTable,
    getSchoolLevelGradePeriods,
    generateClassTimeTable,
    getTeacherPeriods,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!store.selectedSchool.id) {
      getTeacherPeriods(store.selectedSchool.id);
      getWeeklyTeacherTimeTable(store.selectedSchool.id);
    }
  }, [store.selectedSchool]);

  // useEffect(() => {
  //   if (isTimeTableDialogOpen) {
  //     // console.log(classRoom);
  //     getSchoolLevelGradePeriods(schoolLevelGrade.id);
  //     getWeeklyTeacherTimeTable(classRoom.id);
  //   }
  // }, [isTimeTableDialogOpen]);

  const getTimeTableSubject = (period, dayId) => {
    const timeTableSubject = {
      name: "",
      class: "",
    };

    if (period.periodTypeId === 2) timeTableSubject.name = "Break";
    else if (period.periodTypeId === 3 && period.schoolLevel.levelId === 1)
      timeTableSubject.name = "Snack Time";
    else if (period.periodTypeId === 3 && period.schoolLevel.levelId !== 1)
      timeTableSubject.name = "Lunch";
    else if (!!store.appData.teacherTimeTable) {
      const itemIndex = store.appData.teacherTimeTable.findIndex(
        (teacherTimeTable) =>
          teacherTimeTable.period.id === period.id &&
          teacherTimeTable.dayId === dayId
      );

      if (itemIndex >= 0) {
        const item = store.appData.teacherTimeTable[itemIndex];
        timeTableSubject.name = item.subject.name;
        timeTableSubject.class =
          "[ " +
          item.class.schoolLevelGrade.schoolLevel.level.name +
          " " +
          item.class.schoolLevelGrade.gradeId +
          item.class.name +
          " ]";
      }
    }

    return timeTableSubject;
  };

  return (
    <Card variant="outlined">
      <div
        className=" d-flex align-items-center text-white p-2"
        style={{ backgroundColor: "#0f7197" }}
      >
        <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
          <strong>TimeTable</strong>
        </Typography>
      </div>
      <Divider />
      <CardContent>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-2 text-center time-table-header period display-4">
              Period
            </div>
            <div className="col-2 text-center time-table-header display-4">
              Mo
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Tu
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              We
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Th
            </div>
            <div className="col-2  text-center  time-table-header display-4">
              Fr
            </div>
          </div>

          {!!store.appData.teacherPeriods &&
            !!store.appData.teacherPeriods.length &&
            store.appData.teacherPeriods.map((teacherPeriod, index) => (
              <div
                className="row d-flex justify-content-center"
                key={teacherPeriod.id}
              >
                <div
                  className={`col-2  text-center time-table-body-period display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {teacherPeriod.startAt} - {teacherPeriod.endAt}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(teacherPeriod, 1).name}{" "}
                  {!!getTimeTableSubject(teacherPeriod, 1).class && (
                    <span className="teacher-name">
                      {/* <i className="fas fa-chalkboard-teacher text-info"></i>{" "} */}
                      {getTimeTableSubject(teacherPeriod, 1).class}
                    </span>
                  )}
                </div>
                {/* <i className="fas fa-chalkboard-teacher"></i> */}
                {/* <i className="fas fa-chalkboard-teachers-class"></i> */}

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(teacherPeriod, 2).name}{" "}
                  {!!getTimeTableSubject(teacherPeriod, 2).class && (
                    <span className="teacher-name">
                      {/* <i className="fas fa-chalkboard-teacher text-info"></i>{" "} */}
                      {getTimeTableSubject(teacherPeriod, 2).class}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(teacherPeriod, 3).name}{" "}
                  {!!getTimeTableSubject(teacherPeriod, 3).class && (
                    <span className="teacher-name">
                      {/* <i className="fas fa-chalkboard-teacher text-info"></i>{" "} */}
                      {getTimeTableSubject(teacherPeriod, 3).class}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(teacherPeriod, 4).name}{" "}
                  {!!getTimeTableSubject(teacherPeriod, 4).class && (
                    <span className="teacher-name">
                      {/* <i className="fas fa-chalkboard-teacher text-info"></i>{" "} */}
                      {getTimeTableSubject(teacherPeriod, 4).class}
                    </span>
                  )}
                </div>

                <div
                  className={`col-2 text-center time-table-body display-4 ${
                    teacherPeriod.periodTypeId === 1
                      ? "teaching"
                      : teacherPeriod.periodTypeId === 2
                      ? "break"
                      : "lunch"
                  }-period`}
                >
                  {getTimeTableSubject(teacherPeriod, 5).name}{" "}
                  {!!getTimeTableSubject(teacherPeriod, 5).class && (
                    <span className="teacher-name">
                      {/* <i className="fas fa-chalkboard-teacher text-info"></i>{" "} */}
                      {getTimeTableSubject(teacherPeriod, 5).class}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getWeeklyTeacherTimeTable,
  getSchoolLevelGradePeriods,
  generateClassTimeTable,
  getTeacherPeriods,
})(MyWeeklyTimeTable);
