import types from "./types";
import axios from "axios";
import { onSuccess, onError, setAuthorizationToken } from "./utils";
import { loginCredentials } from "./validations";

export const setCurrentUser = ({ user, token }) => {
  return {
    type: types.SET_CURRENT_USER,
    token,
    user,
  };
};

export const updateProfilePicture = (profilePicture) => {
  return {
    type: types.UPDATE_PROFILE_PICTURE,
    profilePicture,
  };
};

export const login = (credentials, history) => {
  return async (dispatch) => {
    try {
      const { error } = loginCredentials.validate(credentials);

      if (error) return onError(error);

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/logins", credentials);

      localStorage.setItem(
        "auth-user",
        JSON.stringify({ user: data.user, token: data.token })
      );
      dispatch({ type: types.END_LOADING });

      setAuthorizationToken(data.token);
      dispatch(setCurrentUser(data));

      if (data.school)
        dispatch({ type: types.SET_SELECTED_SCHOOL, data: data.school });

      if (data.user.roles.findIndex(({ id }) => id === 1) >= 0)
        history.push("/in/security");

      if (data.user.roles.findIndex(({ id }) => id === 2) >= 0)
        history.push("/in/settings");

      if (data.user.roles.findIndex(({ id }) => id === 3) >= 0)
        history.push("/in/time-tables/my-day");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const logout = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      localStorage.removeItem("auth-user");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.CLEAN_STATE });
      history.push("/");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const passwordReset = (email, setShowResetPassword) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/reset-password", { email });

      dispatch({ type: types.END_LOADING });

      onSuccess({ message: "Please, Check your email" });

      setShowResetPassword(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const changePassword = (payroad) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/change-password", payroad);

      dispatch({ type: types.END_LOADING });

      onSuccess({ message: "Password changed successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

// export const uploadSingleFile = (formData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post("/api/upload-single-file", formData);

//       dispatch({ type: types.END_LOADING });
//       onSuccess(data);
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       onError(error);
//     }
//   };
// };

// export const uploadMultipleFiles = (formData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post("/api/upload-multiple-files", formData);

//       dispatch({ type: types.END_LOADING });
//       onSuccess(data);
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       onError(error);
//     }
//   };
// };

export const updateUserProfilePicture = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/update-user-profile-picture",
        formData
      );
      dispatch({ type: types.END_LOADING });

      const currUser = JSON.parse(localStorage.getItem("auth-user"));
      currUser.user.profilePicture = data.profilePicture;

      localStorage.setItem("auth-user", JSON.stringify(currUser));
      // setAuthorizationToken(data.token);

      dispatch({ type: types.END_LOADING });

      dispatch(updateProfilePicture(data.profilePicture));

      onSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getLevels = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/levels");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LEVELS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolSbjects = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/school-subjects/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SCHOOL_SUBJECTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addSchoolLevel = (
  payload,
  setSelectedLevel,
  handleLevelFormDialogClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/school-levels", payload);

      onSuccess({ message: "Saved successfully" });

      setSelectedLevel("");
      handleLevelFormDialogClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.ADD_SCHOOL_LEVEL, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevel = (schoolLevelId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/school-levels/" + schoolLevelId
      );

      // onSuccess(data);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_SCHOOL_LEVEL, schoolLevelId });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevelGrade = (schoolLevelId, schoolLevelGradeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/school-level-grades/" + schoolLevelGradeId
      );

      // onSuccess(data);

      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.REMOVE_SCHOOL_LEVEL_GRADE,
        schoolLevelId,
        schoolLevelGradeId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getLevelGrades = (levelId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/level-grades/" + levelId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LEVEL_GRADES, data: { levelId, data } });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addSchoolLevelGrade = (
  { schoolId, levelId, gradeId },
  setSelectedGrade,
  handleGradeFormDialogClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/school-level-grades", {
        schoolId,
        levelId,
        gradeId,
      });

      onSuccess({ message: "Saved successfully" });

      setSelectedGrade("");
      handleGradeFormDialogClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.ADD_SCHOOL_LEVEL_GRADE, data: { levelId, data } });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSelectedSchool = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/schools/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SELECTED_SCHOOL, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchools = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/schools");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SCHOOLS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchool = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/schools/" + id);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_SCHOOL, id });

      const authUser = JSON.parse(localStorage.getItem("auth-user"));

      authUser.user.schools.splice(
        authUser.user.schools.findIndex(({ id: schoolId }) => schoolId === id),
        1
      );

      localStorage.setItem("auth-user", JSON.stringify(authUser));
      dispatch(setCurrentUser(authUser));
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeHeadTeacher = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/head-teachers/" + id);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_HEAD_TEACHER, id });

      onSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const assignHeadTeacherSchool = (
  assignmentData,
  setAssignmentDialogOpen
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/head-teacher-schools",
        assignmentData
      );

      setAssignmentDialogOpen(false);

      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.UPDATE_HEAD_TEACHER,
        data,
      });

      onSuccess({ message: "Assigned successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeHeadTeacherSchool = (userId, schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/head-teacher-schools/" + userId + "/" + schoolId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.REMOVE_HEAD_TEACHER_SCHOOL,
        data: { userId, schoolId },
      });
      onSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/school-categories");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LOOKUP_SCHOOL_CATEGORIES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getDistricts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/districts");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LOOKUP_DISTRICTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSectors = (districtId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/sectors/" + districtId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LOOKUP_SECTORS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getCells = (sectorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/cells/" + sectorId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LOOKUP_CELLS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getVillages = (cellId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/villages/" + cellId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_LOOKUP_VILLAGES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveSchool = (
  school,
  handleLevelFormDialogClose,
  setFormData,
  setIsEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/schools", school);

      dispatch({ type: types.END_LOADING });
      if (!school.id) dispatch({ type: types.ADD_NEW_SCHOOL, data });
      else dispatch({ type: types.UPDATE_SCHOOL, data });

      handleLevelFormDialogClose();
      setIsEditing(false);
      setFormData({
        id: null,
        name: "",
        sdmsSchoolCode: null,
        categoryId: "",
        district: null,
        sector: null,
        cell: null,
        village: null,
        headTeacher: null,
        schoolLevels: null,
      });
      dispatch({
        type: types.SET_LOOKUP_SECTORS,
        data: null,
      });
      dispatch({
        type: types.SET_LOOKUP_CELLS,
        data: null,
      });
      dispatch({
        type: types.SET_LOOKUP_VILLAGES,
        data: null,
      });

      const authUser = JSON.parse(localStorage.getItem("auth-user"));
      authUser.user.schools.push(data);
      localStorage.setItem("auth-user", JSON.stringify(authUser));
      dispatch(setCurrentUser(authUser));
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolHeadTeachers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/head-teachers");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_HEAD_TEACHERS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveSchoolHeadTeacher = (
  headTeacher,
  handleLevelFormDialogClose,
  setFormData,
  setIsEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/head-teachers", headTeacher);

      dispatch({ type: types.END_LOADING });
      if (!headTeacher.id) dispatch({ type: types.ADD_NEW_HEAD_TEACHER, data });
      else dispatch({ type: types.UPDATE_HEAD_TEACHER, data });

      handleLevelFormDialogClose();
      setFormData({
        id: null,
        sdmsStaffCode: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        idNumber: "",
        genderId: "",
      });
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addSchoolSubject = (
  subject,
  setSubject,
  handleLevelFormDialogClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/school-subjects", subject);

      onSuccess({ message: "Saved successfully" });
      handleLevelFormDialogClose();

      dispatch({ type: types.END_LOADING });

      // if (!!subject.id) dispatch({ type: types.UPDATE_SCHOOL_SUBJECT, data });
      // else dispatch({ type: types.ADD_NEW_SCHOOL_SUBJECT, data });

      dispatch({ type: types.SET_SCHOOL_SUBJECTS, data });

      setSubject({
        id: null,
        schoolId: subject.schoolId,
        name: "",
        subjectTypeId: "",
        periods: 0,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

// export const addElectiveSchoolSubject = (
//   subject,
//   setSubject,
//   handleLevelFormDialogClose
// ) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post(
//         "/api/school-elective-subjects",
//         subject
//       );

//       onSuccess({ message: "Saved successfully" });
//       handleLevelFormDialogClose();
//       setSubject({
//         id: null,
//         schoolId: subject.schoolId,
//         name: "",
//         subjectTypeId: "",
//         isNursery: false,
//         isLowerPrimary: false,
//         isUpperPrimary: false,
//         isLowerSecondary: false,
//         nurseryWeight: 0,
//         lowerPrimaryWeight: 0,
//         upperPrimaryWeight: 0,
//         lowerSecondaryWeight: 0,
//         n1Periods: 0,
//         n2Periods: 0,
//         n3Periods: 0,
//         p1Periods: 0,
//         p2Periods: 0,
//         p3Periods: 0,
//         p4Periods: 0,
//         p5Periods: 0,
//         p6Periods: 0,
//         s1Periods: 0,
//         s2Periods: 0,
//         s3Periods: 0,
//       });

//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.SET_SCHOOL_SUBJECTS, data });
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       onError(error);
//     }
//   };
// };

// export const addCocurricularSchoolSubject = (
//   subject,
//   setSubject,
//   handleLevelFormDialogClose
// ) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post(
//         "/api/school-cocurricular-subjects",
//         subject
//       );

//       onSuccess({ message: "Saved successfully" });
//       handleLevelFormDialogClose();
//       setSubject({
//         id: null,
//         schoolId: subject.schoolId,
//         name: "",
//         subjectTypeId: "",
//         isNursery: false,
//         isLowerPrimary: false,
//         isUpperPrimary: false,
//         isLowerSecondary: false,
//         nurseryWeight: 0,
//         lowerPrimaryWeight: 0,
//         upperPrimaryWeight: 0,
//         lowerSecondaryWeight: 0,
//         n1Periods: 0,
//         n2Periods: 0,
//         n3Periods: 0,
//         p1Periods: 0,
//         p2Periods: 0,
//         p3Periods: 0,
//         p4Periods: 0,
//         p5Periods: 0,
//         p6Periods: 0,
//         s1Periods: 0,
//         s2Periods: 0,
//         s3Periods: 0,
//       });

//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.SET_SCHOOL_SUBJECTS, data });
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       onError(error);
//     }
//   };
// };

export const removeSchoolSubject = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/school-subjects/" + id);

      onSuccess(data);

      dispatch({ type: types.END_LOADING });
      //dispatch({ type: types.REMOVE_SCHOOL_SUBJECT, id });
      dispatch({ type: types.SET_SCHOOL_SUBJECTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

// export const addClassRoom = (classRoom, closeClassFormDialog, setClassRoom) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post("/api/class-rooms", classRoom);
//       setClassRoom({ ...classRoom, name: "" });
//       closeClassFormDialog();

//       onSuccess({ message: "Saved successfully" });

//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.ADD_NEW_CLASS_ROOM, data });
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       onError(error);
//     }
//   };
// };

export const getClassRooms = ({ schoolLevelGradeId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/class-rooms/" + schoolLevelGradeId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_CLASS_ROOMS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addClassRoom = (classRoom, closeClassFormDialog, setClassRoom) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/class-rooms", classRoom);

      setClassRoom({
        ...classRoom,
        name: "",
        isBeforeNoon: true,
        isAfterNoon: true,
      });
      closeClassFormDialog();

      onSuccess({ message: "Saved successfully" });

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.ADD_NEW_CLASS_ROOM, data: data.newClassRoom });

      dispatch({
        type: types.UPDATE_SCHOOL_LEVEL_GRADE,
        data: {
          levelId: classRoom.levelId,
          schoolLevelGradeId: classRoom.schoolLevelGradeId,
          data: data.newGrade,
        },
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeClassRoom = ({ id, levelId, schoolLevelGradeId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/class-rooms/" + id);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.REMOVE_CLASS_ROOM, id });

      dispatch({
        type: types.UPDATE_SCHOOL_LEVEL_GRADE,
        data: {
          levelId: levelId,
          schoolLevelGradeId: schoolLevelGradeId,
          data: data.newGrade,
        },
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolTeachers = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/teachers/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHERS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveSchoolTeacher = (
  teacher,
  handleFormDialogClose,
  setFormData,
  setIsEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/teachers", teacher);

      dispatch({ type: types.END_LOADING });

      if (!teacher.id) dispatch({ type: types.ADD_NEW_TEACHER, data });
      else dispatch({ type: types.UPDATE_TEACHER, data });

      handleFormDialogClose();
      setFormData({
        id: null,
        sdmsStaffCode: null,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        idNumber: "",
        genderId: "",
      });
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const uploadSchoolTeachers = (payload, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/upload-teachers", payload);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_TEACHERS, data });

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolTeacher = ({ id, schoolId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/teachers/" + id + "/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_TEACHER, id });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const setAsDOS = (payroad, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/set-as-dos", payroad);

      handleCloseMenu();

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_TEACHERS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolLevelGradeSubjects = (schoolLevelGradeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/school-level-grade-subjects/" + schoolLevelGradeId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SCHOOL_LEVEL_GRADE_SUBJECTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveSchoolLevelGradeSubject = (
  schoolLevelGradeSubject,
  setSelectedSubject
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/school-level-grade-subjects",
        schoolLevelGradeSubject
      );

      setSelectedSubject(null);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.ADD_NEW_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const changeSchoolLevelGradeSubject = ({
  id,
  isBeforeNoon,
  isAfterNoon,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put("/api/school-level-grade-subjects", {
        id,
        isBeforeNoon,
        isAfterNoon,
      });

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const setSchoolLevelGradeSubjectSpecificDay = (
  gradeSubjectId,
  dayId,
  setShowAddSpecificDayForm,
  setSelectedDay
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/school-level-grade-subjects-specific-day",
        {
          gradeSubjectId,
          dayId,
        }
      );

      setShowAddSpecificDayForm(false);

      dispatch({ type: types.END_LOADING });

      setSelectedDay("");

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const setSchoolLevelGradeSubjectSpecificPeriod = (
  gradeSubjectId,
  periodId,
  setShowAddSpecificPeriodForm,
  setSelectedPeriod
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/school-level-grade-subjects-specific-period",
        {
          gradeSubjectId,
          periodId,
        }
      );

      setShowAddSpecificPeriodForm(false);

      dispatch({ type: types.END_LOADING });

      setSelectedPeriod("");

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevelGradeSubjectSpecificDay = (
  gradeSubjectId,
  dayId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/school-level-grade-subjects-specific-day/" +
          gradeSubjectId +
          "/" +
          dayId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Removed successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevelGradeSubjectSpecificPeriod = (
  gradeSubjectId,
  periodId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/school-level-grade-subjects-specific-period/" +
          gradeSubjectId +
          "/" +
          periodId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT, data });

      onSuccess({ message: "Removed successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevelGradeSubject = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/school-level-grade-subjects/" + id);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_SCHOOL_LEVEL_GRADE_SUBJECT, id });

      onSuccess({ message: "Deleted successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolPeriods = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/school-periods/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SCHOOL_PERIODS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getTeacherPeriods = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/teacher-periods/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHER_PERIODS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addNewSchoolPeriod = (
  formData,
  setFormData,
  closePeriodFormDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/school-periods", formData);

      onSuccess({ message: "Saved successfully" });

      dispatch({ type: types.END_LOADING });
      if (!!formData.id) dispatch({ type: types.UPDATE_SCHOOL_PERIOD, data });
      else dispatch({ type: types.ADD_NEW_SCHOOL_PERIOD, data });

      closePeriodFormDialog();
      setFormData({
        id: null,
        schoolId: "",
        schoolLevelId: "",
        periodNo: "",
        durationMin: "",
        startAt: null,
        endAt: null,
        periodTypeId: "",
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeNewSchoolPeriod = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/school-periods/" + id);

      onSuccess({ message: "Removed successfully" });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_SCHOOL_PERIOD, id });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolLevelGradePeriods = (schoolLevelGradeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/school-level-grade-periods/" + schoolLevelGradeId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_SCHOOL_LEVEL_GRADE_PERIODS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveSchoolLevelGradePeriod = (
  schoolLevelGradePeriod,
  setSelectedPeriod
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/school-level-grade-periods",
        schoolLevelGradePeriod
      );

      setSelectedPeriod(null);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.ADD_NEW_SCHOOL_LEVEL_GRADE_PERIOD, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeSchoolLevelGradePeriod = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/school-level-grade-periods/" + id);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_SCHOOL_LEVEL_GRADE_PERIOD, id });

      onSuccess({ message: "Deleted successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const changeSchoolLevelGradePeriodDay = ({ id, day, value }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put("/api/school-level-grade-periods", {
        id,
        day,
        value,
      });

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_SCHOOL_LEVEL_GRADE_PERIOD, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getClassRoomTeachers = (classRoomId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/class-room-teachers/" + classRoomId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_CLASS_ROOM_TEACHERS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const saveClassRoomTeacher = (teacher, setSelectedTeacher) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/class-room-teachers", teacher);

      setSelectedTeacher(null);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.ADD_NEW_CLASS_ROOM_TEACHER, data: data.teacher });
      dispatch({ type: types.UPDATE_CLASS_ROOM, data: data.classRoom });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeClassRoomTeacher = ({ userId, classId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/class-room-teachers/" + userId + "/" + classId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.REMOVE_CLASS_ROOM_TEACHER, userId });
      dispatch({ type: types.UPDATE_CLASS_ROOM, data });

      onSuccess({ message: "Deleted successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addTeacherSubject = (
  formData,
  setSelectedSubjectIds,
  setOpenAddTeacherSubjectForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/teacher-subjects", formData);

      setSelectedSubjectIds([]);
      setOpenAddTeacherSubjectForm(false);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_CLASS_ROOM_TEACHER, data });

      onSuccess({ message: "Saved successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeTeacherSubject = ({ userClassId, subjectId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/teacher-subjects/" + userClassId + "/" + subjectId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_CLASS_ROOM_TEACHER, data });

      onSuccess({ message: "Deleted successfully" });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getClassTimeTable = (classId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/class-time-table/" + classId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_CLASS_TIMETABLE, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getWeeklyTeacherTimeTable = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/weekly-teacher-time-table/" + schoolId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHER_TIMETABLE, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const generateClassTimeTable = (classId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/generate-class-time-table/" + classId
      );

      onSuccess({ message: "TimeTable Generated Successfully" });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_CLASS_TIMETABLE, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getTeacherClasses = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/teacher-classes");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHER_CLASSES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getClassStudentsForAttendance = (
  classId,
  periodId,
  dayId,
  subjectId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({ type: types.SET_CLASS_STUDENTS, data: [] });

      const { data } = await axios.get(
        "/api/class-students-for-attendance/" +
          classId +
          "/" +
          periodId +
          "/" +
          dayId +
          "/" +
          subjectId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_CLASS_STUDENTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getClassStudents = (classId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({ type: types.SET_CLASS_STUDENTS, data: [] });

      const { data } = await axios.get("/api/class-students/" + classId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_CLASS_STUDENTS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addNewStudent = (
  formData,
  setOpenStudentFormDialog,
  setFormData,
  setIsEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/class-students", formData);

      dispatch({ type: types.END_LOADING });

      if (!!formData.id) {
        dispatch({
          type: types.UPDATE_STUDENT,
          data,
        });
      } else {
        dispatch({
          type: types.ADD_NEW_STUDENT,
          data,
          classId: formData.classId,
        });
      }

      setOpenStudentFormDialog(false);
      setFormData({
        ...formData,
        id: null,
        sdmsStaffCode: null,
        firstName: "",
        lastName: "",
        genderId: "",
        guardianFirstName: "",
        guardianLastName: "",
        guardianPhone: "",
        guardianEmail: "",
      });
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const uploadClassStudents = (payload, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/upload-class-students", payload);

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_CLASS_STUDENTS, data });

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const removeStudent = (studentId, classId, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/class-students/" + studentId);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.REMOVE_STUDENT,
        studentId,
        classId,
      });

      handleCloseMenu();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getDailyTeacherPeriods = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/daily-teacher-periods");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_DAILY_TEACHER_PERIODS, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getTeacherAttendances = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/teacher-attendances");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHER_ATTENDANCES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolAttendances = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/school-attendances/" + schoolId);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SET_TEACHER_ATTENDANCES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const getSchoolTimeTableClasses = (schoolId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/school-time-table-classes/" + schoolId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.SCHOOL_TIME_TABLE_CLASSES, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};

export const addStudentOnAttendance = (
  { classId, periodId, dayId, subjectId, studentId, statusId, reason = null },
  setEdit,
  setShowReasonDialog = null
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/attendances", {
        classId,
        periodId,
        dayId,
        subjectId,
        studentId,
        statusId,
        reason,
      });

      if (!!setShowReasonDialog) setShowReasonDialog(false);

      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.UPDATE_STUDENT,
        data,
      });

      setEdit(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      onError(error);
    }
  };
};
