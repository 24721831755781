import _ from "lodash";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSchoolPeriods, removeNewSchoolPeriod } from "../store/actions";
import { Link } from "react-router-dom";
import PeriodFormDialog from "../components/PeriodFormDialog";

const formatTime = (time) => {
  const stringDate = new Date().toISOString();
  const dateOnly = stringDate.split("T")[0];
  const [timeOnly, millSecondsOnly] = stringDate.split("T")[1].split(".");
  const [, , sec] = timeOnly.split(":");

  const formatedTime =
    dateOnly + "T" + time + ":" + sec + "." + millSecondsOnly;

  return new Date(formatedTime.slice(0, -1));
};

const Periods = (props) => {
  const { store, getSchoolPeriods, removeNewSchoolPeriod } = props;

  useEffect(() => {
    if (!!store.selectedSchool.id) getSchoolPeriods(store.selectedSchool.id);
  }, [store.selectedSchool]);

  const filterLevelPeriod = (schoolLevelIdToFilter) => {
    if (!!store.appData.schoolPeriods)
      return store.appData.schoolPeriods.filter(
        ({ schoolLevelId }) => schoolLevelId === schoolLevelIdToFilter
      );
    else return [];
  };

  const [selectedSchoolLevel, setSelectedSchoolLevel] = useState(null);
  const [openPeriodFormDialog, setOpenPeriodFormDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    schoolId: "",
    schoolLevelId: "",
    periodNo: "",
    durationMin: "",
    startAt: null,
    endAt: null,
    periodTypeId: "",
  });

  const closePeriodFormDialog = () => {
    setIsEditing(false);
    setFormData({
      id: null,
      schoolId: "",
      schoolLevelId: "",
      periodNo: "",
      durationMin: "",
      startAt: null,
      endAt: null,
      periodTypeId: "",
    });
    setOpenPeriodFormDialog(false);
  };

  return (
    <>
      <div className="row mt-2 d-flex justify-content-center  ">
        {store &&
          store.selectedSchool.schoolLevels &&
          store.selectedSchool.schoolLevels.map((schoolLevel, index) => (
            <div
              key={"schoolLevel-" + schoolLevel.id.toString()}
              className={`mb-4 col-12 ${
                store.selectedSchool.schoolLevels.length === 4
                  ? "col-lg-3"
                  : store.selectedSchool.schoolLevels.length === 3
                  ? "col-lg-4"
                  : store.selectedSchool.schoolLevels.length === 2
                  ? "col-lg-6"
                  : ""
              }  `}
            >
              <Card variant="outlined" className="level-card mt-2 mb-3">
                <div
                  className=" d-flex align-items-center text-white p-2"
                  style={{ backgroundColor: "#0f7197" }}
                >
                  <Typography
                    sx={{ fontSize: 16 }}
                    className="pl-3"
                    gutterBottom
                  >
                    {schoolLevel.level.name} periods
                  </Typography>
                  <Button
                    variant="contained"
                    className="ml-2"
                    color="info"
                    size="small"
                    onClick={() => {
                      setSelectedSchoolLevel(schoolLevel);
                      setOpenPeriodFormDialog(true);
                    }}
                  >
                    Add Period
                  </Button>
                </div>
                <Divider />
                <CardContent>
                  <List>
                    <Divider />

                    {store &&
                      !!filterLevelPeriod(schoolLevel.id) &&
                      filterLevelPeriod(schoolLevel.id).map((period, index) => (
                        <PeriodListItem
                          schoolLevel={schoolLevel}
                          setSelectedSchoolLevel={setSelectedSchoolLevel}
                          period={period}
                          key={period.id}
                          index={index}
                          setIsEditing={setIsEditing}
                          setFormData={setFormData}
                          setOpenPeriodFormDialog={setOpenPeriodFormDialog}
                          removeNewSchoolPeriod={removeNewSchoolPeriod}
                        />
                      ))}
                  </List>
                  {!filterLevelPeriod(schoolLevel.id).length && (
                    <div className="text-center">Not record found</div>
                  )}
                </CardContent>
              </Card>
            </div>
          ))}
        {store &&
          store.selectedSchool.schoolLevels &&
          !store.selectedSchool.schoolLevels.length && (
            <div>No level added</div>
          )}
        {_.isEmpty(store.selectedSchool) && (
          <div className="row mt-5 d-flex justify-content-center ">
            Select school
          </div>
        )}
      </div>

      <PeriodFormDialog
        closePeriodFormDialog={closePeriodFormDialog}
        openPeriodFormDialog={openPeriodFormDialog}
        selectedSchoolLevel={selectedSchoolLevel}
        filterLevelPeriod={filterLevelPeriod}
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getSchoolPeriods,
  removeNewSchoolPeriod,
})(Periods);

const PeriodListItem = (props) => {
  const {
    period,
    schoolLevel,
    setIsEditing,
    setFormData,
    setSelectedSchoolLevel,
    setOpenPeriodFormDialog,
    removeNewSchoolPeriod,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ListItem
        style={{ backgroundColor: period.periodTypeId === 1 ? "#e4eef5" : "" }}
        className={`mb-1 mt-1 ${
          period.periodTypeId === 1
            ? "teaching"
            : period.periodTypeId === 2
            ? "break"
            : "lunch"
        }-period`}
      >
        <ListItemAvatar>
          <Avatar>{period.periodNo}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              Period {period.periodNo} {period.periodType.name}
            </span>
          }
          secondary={
            <span>
              <span
                className={`badge badge-${
                  period.periodTypeId === 1
                    ? "primary"
                    : period.periodTypeId === 2
                    ? "secondary"
                    : "dark"
                }`}
              >
                {period.durationMin} Min
              </span>{" "}
              <span>
                {period.startAt} - {period.endAt}
              </span>
            </span>
          }
        />
        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                const startAt = formatTime(period.startAt);
                const endAt = formatTime(period.endAt);
                setSelectedSchoolLevel(schoolLevel);
                setFormData({ ...period, startAt, endAt });
                setIsEditing(true);
                setOpenPeriodFormDialog(true);
                handleCloseMenu();
              }}
              className="text-primary font-weight-light"
            >
              <span className="material-icons mr-2">edit</span>
              Edit
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                removeNewSchoolPeriod(period.id);
                handleCloseMenu();
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
