import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  getClassRooms,
  getSchoolSbjects,
  getSchoolTeachers,
  getSchoolLevelGradeSubjects,
  saveSchoolLevelGradeSubject,
  removeSchoolLevelGradeSubject,
  removeClassRoom,
  getSchoolPeriods,
  getSchoolLevelGradePeriods,
  saveSchoolLevelGradePeriod,
  removeSchoolLevelGradePeriod,
  changeSchoolLevelGradePeriodDay,
  changeSchoolLevelGradeSubject,
  setSchoolLevelGradeSubjectSpecificDay,
  setSchoolLevelGradeSubjectSpecificPeriod,
  removeSchoolLevelGradeSubjectSpecificDay,
  removeSchoolLevelGradeSubjectSpecificPeriod,
} from "../store/actions";

import types from "../store/types";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import ClassFormDialog from "./ClassFormDialog";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import ClassTeachersDialog from "./ClassTeachersDialog";
import ClassTimeTableDialog from "./ClassTimeTableDialog";
import ClassStudents from "./ClassStudents";

const ClassesCardDialog = (props) => {
  const {
    store,
    schoolLevel,
    schoolLevelGrade,
    isDialogOpen,
    closeDialog,
    getClassRooms,
    getSchoolSbjects,
    getSchoolTeachers,
    getSchoolPeriods,

    getSchoolLevelGradeSubjects,
    saveSchoolLevelGradeSubject,
    removeSchoolLevelGradeSubject,
    getSchoolLevelGradePeriods,
    saveSchoolLevelGradePeriod,
    removeSchoolLevelGradePeriod,
    removeClassRoom,
    changeSchoolLevelGradePeriodDay,
    changeSchoolLevelGradeSubject,
    setSchoolLevelGradeSubjectSpecificDay,
    setSchoolLevelGradeSubjectSpecificPeriod,
    removeSchoolLevelGradeSubjectSpecificDay,
    removeSchoolLevelGradeSubjectSpecificPeriod,
  } = props;
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  // const handaleSubmit = (e) => {
  //   e.preventDefault();

  //   addClass({
  //     schoolLevelId: schoolLevel.id,
  //     schoolLevelGradeId: schoolLevelGrade.id,
  //     class: selectedClass,
  //   });

  //   setSelectedClass("");
  // };

  //ClassFormDialog
  const [isClassFormDialogOpen, setIsClassFormDialogOpen] = useState(false);
  const [itemToShow, setItemToShow] = useState("periods");

  const openClassFormDialog = () => {
    setIsClassFormDialogOpen(true);
  };
  const closeClassFormDialog = () => {
    setIsClassFormDialogOpen(false);
  };

  useEffect(() => {
    if (isDialogOpen) {
      getClassRooms({
        schoolLevelGradeId: schoolLevelGrade.id,
      });
      // getSchoolSbjects(store.selectedSchool.id);
      // getSchoolTeachers(store.selectedSchool.id);
      // getSchoolPeriods(store.selectedSchool.id);
      getSchoolLevelGradeSubjects(schoolLevelGrade.id);
      getSchoolLevelGradePeriods(schoolLevelGrade.id);
    }
  }, [isDialogOpen]);

  // const filterSchoolSubjects = (schoolLevel) => {
  //   if (!!store.appData.schoolSubjects)
  //     return store.appData.schoolSubjects.filter(
  //       ({ schoolLevelId }) => schoolLevelId === schoolLevel.id
  //     );
  //   else return [];
  // };

  const filterSubjects = () => {
    if (!!store.appData.schoolSubjects && !!store.appData.gradeSubjects)
      return store.appData.schoolSubjects.filter(
        (subject) =>
          subject.schoolLevelId === schoolLevel.id &&
          store.appData.gradeSubjects.findIndex(
            (gradeSubject) => gradeSubject.subject.id === subject.id
          ) < 0
      );
    // if (
    //   !!store.appData.schoolSubjects &&
    //   !!store.appData.gradeSubjects &&
    //   schoolLevel.level.id === 2
    // )
    //   return store.appData.schoolSubjects.filter(
    //     (subject) =>
    //       (subject.isLowerPrimary === true ||
    //         subject.isUpperPrimary === true) &&
    //       subject.isAdded === true &&
    //       store.appData.gradeSubjects.findIndex(
    //         (gradeSubject) => gradeSubject.subject.id === subject.id
    //       ) < 0
    //   );
    // if (
    //   !!store.appData.schoolSubjects &&
    //   !!store.appData.gradeSubjects &&
    //   schoolLevel.level.id === 3
    // )
    //   return store.appData.schoolSubjects.filter(
    //     (subject) =>
    //       subject.isLowerSecondary === true &&
    //       subject.isAdded === true &&
    //       store.appData.gradeSubjects.findIndex(
    //         (gradeSubject) => gradeSubject.subject.id === subject.id
    //       ) < 0
    //   );
    else return [];
  };

  const filterPeriods = () => {
    if (!!store.appData.schoolPeriods && !!store.appData.gradePeriods)
      return store.appData.schoolPeriods.filter(
        (schoolPeriod) =>
          store.appData.gradePeriods.findIndex(
            (gradePeriod) => gradePeriod.period.id === schoolPeriod.id
          ) < 0 && schoolPeriod.schoolLevelId === schoolLevel.id
      );
    else return [];
  };

  return (
    <>
      <Dialog open={isDialogOpen} fullWidth maxWidth="lg">
        <DialogTitle style={{ backgroundColor: "#0f7197", color: "white" }}>
          <span>
            {schoolLevel.level.name}
            {schoolLevelGrade.grade.name} Settings
          </span>

          <IconButton
            className="close-btn mr-3"
            onClick={() => {
              closeDialog();
              setItemToShow("periods");
              dispatch({
                type: types.SET_CLASS_ROOMS,
                data: [],
              });
              dispatch({
                type: types.SET_CLASS_ROOM_TEACHERS,
                data: [],
              });
              dispatch({
                type: types.SET_SCHOOL_LEVEL_GRADE_SUBJECTS,
                data: [],
              });

              dispatch({
                type: types.SET_SCHOOL_LEVEL_GRADE_PERIODS,
                data: [],
              });
            }}
          >
            <span className="material-icons text-white">close</span>
          </IconButton>
        </DialogTitle>
        <div className="  my-1 d-flex justify-content-center text-md-left">
          <Button
            onClick={() => setItemToShow("periods")}
            size="small"
            className={`mr-2 ${
              itemToShow === "periods" ? "outlined active mb-2" : "mb-2"
            } `}
          >
            Periods
          </Button>
          <Button
            onClick={() => setItemToShow("subjects")}
            size="small"
            className={`mr-2 ${
              itemToShow === "subjects" ? "outlined active mb-2" : "mb-2"
            } `}
          >
            Subjects
          </Button>

          <Button
            onClick={() => setItemToShow("classes")}
            size="small"
            // variant="outlined"
            className={` ${
              itemToShow === "classes" ? "outlined active  mb-2" : " mb-2"
            } `}
          >
            Classes
          </Button>
        </div>
        <div className="px-4">
          {itemToShow === "classes" && (
            <button
              onClick={openClassFormDialog}
              type="button"
              className="btn btn-primary btn-block  text-uppercase  mt-1"
              style={{ borderRadius: "25px" }}
            >
              <span className="d-flex justify-content-center">
                <span className="material-icons">add</span> Add New Class
              </span>
            </button>
          )}

          {itemToShow === "subjects" && (
            <>
              <Autocomplete
                size="small"
                disablePortal
                defaultValue={null}
                value={selectedSubject}
                options={filterSubjects()}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Select subject" />
                )}
                onChange={(event, newValue) => {
                  setSelectedSubject(newValue);
                }}
              />
              <button
                style={{ borderRadius: "25px" }}
                onClick={() => {
                  saveSchoolLevelGradeSubject(
                    {
                      schoolLevelGradeId: schoolLevelGrade.id,
                      subjectId: selectedSubject.id,
                    },
                    setSelectedSubject
                  );
                }}
                type="button"
                disabled={isEmpty(selectedSubject) || store.isLoading}
                className="btn btn-primary btn-block text-uppercase  mt-1"
              >
                {store.isLoading ? "Wait..." : "Save"}
              </button>
            </>
          )}

          {itemToShow === "periods" && (
            <>
              <Autocomplete
                size="small"
                disablePortal
                defaultValue={null}
                value={selectedPeriod}
                options={filterPeriods()}
                getOptionLabel={(option) =>
                  option.startAt +
                  " - " +
                  option.endAt +
                  " (" +
                  option.durationMin +
                  " Min " +
                  option.periodType.name +
                  ")"
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select period" />
                )}
                onChange={(event, newValue) => {
                  setSelectedPeriod(newValue);
                }}
              />
              <button
                style={{ borderRadius: "25px" }}
                onClick={() => {
                  saveSchoolLevelGradePeriod(
                    {
                      schoolLevelGradeId: schoolLevelGrade.id,
                      periodId: selectedPeriod.id,
                    },
                    setSelectedPeriod
                  );
                }}
                type="button"
                disabled={isEmpty(selectedPeriod) || store.isLoading}
                className="btn btn-primary btn-block text-uppercase  mt-1"
              >
                {store.isLoading ? "Wait..." : "Save"}
              </button>
            </>
          )}
        </div>

        <DialogContent className="px-4">
          <div style={{ minHeight: "60vh" }}>
            {itemToShow === "classes" && (
              <div>
                <List>
                  <Divider />
                  {!!store.appData.classRooms &&
                    store.appData.classRooms.map((classRoom) => (
                      <ClassItem
                        key={classRoom.id}
                        classRoom={classRoom}
                        schoolLevel={schoolLevel}
                        schoolLevelGrade={schoolLevelGrade}
                        removeClassRoom={removeClassRoom}
                      />
                    ))}
                </List>
                {!store.appData.classRooms ||
                  (!!store.appData.classRooms &&
                    !store.appData.classRooms.length && (
                      <div className="text-center">Not found</div>
                    ))}
              </div>
            )}
            {itemToShow === "subjects" && (
              <div>
                <List>
                  <Divider />
                  {!!store.appData.gradeSubjects &&
                    store.appData.gradeSubjects.map((gradeSubject, index) => (
                      <SubjectItem
                        index={index}
                        key={gradeSubject.id}
                        gradeSubject={gradeSubject}
                        schoolLevel={schoolLevel}
                        schoolLevelGrade={schoolLevelGrade}
                        removeSchoolLevelGradeSubject={
                          removeSchoolLevelGradeSubject
                        }
                        gradePeriods={store.appData.gradePeriods}
                        changeSchoolLevelGradeSubject={
                          changeSchoolLevelGradeSubject
                        }
                        setSchoolLevelGradeSubjectSpecificDay={
                          setSchoolLevelGradeSubjectSpecificDay
                        }
                        setSchoolLevelGradeSubjectSpecificPeriod={
                          setSchoolLevelGradeSubjectSpecificPeriod
                        }
                        removeSchoolLevelGradeSubjectSpecificDay={
                          removeSchoolLevelGradeSubjectSpecificDay
                        }
                        removeSchoolLevelGradeSubjectSpecificPeriod={
                          removeSchoolLevelGradeSubjectSpecificPeriod
                        }
                      />
                    ))}
                </List>
                {(!store.appData.gradeSubjects ||
                  (!!store.appData.gradeSubjects &&
                    !store.appData.gradeSubjects.length)) && (
                  <div className="text-center">Not found</div>
                )}
              </div>
            )}
            {itemToShow === "periods" && (
              <div>
                <List>
                  <Divider />

                  {!!store.appData.gradePeriods &&
                    store.appData.gradePeriods.map((gradePeriod, index) => (
                      <ClassPeriodListItem
                        gradePeriod={gradePeriod}
                        key={gradePeriod.id}
                        index={index}
                        schoolLevel={schoolLevel}
                        schoolLevelGrade={schoolLevelGrade}
                        removeSchoolLevelGradePeriod={
                          removeSchoolLevelGradePeriod
                        }
                        changeSchoolLevelGradePeriodDay={
                          changeSchoolLevelGradePeriodDay
                        }
                      />
                    ))}
                </List>
                {(!store.appData.gradePeriods ||
                  (!!store.appData.gradePeriods &&
                    !store.appData.gradePeriods.length)) && (
                  <div className="text-center">Not found</div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3">
          {/* <Button variant="contained" onClick={closeDialog}>
            Close
          </Button> */}
        </DialogActions>
        <ClassFormDialog
          schoolLevel={schoolLevel}
          schoolLevelGrade={schoolLevelGrade}
          isClassFormDialogOpen={isClassFormDialogOpen}
          closeClassFormDialog={closeClassFormDialog}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getClassRooms,
  getSchoolSbjects,
  getSchoolTeachers,
  getSchoolLevelGradeSubjects,
  saveSchoolLevelGradeSubject,
  removeSchoolLevelGradeSubject,
  removeClassRoom,
  getSchoolPeriods,
  getSchoolLevelGradePeriods,
  saveSchoolLevelGradePeriod,
  removeSchoolLevelGradePeriod,
  changeSchoolLevelGradePeriodDay,
  changeSchoolLevelGradeSubject,
  setSchoolLevelGradeSubjectSpecificDay,
  setSchoolLevelGradeSubjectSpecificPeriod,
  removeSchoolLevelGradeSubjectSpecificDay,
  removeSchoolLevelGradeSubjectSpecificPeriod,
})(ClassesCardDialog);

const ClassItem = (props) => {
  const { classRoom, schoolLevel, schoolLevelGrade, removeClassRoom } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isTeachersDialogOpen, setIsTeachersDialogOpen] = useState(false);
  const [isTimeTableDialogOpen, setIsTimeTableDialogOpen] = useState(false);
  const [isStudentsDialogOpen, setIsStudentsDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar>
            <span style={{ fontSize: "14px" }}>
              {schoolLevel.level.acronym}
              {schoolLevelGrade.grade.name}
              {classRoom.name}
            </span>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {schoolLevel.level.name}
              {schoolLevelGrade.grade.name}
              {classRoom.name}{" "}
              {classRoom.isBeforeNoon && !classRoom.isAfterNoon && (
                <span className="badge badge-info">Beforenoon</span>
              )}
              {!classRoom.isBeforeNoon && classRoom.isAfterNoon && (
                <span className="badge badge-dark">Afternoon</span>
              )}
            </>
          }
          secondary={
            <>
              <span className="badge badge-secondary mr-1 p-1">
                {classRoom.teachers.length} Teachers
              </span>
            </>
          }
        />

        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            {/* <MenuItem
              onClick={() => {
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">list</span> Subjects
            </MenuItem>
            <Divider className="my-1" />*/}

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setIsTeachersDialogOpen(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">
                settings_accessibility
              </span>
              Teaches
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setIsStudentsDialogOpen(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">groups</span>
              Students
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setIsTimeTableDialogOpen(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">calendar_month</span> Time
              Table
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                removeClassRoom({
                  id: classRoom.id,
                  levelId: schoolLevel.levelId,
                  schoolLevelGradeId: schoolLevelGrade.id,
                });
                handleCloseMenu();
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />

      <ClassTeachersDialog
        schoolLevel={schoolLevel}
        schoolLevelGrade={schoolLevelGrade}
        classRoom={classRoom}
        isTeachersDialogOpen={isTeachersDialogOpen}
        setIsTeachersDialogOpen={setIsTeachersDialogOpen}
      />

      <ClassTimeTableDialog
        schoolLevel={schoolLevel}
        schoolLevelGrade={schoolLevelGrade}
        classRoom={classRoom}
        isTimeTableDialogOpen={isTimeTableDialogOpen}
        setIsTimeTableDialogOpen={setIsTimeTableDialogOpen}
      />

      <ClassStudents
        teacherClass={{
          ...classRoom,
          level: schoolLevel.level,
          grade: schoolLevelGrade.grade,
        }}
        isDialogOpen={isStudentsDialogOpen}
        closeDialog={() => setIsStudentsDialogOpen(false)}
      />
    </React.Fragment>
  );
};

const SubjectItem = (props) => {
  const {
    gradeSubject,
    schoolLevel,
    schoolLevelGrade,
    index,
    removeSchoolLevelGradeSubject,
    gradePeriods,
    changeSchoolLevelGradeSubject,
    setSchoolLevelGradeSubjectSpecificDay,
    setSchoolLevelGradeSubjectSpecificPeriod,
    removeSchoolLevelGradeSubjectSpecificDay,
    removeSchoolLevelGradeSubjectSpecificPeriod,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showAddSpecificDayForm, setShowAddSpecificDayForm] = useState(false);
  const [showAddSpecificPeriodForm, setShowAddSpecificPeriodForm] =
    useState(false);

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");

  const filterPeriods = (gradeSubject) => {
    if (!!gradePeriods)
      return gradePeriods.filter(
        ({ period }) =>
          period.periodType.id === 1 &&
          (!gradeSubject.specificPeriods ||
            (!!gradeSubject.specificPeriods &&
              gradeSubject.specificPeriods.findIndex(
                ({ id }) => id === period.id
              ) < 0))
      );
    else return [];
  };

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <span className="d-block">{gradeSubject.subject.name}</span>

              <span className="d-flex flex-wrap align-items-center">
                <FormGroup row className="d-inline ">
                  <FormControlLabel
                    style={{ color: "#7b7d7d" }}
                    control={
                      <Checkbox
                        style={{ color: "#0f7197" }}
                        size="small"
                        checked={gradeSubject.isBeforeNoon}
                        onChange={(e) => {
                          changeSchoolLevelGradeSubject({
                            id: gradeSubject.id,
                            isBeforeNoon: e.target.checked,
                            isAfterNoon: gradeSubject.isAfterNoon,
                          });
                        }}
                      />
                    }
                    label="Beforenoon"
                  ></FormControlLabel>
                  <FormControlLabel
                    style={{ color: "#7b7d7d" }}
                    control={
                      <Checkbox
                        style={{ color: "#0f7197" }}
                        color="info"
                        size="small"
                        checked={gradeSubject.isAfterNoon}
                        onChange={(e) => {
                          changeSchoolLevelGradeSubject({
                            id: gradeSubject.id,
                            isBeforeNoon: gradeSubject.isBeforeNoon,
                            isAfterNoon: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Afternoon"
                  ></FormControlLabel>
                </FormGroup>
                {!!gradeSubject.specificDays &&
                  !!gradeSubject.specificDays[0] &&
                  gradeSubject.specificDays.map((specificDay) => (
                    <Chip
                      className="mr-1 mb-1"
                      key={specificDay.id}
                      component="span"
                      variant="filled"
                      color="primary"
                      size="small"
                      style={{ maxWidth: "100%" }}
                      label={
                        <span
                          style={{ maxWidth: "100%" }}
                          className="mr-1 mt-1  d-inline-block text-truncate"
                        >
                          {specificDay.name}
                        </span>
                      }
                      onDelete={() => {
                        removeSchoolLevelGradeSubjectSpecificDay(
                          gradeSubject.id,
                          specificDay.id
                        );
                      }}
                    />
                  ))}

                {!!gradeSubject.specificPeriods &&
                  !!gradeSubject.specificPeriods[0] &&
                  gradeSubject.specificPeriods.map((specificPeriod) => (
                    <Chip
                      className="mr-1 mb-1"
                      key={specificPeriod.id}
                      component="span"
                      variant="filled"
                      color="secondary"
                      size="small"
                      style={{ maxWidth: "100%" }}
                      label={
                        <span
                          style={{ maxWidth: "100%" }}
                          className="mr-1 mt-2  d-inline-block text-truncate"
                        >
                          {specificPeriod.startAt} - {specificPeriod.endAt}
                        </span>
                      }
                      onDelete={() => {
                        removeSchoolLevelGradeSubjectSpecificPeriod(
                          gradeSubject.id,
                          specificPeriod.id
                        );
                      }}
                    />
                  ))}
              </span>
            </>
          }
          secondary={
            <>
              <span className="badge badge-primary mr-2">
                Weight: {gradeSubject.subject.weight}%
              </span>

              <span className="badge badge-info">
                {gradeSubject.subject.periods} Periods
              </span>
            </>
          }
        />

        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setShowAddSpecificDayForm(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">event</span>Add specific day
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setShowAddSpecificPeriodForm(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">alarm_on</span>
              Add specific period
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                removeSchoolLevelGradeSubject(gradeSubject.id);
                handleCloseMenu();
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />

      <Dialog open={showAddSpecificDayForm}>
        <DialogTitle className="text-primary">
          <span className="mr-4">Add Specific Day</span>

          <IconButton
            className="close-btn mr-2"
            onClick={() => setShowAddSpecificDayForm(false)}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="Day">Select Day</InputLabel>
            <Select
              labelId="Day"
              name="Day"
              value={selectedDay}
              label="Select Day"
              onChange={(e) => {
                setSelectedDay(e.target.value);
              }}
            >
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 1) <
                    0)) && <MenuItem value={1}>Monday</MenuItem>}
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 2) <
                    0)) && <MenuItem value={2}>Tuesday</MenuItem>}
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 3) <
                    0)) && <MenuItem value={3}>Wednesday</MenuItem>}
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 4) <
                    0)) && <MenuItem value={4}>Thursday</MenuItem>}
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 5) <
                    0)) && <MenuItem value={5}>Friday</MenuItem>}
              {/* {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 6) <
                    0)) && <MenuItem value={6}>Saturday</MenuItem>}
              {(!gradeSubject.specificDays ||
                (!!gradeSubject.specificDays &&
                  gradeSubject.specificDays.findIndex(({ id }) => id === 0) <
                    0)) && <MenuItem value={0}>Sunday</MenuItem>} */}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            disabled={selectedDay === ""}
            onClick={() =>
              setSchoolLevelGradeSubjectSpecificDay(
                gradeSubject.id,
                selectedDay,
                setShowAddSpecificDayForm,
                setSelectedDay
              )
            }
            size="large"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddSpecificPeriodForm}>
        <DialogTitle className="text-primary">
          <span className=" mr-4">Add Specific Period</span>
          <IconButton
            className="close-btn mr-2"
            onClick={() => setShowAddSpecificPeriodForm(false)}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="Period">Select Period</InputLabel>
            <Select
              labelId="Period"
              name="Period"
              value={selectedPeriod}
              label="Select Period"
              onChange={(e) => {
                setSelectedPeriod(e.target.value);
              }}
            >
              {filterPeriods(gradeSubject).map(({ period }) => (
                <MenuItem key={period.id} value={period.id}>
                  {period.startAt +
                    " - " +
                    period.endAt +
                    " (" +
                    period.durationMin +
                    " Min " +
                    period.periodType.name +
                    ")"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            disabled={!!!selectedPeriod}
            onClick={() =>
              setSchoolLevelGradeSubjectSpecificPeriod(
                gradeSubject.id,
                selectedPeriod,
                setShowAddSpecificPeriodForm,
                setSelectedPeriod
              )
            }
            size="large"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const ClassPeriodListItem = (props) => {
  const {
    gradePeriod,
    index,
    schoolLevel,
    schoolLevelGrade,
    removeSchoolLevelGradePeriod,
    changeSchoolLevelGradePeriodDay,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem
        style={{
          backgroundColor:
            gradePeriod.period.periodTypeId === 1 ? "#e4eef5" : "",
        }}
        className={`mb-1 mt-1 ${
          gradePeriod.period.periodTypeId === 1
            ? "teaching"
            : gradePeriod.period.periodTypeId === 2
            ? "break"
            : "lunch"
        }-period`}
      >
        <ListItemAvatar>
          <Avatar>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          // primary={

          // }
          primary={
            <div>
              <span className="mr-2">
                Period {index + 1} {gradePeriod.period.periodType.name}
              </span>
              <span
                className={`badge badge-${
                  gradePeriod.period.periodTypeId === 1
                    ? "primary"
                    : gradePeriod.period.periodTypeId === 2
                    ? "secondary"
                    : "dark"
                }`}
              >
                {gradePeriod.period.durationMin} Min
              </span>{" "}
              <span>
                {gradePeriod.period.startAt} - {gradePeriod.period.endAt}
              </span>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={gradePeriod.isMonday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "monday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Mo"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={gradePeriod.isTuesday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "tuesday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Tu"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={gradePeriod.isWednesday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "wednesday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="We"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={gradePeriod.isThursday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "thursday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Th"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={gradePeriod.isFriday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "friday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Fr"
                ></FormControlLabel>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      size="small"
                      checked={gradePeriod.isSaturday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "saturday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Sa"
                ></FormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      size="small"
                      checked={gradePeriod.isSunday}
                      onChange={(e) => {
                        changeSchoolLevelGradePeriodDay({
                          id: gradePeriod.id,
                          day: "sunday",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Su"
                ></FormControlLabel> */}
              </FormGroup>
            </div>
          }
        />
        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />
            {/* 
            <MenuItem
              disabled
              onClick={() => {
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">visibility</span>
              Details
            </MenuItem>
            <Divider className="my-1" /> */}

            <MenuItem
              onClick={() => {
                removeSchoolLevelGradePeriod(gradePeriod.id);

                handleCloseMenu();
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
