import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  addSchoolSubject,
  // addElectiveSchoolSubject,
  // addCocurricularSchoolSubject,
} from "../store/actions";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const SubjectFormDialog = (props) => {
  const {
    store,
    levelFormDialogOpen,
    handleLevelFormDialogClose,
    addSchoolSubject,
    subject,
    setSubject,
    // addElectiveSchoolSubject,
    // addCocurricularSchoolSubject,
    selectedSchoolLevel,
    // setSelectedSchoolLevel,
  } = props;

  const handaleSubmit = () => {
    addSchoolSubject(
      {
        ...subject,
        schoolId: store.selectedSchool.id,
        schoolLevelId: !!selectedSchoolLevel
          ? selectedSchoolLevel.id
          : subject.schoolLevelId,
      },
      setSubject,
      handleLevelFormDialogClose
    );
  };

  return (
    <>
      <Dialog open={levelFormDialogOpen} fullWidth maxWidth="md">
        <DialogTitle>
          Add subject{" "}
          {!!selectedSchoolLevel && (
            <span className="text-primary pr-4">
              @{selectedSchoolLevel.level.name}
            </span>
          )}
          <IconButton
            className="close-btn mr-3"
            onClick={handleLevelFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <TextField
              disabled={!!subject.id && !!!subject.schoolId}
              fullWidth
              value={subject.name}
              autoFocus
              label="Subject Name"
              variant="outlined"
              className="mt-3 mb-3"
              onChange={(e) => {
                const name = e.target.value;
                setSubject({ ...subject, name });
              }}
            />
          </FormControl>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mt-3 mb-3">
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  value={subject.subjectTypeId}
                  disabled={!!subject.id && !!!subject.schoolId}
                  label="Category"
                  onChange={(e) => {
                    const subjectTypeId = e.target.value;
                    setSubject({ ...subject, subjectTypeId });
                  }}
                >
                  <MenuItem value={1}>Core</MenuItem>
                  <MenuItem value={2}>Elective</MenuItem>
                  <MenuItem value={3}>Co-curricular</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <div className="">
                <TextField
                  fullWidth
                  value={subject.periods}
                  disabled={!!subject.id && !!!subject.schoolId}
                  label="Periods"
                  type="number"
                  variant="filled"
                  className="mt-3 mb-3"
                  onChange={(e) => {
                    const periods = e.target.value;
                    setSubject({ ...subject, periods });
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            disabled={store.isLoading}
            size="large"
            variant="contained"
            onClick={handaleSubmit}
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  addSchoolSubject,
  // addElectiveSchoolSubject,
  // addCocurricularSchoolSubject,
})(SubjectFormDialog);
