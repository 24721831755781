import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { addNewStudent } from "../store/actions";
import { connect } from "react-redux";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import frLocale from "date-fns/locale/fr";

const StudentFormDialog = (props) => {
  const {
    store,
    openStudentFormDialog,
    setOpenStudentFormDialog,
    addNewStudent,
    teacherClass,
    formData,
    setFormData,
    isEditing,
    setIsEditing,
    maxDateOfBirth,
  } = props;
  const [selectedLevel, setSelectedLevel] = useState("");

  const closeDialog = () => {
    setOpenStudentFormDialog(false);
    setIsEditing(false);
  };

  // const [formData, setFormData] = useState({
  //   id: null,
  //   firstName: "",
  //   lastName: "",
  //   genderId: "",
  //   dateOfBirth: maxDateOfBirth,
  //   guardianFirstName: "",
  //   guardianLastName: "",
  //   guardianPhone: "",
  //   guardianEmail: "",
  //   classId: teacherClass.classId,
  // });

  const onSave = () => {
    addNewStudent(
      { ...formData, classId: teacherClass.classId },
      setOpenStudentFormDialog,
      setFormData,
      setIsEditing
    );
  };

  return (
    <>
      <Dialog open={openStudentFormDialog} scroll="paper">
        <DialogTitle className="text-primary">
          {isEditing ? "Update" : "Add New"} Student
          <IconButton className="close-btn mr-3" onClick={closeDialog}>
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ maxHeight: "55vh" }}>
          <div className="row">
            <div className="col-12">
              <TextField
                size="small"
                fullWidth
                autoFocus
                label="SDMS Staff Code"
                variant="outlined"
                name="sdmsStaffCode"
                className="mt-3"
                value={formData.sdmsStaffCode}
                onChange={(e) => {
                  const sdmsStaffCode = e.target.value;
                  setFormData({ ...formData, sdmsStaffCode });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                required
                fullWidth
                label="First Name"
                variant="outlined"
                className="mt-3 "
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  const firstName = e.target.value;
                  setFormData({ ...formData, firstName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                required
                fullWidth
                label="Last Name"
                variant="outlined"
                className="mt-3 "
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  const lastName = e.target.value;
                  setFormData({ ...formData, lastName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mt-3">
                <InputLabel>Gender *</InputLabel>
                <Select
                  required
                  labelId="Gender"
                  name="genderId"
                  value={formData.genderId}
                  label="Gender"
                  onChange={(e) => {
                    const genderId = e.target.value;
                    setFormData({ ...formData, genderId });
                  }}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  allowSameDateSelection
                  closeOnSelect={true}
                  // minDate={new Date()}
                  maxDate={maxDateOfBirth}
                  label="Date Of Birth [ mm/dd/yyyy ]"
                  value={formData.dateOfBirth}
                  onChange={(dateOfBirth) => {
                    setFormData({
                      ...formData,
                      dateOfBirth,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      // size="small"
                      name="dateOfBirth"
                      variant="outlined"
                      className="mt-3"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                required
                fullWidth
                label="Guardian First Name"
                variant="outlined"
                className="mt-3 "
                name="guardianFirstName"
                value={formData.guardianFirstName}
                onChange={(e) => {
                  const guardianFirstName = e.target.value;
                  setFormData({ ...formData, guardianFirstName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                required
                fullWidth
                label="Guardian Last Name"
                variant="outlined"
                className="mt-3 "
                name="guardianLastName"
                value={formData.guardianLastName}
                onChange={(e) => {
                  const guardianLastName = e.target.value;
                  setFormData({ ...formData, guardianLastName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                required
                fullWidth
                label="Guardian Phone"
                variant="outlined"
                className="mt-3 "
                name="guardianPhone"
                value={formData.guardianPhone}
                onChange={(e) => {
                  const guardianPhone = e.target.value;
                  setFormData({ ...formData, guardianPhone });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Guardian Email (optional)"
                variant="outlined"
                className="mt-3 "
                name="guardianEmail"
                value={formData.guardianEmail}
                onChange={(e) => {
                  const guardianEmail = e.target.value;
                  setFormData({ ...formData, guardianEmail });
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            onClick={onSave}
            size="large"
            variant="contained"
            className="mr-2"
            disabled={
              !(
                formData.sdmsStaffCode &&
                formData.firstName &&
                formData.lastName &&
                formData.genderId &&
                formData.dateOfBirth &&
                formData.guardianFirstName &&
                formData.guardianLastName &&
                formData.guardianLastName &&
                formData.guardianPhone
              ) || store.isLoading
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  // getSchools,
  addNewStudent,
})(StudentFormDialog);
