import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DailyTeacherTimeTable from "../components/DailyTeacherTimeTable";
import TeacherCard from "../components/TeacherCard";
import WeeklyTeacherTimeTable from "../components/WeeklyTeacherTimeTable";
const TeacherTimeTables = () => {
  return (
    <>
      <div>
        <TeacherCard />
        <TeacherCard />
        <TeacherCard />
        <TeacherCard />
      </div>
      <Switch>
        <Route
          path="/in/time-tables/teacher/daily"
          component={DailyTeacherTimeTable}
        />
        <Route
          path="/in/time-tables/teacher/weekly"
          component={WeeklyTeacherTimeTable}
        />
        {/* <Route
          path="/in/time-tables/teacher"
          component={() => <Redirect to="/in/time-tables/teacher/daily" />}
        /> */}
      </Switch>
    </>
  );
};

export default TeacherTimeTables;
