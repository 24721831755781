import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";

const UploadExcelFile = (props) => {
  const { setShowDialog, isLoading, showDialog, onSave, file, setFile } = props;

  const onClose = () => {
    setShowDialog(false);
    setFile(null);
  };
  return (
    <>
      <Dialog maxWidth="xs" onClose={onClose} open={showDialog}>
        <DialogTitle className={`text-primary`}>
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">file_upload</span> Upload
          </span>{" "}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-0 text-center">
          <div className={`col-12 mx-0 px-0`}>
            <small>Choose excel file</small>
            <Button
              fullWidth
              className="d-flex justify-content-start"
              component="label"
              style={{
                borderRadius: "0px",
                height: "35px",
                border: `1px solid #6c757d`,
                color: "#fff",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
                backgroundColor: "#6c757d",
                "&:hover": {
                  backgroundColor: "#6c757d",
                },
              }}
              size="md"
            >
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                className="text-dark"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFile(file || null);
                }}
              />
            </Button>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={() => onSave()}
            type="button"
            disabled={isLoading || !file}
            className="btn btn-primary text-uppercase  mr-3"
          >
            {isLoading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ isLoading }) => {
  return { isLoading };
};
export default connect(mapStateToProps)(UploadExcelFile);
