import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { addSchoolLevel } from "../store/actions";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const LevelFormDialog = (props) => {
  const {
    store,
    levelFormDialogOpen,
    handleLevelFormDialogClose,
    addSchoolLevel,
  } = props;
  const [selectedLevel, setSelectedLevel] = useState("");
  const [levels, setRevels] = useState([]);

  const handaleSubmit = (e) => {
    e.preventDefault();

    addSchoolLevel(
      {
        schoolId: store.selectedSchool.id,
        levelId: selectedLevel,
      },
      setSelectedLevel,
      handleLevelFormDialogClose
    );
  };

  useEffect(() => {
    let tempLevels = [];
    const schoolLevels = [...(store.selectedSchool.schoolLevels || [])];

    const levels = [...(store.lookups.levels || [])];

    // console.log(schoolLevels);

    levels.forEach((item) => {
      if (schoolLevels.findIndex(({ level }) => level.id === item.id) < 0)
        tempLevels.push(item);
    });

    setRevels(tempLevels);
  }, [store]);

  return (
    <>
      <Dialog open={levelFormDialogOpen}>
        <DialogTitle className="text-primary">
          Add Level
          <IconButton
            className="close-btn mr-3"
            onClick={handleLevelFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "240px" }}>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="label">Level</InputLabel>
            <Select
              labelId="label"
              name="level"
              value={selectedLevel}
              label="Level"
              onChange={(e) => {
                setSelectedLevel(e.target.value);
              }}
            >
              {store &&
                store.lookups.levels &&
                levels.map((level, index) => (
                  <MenuItem
                    key={level.id}
                    value={level.id}
                    disabled={level.id === 5}
                  >
                    {level.name}{" "}
                    {level.id === 5 && (
                      <span className="badge badge-danger ml-1">Disabled</span>
                    )}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            onClick={handaleSubmit}
            variant="contained"
            disabled={!selectedLevel || store.isLoading}
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button variant="outlined" onClick={handleLevelFormDialogClose}>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { addSchoolLevel })(LevelFormDialog);
