import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  //addSchoolLevel
  getDistricts,
  getSectors,
  getCells,
  getVillages,
  getSchoolCategories,
  saveSchool,
} from "../store/actions";
import { connect, useDispatch } from "react-redux";
import types from "../store/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Box,
  Avatar,
  Card,
  CardContent,
  Typography,
  Stack,
  Chip,
} from "@mui/material";

import defaultProfile from "../assets/default-profile.jpg";

const SchoolFormDialog = (props) => {
  const {
    store,
    formDialogOpen,
    handleFormDialogClose,
    getDistricts,
    getSectors,
    getCells,
    getVillages,
    getSchoolCategories,
    saveSchool,
    formData,
    setFormData,
    isEditing,
    setIsEditing,
    villageDetails,
  } = props;
  const [selectedLevel, setSelectedLevel] = useState("");

  const dispatch = useDispatch();

  // const [formData, setFormData] = useState({
  //   id: null,
  //   name: "",
  //   categoryId: "",
  //   district: null,
  //   sector: null,
  //   cell: null,
  //   village: null,
  //   headTeacher: null,
  // });

  useEffect(() => {
    getSchoolCategories();
    getDistricts();
  }, []);

  useEffect(() => {
    if (!!formData.district && !!formData.district.id && !isEditing) {
      setFormData({ ...formData, sector: null, cell: null, village: null });
      getSectors(formData.district.id);
      dispatch({
        type: types.SET_LOOKUP_CELLS,
        data: null,
      });
      dispatch({
        type: types.SET_LOOKUP_VILLAGES,
        data: null,
      });
    } else if (!!formData.district && !!formData.district.id) {
      getSectors(formData.district.id);
    }
  }, [formData.district]);

  useEffect(() => {
    if (!!formData.sector && !!formData.sector.id && !isEditing) {
      setFormData({ ...formData, cell: null, village: null });
      getCells(formData.sector.id);
      dispatch({
        type: types.SET_LOOKUP_VILLAGES,
        data: null,
      });
    } else if (!!formData.sector && !!formData.sector.id) {
      getCells(formData.sector.id);
    }
  }, [formData.sector]);

  useEffect(() => {
    if (!!formData.cell && !!formData.cell.id && !isEditing) {
      setFormData({ ...formData, village: null });
      getVillages(formData.cell.id);
    } else if (!!formData.cell && !!formData.cell.id) {
      getVillages(formData.cell.id);
    }
  }, [formData.cell]);

  const onSave = () => {
    saveSchool(
      {
        id: formData.id,
        sdmsSchoolCode: formData.sdmsSchoolCode,
        name: formData.name,
        categoryId: formData.categoryId,
        villageId: formData.village.id,
      },
      handleFormDialogClose,
      setFormData,
      setIsEditing
    );
  };

  return (
    <>
      <Dialog open={formDialogOpen} fullWidth>
        <DialogTitle className="text-primary">
          {isEditing ? "School details" : "Add new school"}
          <IconButton
            className="close-btn mr-3"
            onClick={handleFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ height: "65vh" }}>
          <div className="row">
            <div className="col-12">
              <TextField
                size="small"
                fullWidth
                autoFocus
                label="SDMS School Code"
                variant="outlined"
                name="sdmsSchoolCode"
                className="mt-3"
                value={formData.sdmsSchoolCode}
                onChange={(e) => {
                  const sdmsSchoolCode = e.target.value;
                  setFormData({ ...formData, sdmsSchoolCode });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                autoFocus
                label="School Name"
                variant="outlined"
                name="schoolName"
                className="mt-3"
                value={formData.name}
                onChange={(e) => {
                  const name = e.target.value;
                  setFormData({ ...formData, name });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mt-3">
                <InputLabel>Category</InputLabel>
                <Select
                  labelId="Category"
                  name="categoryId"
                  value={formData.categoryId}
                  label="Category"
                  onChange={(e) => {
                    const categoryId = e.target.value;
                    setFormData({ ...formData, categoryId });
                  }}
                >
                  {!!store.lookups.schoolCategories &&
                    store.lookups.schoolCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id || ""}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {!!store.lookups.districts && (
              <div className="col-12 col-md-6">
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    disablePortal
                    defaultValue={null}
                    value={formData.district}
                    options={store.lookups.districts}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="District" />
                    )}
                    onChange={(event, newValue) => {
                      setFormData({
                        ...formData,
                        district: newValue || null,
                        sector: null,
                        cell: null,
                        village: null,
                      });
                    }}
                  />
                </FormControl>
              </div>
            )}
            {!!store.lookups.sectors && (
              <div className="col-12 col-md-6">
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    disablePortal
                    defaultValue={null}
                    value={formData.sector}
                    options={store.lookups.sectors}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Sector" />
                    )}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, sector: newValue || null });
                    }}
                  />
                </FormControl>
              </div>
            )}
            {!!store.lookups.cells && (
              <div className="col-12 col-md-6">
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    disablePortal
                    defaultValue={null}
                    value={formData.cell}
                    options={store.lookups.cells}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Cell" />
                    )}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, cell: newValue || null });
                    }}
                  />
                </FormControl>
              </div>
            )}
            {!!store.lookups.villages && (
              <div className="col-12 col-md-6">
                <FormControl fullWidth className="mt-3">
                  <Autocomplete
                    disablePortal
                    defaultValue={null}
                    value={formData.village}
                    options={store.lookups.villages}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Village" />
                    )}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, village: newValue || null });
                    }}
                  />
                </FormControl>
              </div>
            )}
          </div>
          {isEditing && !!formData.schoolLevels && (
            <Card variant="outlined" className="mt-2 bg-light">
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h5" component="span">
                    Levels:
                  </Typography>
                  {formData.schoolLevels.map((schoolLevel, index) => (
                    <Chip
                      key={index}
                      label={schoolLevel.level.name}
                      color="primary"
                    />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          )}
          {isEditing && !!formData.headTeacher && (
            <Card variant="outlined" className="mt-2 bg-light">
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Typography component="span">Head Teacher:</Typography>

                  <Chip
                    component="span"
                    size="medium"
                    avatar={
                      <Avatar
                        component="span"
                        alt={formData.headTeacher.firstName}
                        src={
                          formData.headTeacher.profilePicture
                            ? `data:image/png;base64,${formData.headTeacher.profilePicture}`
                            : defaultProfile
                        }
                      />
                    }
                    label={
                      <span>
                        {formData.headTeacher.firstName +
                          " " +
                          formData.headTeacher.lastName}
                      </span>
                    }
                    variant="outlined"
                  />
                </Stack>
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            onClick={onSave}
            size="large"
            variant="contained"
            className="mr-2"
            disabled={
              !(
                formData.sdmsSchoolCode &&
                formData.name &&
                formData.categoryId &&
                formData.village
              ) || store.isLoading
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button
            size="large"
            variant="outlined"
            onClick={handleFormDialogClose}
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getDistricts,
  getSectors,
  getCells,
  getVillages,
  getSchoolCategories,
  saveSchool,
})(SchoolFormDialog);
