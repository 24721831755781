import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  login,
  // uploadSingleFile
} from "../store/actions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const Login = (props) => {
  const history = useHistory();
  const {
    store,
    login,
    //uploadSingleFile,
    setShowResetPassword,
  } = props;

  // const [file, setFile] = useState("");

  // const onSingleFileChage = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();

    login(loginCredentials, history);

    // const formData = new FormData();
    // formData.append("file", loginCredentials.file);
    // uploadSingleFile(formData);
  };

  if (store.auth.isAuthenticated) {
    if (store.auth.user.roles.findIndex(({ id }) => id === 1) >= 0)
      return <Redirect to="/in/security" />;

    if (store.auth.user.roles.findIndex(({ id }) => id === 2) >= 0)
      return <Redirect to="/in/settings" />;

    if (store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0)
      return <Redirect to="/in/time-tables/my-day" />;
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <form className="text-center" onSubmit={onSubmit}>
          <p className="text-info mt-3">Login</p>
          <div className="input-group my-4">
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fas fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Email"
              value={loginCredentials.email}
              onChange={(e) => {
                const email = e.target.value;
                setLoginCredentials({ ...loginCredentials, email });
              }}
            />
          </div>
          {/* <div className="input-group my-4">
            <input
              type="file"
              name="file"
              className="form-control"
              accept="application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              placeholder="Select file"
              onChange={(e) => {
                const file = e.target.files[0];
                setLoginCredentials({ ...loginCredentials, file });
              }}
            />
          </div> */}
          <div className="input-group mb-2">
            <div className="input-group-append">
              <span className="input-group-text ">
                <i className="fas fa-key"></i>
              </span>
            </div>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              value={loginCredentials.password}
              onChange={(e) => {
                const password = e.target.value;
                setLoginCredentials({ ...loginCredentials, password });
              }}
            />
          </div>

          <div className="d-flex justify-content-center mt-3 ">
            <button type="submit" name="submit" className="btn btn-info">
              Login
            </button>
          </div>
        </form>
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-center links">
          <button
            className="btn btn-link"
            onClick={() => {
              setShowResetPassword(true);
            }}
          >
            Forgot your password?
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  login,
})(Login);
