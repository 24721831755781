import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import defaultProfile from "../assets/default-profile.jpg";
import logo from "../assets/logo.png";
import { connect } from "react-redux";
import { logout, getSelectedSchool } from "../store/actions";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

const Header = (props) => {
  const { drawerState, setDrawerState, store, logout, getSelectedSchool } =
    props;

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectSchool = (school) => {
    getSelectedSchool(school.id);
    handleCloseMenu();
  };

  if (!store.auth.isAuthenticated) return <Redirect to="/" />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className="app-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setDrawerState(!drawerState);
            }}
          >
            <span className="material-icons">menu</span>
          </IconButton>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <img
              src={logo}
              className="brand_logo mr-2 d-none d-md-inline"
              alt="Logo"
            />
            <strong>
              <span style={{ color: "#4bb9dd" }}>Keza</span>Class
            </strong>
          </Typography>
          <Box className="mr-2">
            <Button
              id="demo-customized-button"
              disableElevation
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOpenMenu}
              sx={{ color: "white", textTransform: "capitalize" }}
              endIcon={
                <span className="material-icons">keyboard_arrow_down</span>
              }
            >
              Select School
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {store &&
                store.auth.user.schools &&
                store.auth.user.schools.map((school, index) => (
                  <MenuItem
                    key={school.id}
                    onClick={() => {
                      handleSelectSchool(school);
                    }}
                  >
                    {school.name}
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {store.auth.user.profilePicture && (
                <Avatar
                  alt="User profile picture"
                  src={`data:image/png;base64,${store.auth.user.profilePicture}`}
                />
              )}
              {!store.auth.user.profilePicture && (
                <Avatar alt="User profile picture" src={defaultProfile} />
              )}
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Link
                  to="/in/profile"
                  className="dropdown-item text-info"
                  style={{ padding: "0.5rem .5rem" }}
                >
                  <i className="fas fa-user mr-1"></i>
                  Profile
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>
                <button
                  onClick={() => logout(history)}
                  className="dropdown-item text-danger"
                  style={{ padding: "0.5rem .5rem" }}
                >
                  <i
                    className="fas fa-sign-out-alt mr-1"
                    style={{ transform: "rotate(180deg)" }}
                  ></i>
                  Logout
                </button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { logout, getSelectedSchool })(Header);
