import _ from "lodash";
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getTeacherClasses } from "../store/actions";
import { Link, Route, Switch, useLocation, Redirect } from "react-router-dom";
import { Box } from "@mui/system";
import ClassStudents from "../components/ClassStudents";

const ClassSettings = (props) => {
  const location = useLocation();
  const { store, getTeacherClasses } = props;

  useEffect(() => {
    getTeacherClasses();
  }, []);

  return (
    <div className="">
      <div className=" row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left px-0 px-md-2 ">
          <div className="text-center mt-2 ml-2 text-md-left">
            Class Settings
          </div>
        </div>
        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1 "
                >
                  {role.name}
                </span>
              ))}
            {store.selectedSchool.schoolLevels && (
              <Chip
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar alt="school">
                    <span className="material-icons">school</span>
                  </Avatar>
                }
                label={store.selectedSchool.name.toUpperCase()}
                className="mr-1"
              />
            )}
          </span>
        </div>
      </div>

      <Card variant="outlined">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            {store && store.appData.teacherClasses && (
              <strong>{store.appData.teacherClasses.length}</strong>
            )}{" "}
            Classes
          </Typography>
        </div>
        <Divider />

        <CardContent className="level-card ">
          <List>
            <Divider />

            {!!store.appData.teacherClasses &&
              store.appData.teacherClasses.map((teacherClass, index) => (
                <ClassListItem key={index} teacherClass={teacherClass} />
              ))}
          </List>

          {store &&
            store.appData.teacherClasses &&
            !store.appData.teacherClasses.length && (
              <div className="text-center">No class found</div>
            )}
        </CardContent>
      </Card>
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getTeacherClasses,
})(ClassSettings);

const ClassListItem = (props) => {
  const { teacherClass } = props;
  const dispatch = useDispatch();

  //ClassFormDialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar>
            <span style={{ fontSize: "12px" }}>P1A</span>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {teacherClass.levelName} {teacherClass.gradeId}{" "}
              {teacherClass.className}
            </span>
          }
          secondary={
            <button
              onClick={() => {
                handleCloseMenu();
                openDialog();
              }}
              type="button"
              className="btn btn-primary btn-sm"
            >
              <span className="badge badge-light">
                {teacherClass.numberOfStudents}
              </span>{" "}
              Students
            </button>
          }
        />

        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                openDialog();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">group</span> Students
            </MenuItem>
            <Divider className="my-1" />

            {/* <MenuItem
              onClick={() => {
                handleCloseMenu();
                 removeSchoolLevelGrade(schoolLevel.id, schoolLevelGrade.id);
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem> */}
          </Menu>
        </Box>
      </ListItem>

      <Divider className="my-1" />

      <ClassStudents
        teacherClass={teacherClass}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
      />
    </React.Fragment>
  );
};
