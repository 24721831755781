import _, { isEmpty } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SchoolLevel from "../components/SchoolLevelCard";
import LevelFormDialog from "../components/LevelFormDialog";
import {
  removeSchool,
  getSchools,
  getSchoolHeadTeachers,
} from "../store/actions";
import { Link } from "react-router-dom";
import SubjectFormDialog from "../components/SubjectFormDialog";
import SchoolFormDialog from "../components/SchoolFormDialog";
import defaultProfile from "../assets/default-profile.jpg";
import axios from "axios";

const Schools = (props) => {
  const {
    store,
    removeSchool,
    getSchools,
    getSchoolCategories,
    getSchoolHeadTeachers,
  } = props;

  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const handleFormDialogOpen = () => {
    setFormDialogOpen(true);
  };

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    categoryId: "",
    district: null,
    sector: null,
    cell: null,
    village: null,
    headTeacher: null,
    schoolLevels: null,
    sdmsSchoolCode: null,
  });
  const handleFormDialogClose = () => {
    setFormData({
      id: null,
      name: "",
      categoryId: "",
      district: null,
      sector: null,
      cell: null,
      village: null,
      headTeacher: null,
      schoolLevels: null,
    });

    setIsEditing(false);
    setFormDialogOpen(false);
  };

  useEffect(() => {
    getSchools();
    getSchoolHeadTeachers();
  }, []);

  const findHeadTeacher = (users) => {
    if (!!users)
      return users.find(({ roles }) => roles.find(({ id }) => id === 2));
    else return null;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isEditing, setIsEditing] = useState(false);

  const [villageDetails, setVillageDetails] = useState({});

  const onEditSchool = async (school, handleCloseMenu) => {
    setIsEditing(true);

    const { data } = await axios.get("/api/village/" + school.villageId);
    setVillageDetails(data);

    setFormData({
      id: school.id,
      sdmsSchoolCode: school.sdmsSchoolCode,
      name: school.name,
      categoryId: school.categoryId,
      headTeacher: findHeadTeacher(school.users),
      schoolLevels: school.schoolLevels,
      district: data.district,
      sector: data.sector,
      cell: data.cell,
      village: data.village,
    });

    handleCloseMenu();
    setFormDialogOpen(true);
  };

  return (
    <>
      <Card variant="outlined" className="level-card mt-2 mb-3">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            <strong>
              {!!store.appData.schools ? +store.appData.schools.length : "0"}{" "}
              Schools
            </strong>
          </Typography>
          {/* <Button
            variant="contained"
            className="ml-2"
            size="small"
            color="secondary"
            onClick={handleFormDialogOpen}
          >
            Add New School
          </Button> */}
        </div>
        <Divider />
        <CardContent>
          <List>
            <Divider />

            {!!store.appData.schools &&
              store.appData.schools.map((school, index) => (
                <SchoolListItem
                  key={school.id}
                  index={index}
                  school={school}
                  findHeadTeacher={findHeadTeacher}
                  removeSchool={removeSchool}
                  onEditSchool={onEditSchool}
                />
              ))}
          </List>
        </CardContent>
      </Card>

      <SchoolFormDialog
        handleFormDialogClose={handleFormDialogClose}
        formDialogOpen={formDialogOpen}
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        villageDetails={villageDetails}
      />

      <div className="fab-container pr-3 pb-3">
        <Tooltip title="Add New School">
          <span className="fab-btn">
            <Fab color="primary" onClick={handleFormDialogOpen}>
              <span className="material-icons">add</span>
            </Fab>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  removeSchool,
  getSchools,
  getSchoolHeadTeachers,
})(Schools);

const SchoolListItem = (props) => {
  const { school, index, findHeadTeacher, removeSchool, onEditSchool } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<>{school.name}</>}
          secondary={
            <span>
              <span
                className={`badge badge-${
                  school.category.id === 1
                    ? "success"
                    : school.category.id === 3
                    ? "secondary"
                    : "primary"
                } mr-2`}
              >
                {school.category.name}{" "}
              </span>
              <span>
                {!!school.schoolLevels && school.schoolLevels[0]
                  ? " | " + school.schoolLevels.length + " Levels"
                  : " | 0 Levels"}
              </span>

              <span className="mr-2">
                {!!school.subjects && school.subjects[0]
                  ? " | " + school.subjects.length + " Subjects"
                  : " | 0 Subjects"}
              </span>

              {!!school.users &&
                school.users[0] &&
                !!findHeadTeacher(school.users) && (
                  <Tooltip title="Head teacher">
                    <Chip
                      component="span"
                      size="small"
                      avatar={
                        <Avatar
                          component="span"
                          alt={findHeadTeacher(school.users).firstName}
                          src={
                            findHeadTeacher(school.users).profilePicture
                              ? `data:image/png;base64,${
                                  findHeadTeacher(school.users).profilePicture
                                }`
                              : defaultProfile
                          }
                        />
                      }
                      label={
                        <span>
                          {findHeadTeacher(school.users).firstName +
                            " " +
                            findHeadTeacher(school.users).lastName}
                        </span>
                      }
                      variant="outlined"
                    />
                  </Tooltip>
                )}
            </span>
          }
        />
        <Box>
          {!!school.sdmsSchoolCode && (
            <Tooltip title="SDMS School Code">
              <Chip
                style={{ borderRadius: "0px" }}
                variant="outlined"
                className="small-chip ml-2 py-0"
                size="small"
                label={school.sdmsSchoolCode}
              />
            </Tooltip>
          )}
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                onEditSchool(school, handleCloseMenu);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">visibility</span>
              Details/Update
            </MenuItem>
            <Divider className="my-1" />

            {/* <MenuItem
              disabled
              onClick={() => {
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">edit</span> Edit
            </MenuItem>
            <Divider className="my-1" /> */}

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                removeSchool(school.id);
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
