import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { saveSchoolTeacher } from "../store/actions";

import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const TeacherFormDialog = (props) => {
  const {
    store,
    formDialogOpen,
    handleFormDialogClose,
    saveSchoolTeacher,
    formData,
    setFormData,
    isEditing,
    setIsEditing,
  } = props;
  const [selectedLevel, setSelectedLevel] = useState("");
  const [levels, setRevels] = useState([]);

  // const handaleSubmit = (e) => {
  //   e.preventDefault();

  //   addSchoolLevel({
  //     schoolId: store.selectedSchool.id,
  //     levelId: selectedLevel,
  //   });

  //   setSelectedLevel("");
  //   handleFormDialogClose();
  // };

  const onSave = () => {
    saveSchoolTeacher(
      {
        id: formData.id,
        schoolId: store.selectedSchool.id,
        sdmsStaffCode: formData.sdmsStaffCode,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        idNumber: formData.idNumber,
        genderId: formData.genderId,
      },
      handleFormDialogClose,
      setFormData,
      setIsEditing
    );
  };

  return (
    <>
      <Dialog open={formDialogOpen} fullWidth scroll="paper">
        <DialogTitle className="text-primary">
          {isEditing ? "Teacher details" : "Add new teacher"}
          <IconButton
            className="close-btn mr-3"
            onClick={handleFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <TextField
                size="small"
                fullWidth
                autoFocus
                label="SDMS Staff Code"
                variant="outlined"
                className="mt-3 "
                name="sdmsStaffCode"
                value={formData.sdmsStaffCode}
                onChange={(e) => {
                  const sdmsStaffCode = e.target.value;
                  setFormData({ ...formData, sdmsStaffCode });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                className="mt-3 "
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  const firstName = e.target.value;
                  setFormData({ ...formData, firstName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                className="mt-3 "
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  const lastName = e.target.value;
                  setFormData({ ...formData, lastName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                disabled={isEditing}
                fullWidth
                label="Email Address"
                variant="outlined"
                className="mt-3 "
                name="email"
                value={formData.email}
                onChange={(e) => {
                  const email = e.target.value;
                  setFormData({ ...formData, email });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                className="mt-3 "
                name="phone"
                value={formData.phone}
                onChange={(e) => {
                  const phone = e.target.value;
                  setFormData({ ...formData, phone });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="ID Number"
                variant="outlined"
                className="mt-3 "
                name="idNumber"
                value={formData.idNumber}
                onChange={(e) => {
                  const idNumber = e.target.value;
                  setFormData({ ...formData, idNumber });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mt-3">
                <InputLabel>Gender</InputLabel>
                <Select
                  labelId="Gender"
                  name="genderId"
                  value={formData.genderId}
                  label="Gender"
                  onChange={(e) => {
                    const genderId = e.target.value;
                    setFormData({ ...formData, genderId });
                  }}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            size="large"
            variant="contained"
            className="mr-2"
            onClick={onSave}
            disabled={
              !(
                formData.sdmsStaffCode &&
                formData.firstName &&
                formData.lastName &&
                formData.email &&
                formData.phone &&
                formData.idNumber &&
                formData.genderId
              ) || store.isLoading
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button
            size="large"
            variant="outlined"
            onClick={handleFormDialogClose}
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { saveSchoolTeacher })(
  TeacherFormDialog
);
