const types = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  LOGOUT: "LOGOUT",
  ADD_NEW_USER: "ADD_NEW_USER",
  ADD_NEW_SCHOOL: "ADD_NEW_SCHOOL",
  UPDATE_SCHOOL: "UPDATE_SCHOOL",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAN_STATE: "CLEAN_STATE",
  UPDATE_PROFILE_PICTURE: "UPDATE_PROFILE_PICTURE",
  SET_LEVELS: "SET_LEVELS",
  ADD_SCHOOL_LEVEL: "ADD_SCHOOL_LEVEL",
  SET_SCHOOL_LEVELS: "SET_SCHOOL_LEVELS",
  REMOVE_SCHOOL_LEVEL: "REMOVE_SCHOOL_LEVEL",
  SET_LEVEL_GRADES: "SET_LEVEL_GRADES",
  ADD_SCHOOL_LEVEL_GRADE: "ADD_SCHOOL_LEVEL_GRADE",
  SET_SELECTED_SCHOOL: "SET_SELECTED_SCHOOL",
  REMOVE_SCHOOL_LEVEL_GRADE: "REMOVE_SCHOOL_LEVEL_GRADE",
  SET_SCHOOLS: "SET_SCHOOLS",
  REMOVE_SCHOOL: "REMOVE_SCHOOL",
  SET_LOOKUP_SCHOOL_CATEGORIES: "SET_LOOKUP_SCHOOL_CATEGORIES",
  SET_LOOKUP_DISTRICTS: "SET_LOOKUP_DISTRICTS",
  SET_LOOKUP_SECTORS: "SET_LOOKUP_SECTORS",
  SET_LOOKUP_CELLS: "SET_LOOKUP_CELLS",
  SET_LOOKUP_VILLAGES: "SET_LOOKUP_VILLAGES",
  SET_HEAD_TEACHERS: "SET_HEAD_TEACHERS",
  ADD_NEW_HEAD_TEACHER: "ADD_NEW_HEAD_TEACHER",
  REMOVE_HEAD_TEACHER: "REMOVE_HEAD_TEACHER",
  UPDATE_HEAD_TEACHER: "UPDATE_HEAD_TEACHER",
  SET_SCHOOL_SUBJECTS: "SET_SCHOOL_SUBJECTS",
  ADD_NEW_SCHOOL_SUBJECT: "ADD_NEW_SCHOOL_SUBJECT",
  UPDATE_SCHOOL_SUBJECT: "UPDATE_SCHOOL_SUBJECT",
  REMOVE_SCHOOL_SUBJECT: "REMOVE_SCHOOL_SUBJECT",
  UPDATE_SCHOOL_LEVEL_GRADE: "UPDATE_SCHOOL_LEVEL_GRADE",
  SET_CLASS_ROOMS: "SET_CLASS_ROOMS",
  ADD_NEW_CLASS_ROOM: "ADD_NEW_CLASS_ROOM",
  UPDATE_CLASS_ROOM: "UPDATE_CLASS_ROOM",
  REMOVE_CLASS_ROOM: "REMOVE_CLASS_ROOM",
  SET_TEACHERS: "SET_TEACHERS",
  ADD_NEW_TEACHER: "ADD_NEW_TEACHER",
  REMOVE_TEACHER: "REMOVE_TEACHER",
  SET_SCHOOL_LEVEL_GRADE_SUBJECTS: "SET_SCHOOL_LEVEL_GRADE_SUBJECTS",
  ADD_NEW_SCHOOL_LEVEL_GRADE_SUBJECT: "ADD_NEW_SCHOOL_LEVEL_GRADE_SUBJECT",
  UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT: "UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT",
  REMOVE_SCHOOL_LEVEL_GRADE_SUBJECT: "REMOVE_SCHOOL_LEVEL_GRADE_SUBJECT",

  SET_SCHOOL_PERIODS: "SET_SCHOOL_PERIODS",
  SET_TEACHER_PERIODS: "SET_TEACHER_PERIODS",
  ADD_NEW_SCHOOL_PERIOD: "ADD_NEW_SCHOOL_PERIOD",
  UPDATE_SCHOOL_PERIOD: "UPDATE_SCHOOL_PERIOD",
  REMOVE_SCHOOL_PERIOD: "REMOVE_SCHOOL_PERIOD",

  SET_SCHOOL_LEVEL_GRADE_PERIODS: "SET_SCHOOL_LEVEL_GRADE_PERIODS",
  ADD_NEW_SCHOOL_LEVEL_GRADE_PERIOD: "ADD_NEW_SCHOOL_LEVEL_GRADE_PERIOD",
  REMOVE_SCHOOL_LEVEL_GRADE_PERIOD: "REMOVE_SCHOOL_LEVEL_GRADE_PERIOD",
  UPDATE_SCHOOL_LEVEL_GRADE_PERIOD: "UPDATE_SCHOOL_LEVEL_GRADE_PERIOD",

  SET_CLASS_ROOM_TEACHERS: "SET_CLASS_ROOM_TEACHERS",
  ADD_NEW_CLASS_ROOM_TEACHER: "ADD_NEW_CLASS_ROOM_TEACHER",
  UPDATE_CLASS_ROOM_TEACHER: "UPDATE_CLASS_ROOM_TEACHER",
  REMOVE_CLASS_ROOM_TEACHER: "REMOVE_CLASS_ROOM_TEACHER",

  SET_CLASS_TIMETABLE: "SET_CLASS_TIMETABLE",
  SET_TEACHER_TIMETABLE: "SET_TEACHER_TIMETABLE",
  SET_TEACHER_CLASSES: "SET_TEACHER_CLASSES",
  SET_CLASS_STUDENTS: "SET_CLASS_STUDENTS",
  ADD_NEW_STUDENT: "ADD_NEW_STUDENT",
  UPDATE_STUDENT: "UPDATE_STUDENT",
  REMOVE_STUDENT: "REMOVE_STUDENT",
  SET_DAILY_TEACHER_PERIODS: "SET_DAILY_TEACHER_PERIODS",
  SET_TEACHER_ATTENDANCES: "SET_TEACHER_ATTENDANCES",
  SCHOOL_TIME_TABLE_CLASSES: "SCHOOL_TIME_TABLE_CLASSES",
  REMOVE_HEAD_TEACHER_SCHOOL: "REMOVE_HEAD_TEACHER_SCHOOL",
  UPDATE_TEACHER: "UPDATE_TEACHER",
};

export default types;
