import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { login, addSchoolLevelGrade, addClassRoom } from "../store/actions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

const ClassFormDialog = (props) => {
  const {
    store,
    schoolLevel,
    schoolLevelGrade,
    isClassFormDialogOpen,
    closeClassFormDialog,
    addClassRoom,
  } = props;

  const [classRoom, setClassRoom] = useState({
    levelId: schoolLevel.levelId,
    schoolLevelGradeId: schoolLevelGrade.id,
    name: "",
    isBeforeNoon: true,
    isAfterNoon: true,
  });

  const handaleSubmit = () => {
    addClassRoom(classRoom, closeClassFormDialog, setClassRoom);
  };

  return (
    <Dialog open={isClassFormDialogOpen}>
      <DialogTitle>
        {schoolLevel.level.name}
        {schoolLevelGrade.grade.name}
        <IconButton className="close-btn mr-3" onClick={closeClassFormDialog}>
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "240px" }}>
        <div>
          <FormControl className="mt-2">
            <TextField
              label="Class (Eg.: A)"
              variant="outlined"
              className="mt-3 "
              placeholder="Eg.: A"
              autoFocus
              value={classRoom.name}
              onChange={(e) => {
                if (
                  !!e.target.value &&
                  !isNaN(e.target.value[e.target.value.length - 1])
                )
                  return;

                const name = e.target.value.toUpperCase();
                setClassRoom({ ...classRoom, name });
              }}
            />
          </FormControl>
          <span className="d-flex flex-wrap align-items-center">
            <FormGroup row className="d-inline ">
              <FormControlLabel
                style={{ color: "#7b7d7d" }}
                control={
                  <Checkbox
                    style={{ color: "#0f7197" }}
                    size="small"
                    checked={classRoom.isBeforeNoon}
                    onChange={(e) => {
                      setClassRoom({
                        ...classRoom,
                        isBeforeNoon: e.target.checked,
                      });
                    }}
                  />
                }
                label="Beforenoon"
              ></FormControlLabel>
              <FormControlLabel
                style={{ color: "#7b7d7d" }}
                control={
                  <Checkbox
                    style={{ color: "#0f7197" }}
                    color="info"
                    size="small"
                    checked={classRoom.isAfterNoon}
                    onChange={(e) => {
                      setClassRoom({
                        ...classRoom,
                        isAfterNoon: e.target.checked,
                      });
                    }}
                  />
                }
                label="Afternoon"
              ></FormControlLabel>
            </FormGroup>
          </span>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-3">
        <Button variant="contained" onClick={handaleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { addClassRoom })(ClassFormDialog);
