import _ from "lodash";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SchoolLevel from "../components/SchoolLevelCard";
import LevelFormDialog from "../components/LevelFormDialog";
import {
  getSchoolHeadTeachers,
  removeHeadTeacher,
  assignHeadTeacherSchool,
  removeHeadTeacherSchool,
  getSchools,
} from "../store/actions";
import { Link } from "react-router-dom";
import SubjectFormDialog from "../components/SubjectFormDialog";
import HeadTeacherFormDialog from "../components/HeadTeacherFormDialog";
import defaultProfile from "../assets/default-profile.jpg";
import { Box } from "@mui/system";

const HeadTeachers = (props) => {
  const {
    store,
    getSchoolHeadTeachers,
    removeHeadTeacher,
    assignHeadTeacherSchool,
    removeHeadTeacherSchool,
    getSchools,
  } = props;

  const [levelFormDialogOpen, setLevelFormDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    sdmsStaffCode: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    idNumber: "",
    genderId: "",
  });

  const handleLevelFormDialogOpen = () => {
    setLevelFormDialogOpen(true);
  };
  const handleLevelFormDialogClose = () => {
    setIsEditing(false);
    setLevelFormDialogOpen(false);
    setFormData({
      id: null,
      sdmsStaffCode: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      idNumber: "",
      genderId: "",
    });
  };

  useEffect(() => {
    getSchools();
    getSchoolHeadTeachers();
  }, []);

  const [assignmentDialogOpen, setAssignmentDialogOpen] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedHeadTeacher, setSelectedHeadTeacher] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Card variant="outlined" className="level-card mt-2 mb-3">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            <strong>
              {!!store.appData.headTeachers
                ? +store.appData.headTeachers.length
                : "0"}{" "}
              Head Teachers
            </strong>
          </Typography>
          {/* <Button
            variant="contained"
            color="secondary"
            className="ml-2"
            size="small"
            onClick={handleLevelFormDialogOpen}
          >
            Add New Head Teacher
          </Button> */}
        </div>
        <Divider />
        <CardContent>
          <List>
            <Divider />

            {!!store.appData.headTeachers &&
              store.appData.headTeachers.map((headTeacher) => (
                <HeadTeacherListItem
                  headTeacher={headTeacher}
                  setSelectedHeadTeacher={setSelectedHeadTeacher}
                  setAssignmentDialogOpen={setAssignmentDialogOpen}
                  key={headTeacher.id}
                  removeHeadTeacherSchool={removeHeadTeacherSchool}
                  removeHeadTeacher={removeHeadTeacher}
                  setFormData={setFormData}
                  setIsEditing={setIsEditing}
                  handleLevelFormDialogOpen={handleLevelFormDialogOpen}
                />
              ))}
          </List>
          {!!store.appData.headTeachers &&
            !store.appData.headTeachers.length && (
              <div className="text-center">No item found</div>
            )}
        </CardContent>
      </Card>

      <HeadTeacherFormDialog
        handleLevelFormDialogClose={handleLevelFormDialogClose}
        levelFormDialogOpen={levelFormDialogOpen}
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

      <Dialog open={assignmentDialogOpen} fullWidth scroll="paper">
        <DialogTitle className="text-primary">
          {}
          {!!selectedHeadTeacher?.schools &&
            selectedHeadTeacher?.schools.length > 0 && (
              <>
                Tansfer{" "}
                {!!selectedHeadTeacher.roles?.find(({ id }) => id === 4)
                  ? "DOS"
                  : "Head Teacher"}{" "}
              </>
            )}
          {!(
            !!selectedHeadTeacher?.schools &&
            selectedHeadTeacher?.schools?.length > 0
          ) && <>Assign School</>}{" "}
          | {selectedHeadTeacher?.firstName} {selectedHeadTeacher?.lastName}
          <IconButton
            className="close-btn mr-3"
            onClick={() => setAssignmentDialogOpen(false)}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "50vh" }}>
          {!!selectedHeadTeacher?.schools &&
            selectedHeadTeacher?.schools?.length > 0 && (
              <TextField
                size="small"
                className="mt-4"
                fullWidth
                disabled
                value={selectedHeadTeacher?.schools[0].name || ""}
                label="From"
                placeholder="From"
              />
            )}
          {!!store.appData.schools && (
            <FormControl fullWidth className="mt-3">
              <Autocomplete
                size="small"
                disablePortal
                defaultValue={null}
                value={selectedSchool}
                options={store.appData.schools}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${
                      !(
                        !!selectedHeadTeacher?.schools &&
                        selectedHeadTeacher?.schools?.length > 0
                      )
                        ? "Select school"
                        : "To"
                    }`}
                    placeholder={`${
                      !(
                        !!selectedHeadTeacher?.schools &&
                        selectedHeadTeacher?.schools?.length > 0
                      )
                        ? "Select school"
                        : "To"
                    }`}
                  />
                )}
                onChange={(event, newValue) => {
                  setSelectedSchool(newValue || null);
                }}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            size="large"
            variant="contained"
            className="mr-2"
            disabled={!selectedSchool || store.isLoading}
            onClick={() =>
              assignHeadTeacherSchool(
                {
                  id: selectedHeadTeacher.id,
                  schoolId: selectedSchool.id,
                },
                setAssignmentDialogOpen
              )
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button
            size="large"
            variant="outlined"
            onClick={() => setAssignmentDialogOpen(false)}
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>

      <div className="fab-container pr-3 pb-3">
        <Tooltip title="Add New Head Teacher">
          <span className="fab-btn">
            <Fab color="primary" onClick={handleLevelFormDialogOpen}>
              <span className="material-icons">add</span>
            </Fab>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getSchoolHeadTeachers,
  removeHeadTeacher,
  assignHeadTeacherSchool,
  removeHeadTeacherSchool,
  getSchools,
})(HeadTeachers);

const HeadTeacherListItem = (props) => {
  const {
    headTeacher,
    setSelectedHeadTeacher,
    setAssignmentDialogOpen,
    removeHeadTeacherSchool,
    removeHeadTeacher,
    setFormData,
    setIsEditing,
    handleLevelFormDialogOpen,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt="User profile picture"
            src={
              headTeacher.profilePicture
                ? `data:image/png;base64,${headTeacher.profilePicture}`
                : defaultProfile
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {headTeacher?.firstName} {headTeacher?.lastName}
            </span>
          }
          secondary={
            <>
              {!!headTeacher.schools &&
                !!headTeacher.schools[0] &&
                headTeacher.schools.map((school, index) => (
                  <Chip
                    component="span"
                    className="mr-2"
                    key={index}
                    size="small"
                    label={school.name}
                    variant="outlined"
                    // onDelete={() =>
                    //   removeHeadTeacherSchool(headTeacher.id, school.id)
                    // }
                  />
                ))}
            </>
          }
        />
        <Box>
          {!!headTeacher.sdmsStaffCode && (
            <Tooltip title="SDMS Staff Code">
              <Chip
                style={{ borderRadius: "0px" }}
                variant="outlined"
                className="small-chip mx-2  py-0"
                size="small"
                label={headTeacher.sdmsStaffCode}
              />
            </Tooltip>
          )}
          {!!headTeacher.roles?.find(({ id }) => id === 2) && (
            <span className="badge badge-success">HeadTeacher</span>
          )}

          {!!headTeacher.roles?.find(({ id }) => id === 4) && (
            <span className="badge badge-primary">DOS</span>
          )}
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                setFormData(headTeacher);
                setIsEditing(true);
                handleCloseMenu();
                handleLevelFormDialogOpen();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">visibility</span>{" "}
              Details/Update
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                setSelectedHeadTeacher(headTeacher);
                handleCloseMenu();
                setAssignmentDialogOpen(true);
              }}
              className="text-dark font-weight-light"
            >
              {!(headTeacher?.schools?.length > 0) && (
                <>
                  {" "}
                  <span className="material-icons mr-2">add</span> Assign school
                </>
              )}
              {headTeacher?.schools?.length > 0 && (
                <>
                  {" "}
                  <span className="material-icons mr-2">shortcut</span> Transfer
                </>
              )}
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                removeHeadTeacher(headTeacher.id);
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete_forever</span> Remove
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
