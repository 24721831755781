import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { login, addSchoolLevelGrade } from "../store/actions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

const GradeFormDialog = (props) => {
  const {
    store,
    schoolLevel,
    gradeFormDialogOpen,
    handleGradeFormDialogClose,
    addSchoolLevelGrade,
  } = props;

  const [selectedGrade, setSelectedGrade] = useState("");
  const [levelGrades, setLevelGrades] = useState([]);

  const handaleSubmit = () => {
    addSchoolLevelGrade(
      {
        schoolId: store.selectedSchool.id,
        levelId: schoolLevel.levelId,
        gradeId: selectedGrade,
      },
      setSelectedGrade,
      handleGradeFormDialogClose
    );
  };

  useEffect(() => {
    let tempGrades = [];

    const schoolLevels = [...(store.selectedSchool.schoolLevels || [])];

    const grades = [
      ...(store.lookups[`levelGrades_${schoolLevel.levelId}`] || []),
    ];

    const index = schoolLevels.findIndex(
      ({ levelId }) => levelId === schoolLevel.levelId
    );

    const schoolLevelGrades = [
      ...(schoolLevels[index].schoolLevelGrades || []),
    ];

    grades.forEach((item) => {
      if (schoolLevelGrades.findIndex(({ grade }) => grade.id === item.id) < 0)
        tempGrades.push(item);
    });

    setLevelGrades(tempGrades);
  }, [store]);

  return (
    <>
      <Dialog open={gradeFormDialogOpen}>
        <DialogTitle className="text-primary">
          Add Grade
          <IconButton
            className="close-btn mr-3"
            onClick={handleGradeFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "240px" }}>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="label">Grade</InputLabel>
            <Select
              labelId="label"
              name="Grade"
              value={selectedGrade}
              label="Grade"
              onChange={(e) => {
                setSelectedGrade(e.target.value);
              }}
            >
              {store &&
                store.lookups[`levelGrades_${schoolLevel.levelId}`] &&
                levelGrades.map((grade, index) => (
                  <MenuItem key={grade.id} value={grade.id}>
                    {`${schoolLevel.level.acronym} ${grade.name}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            variant="contained"
            onClick={handaleSubmit}
            disabled={!selectedGrade || store.isLoading}
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button variant="outlined" onClick={handleGradeFormDialogClose}>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { addSchoolLevelGrade })(
  GradeFormDialog
);
