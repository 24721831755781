import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import AttendanceForm from "./AttendanceForm";
const DailyTimaTableCard = (props) => {
  const { period } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Card
        className={`mt-3 ${
          period.status === "Active"
            ? "bg-light"
            : period.status === "Pending"
            ? "bg-pending"
            : "bg-transparent"
        } `}
        variant="outlined"
        style={{ border: "1px solid #0f7197" }}
      >
        <CardContent>
          <div className="row ">
            <div className="col-12 text-center">
              <Chip
                size="small"
                label={
                  <span className="font-weight-bold">
                    {period.startAt} - {period.endAt}
                  </span>
                }
                color="primary"
                variant="outlined"
              />
            </div>
            <div className="col-12 text-center">
              <Typography color="text.primary text-left">
                {period.subjectName}
              </Typography>
            </div>
            <div className="col-12 text-center">
              <Typography color="text.primary text-left ">
                @{period.levelName}
                {period.gradeId}
                {period.className}
              </Typography>{" "}
              {period.status === "Ended" && (
                <span className="badge badge-danger">Ended</span>
              )}
              {period.status === "Active" && (
                <span className="badge badge-success">Active</span>
              )}
              {period.status === "Pending" && (
                <span className="badge badge-info">Pending</span>
              )}
            </div>
          </div>

          <div className="text-center mt-2">
            {period.status !== "Ended" && (
              <Button
                variant="contained"
                color="info"
                size="small"
                disabled={period.status !== "Active"}
                onClick={openDialog}
              >
                Make Attendance
              </Button>
            )}
            {period.status === "Ended" && (
              <button
                onClick={openDialog}
                type="button"
                className="btn btn-secondary btn-sm"
              >
                View Attendance
              </button>
            )}
          </div>
        </CardContent>
        <AttendanceForm
          isDialogOpen={isDialogOpen}
          period={period}
          closeDialog={closeDialog}
        />
      </Card>
    </>
  );
};

export default DailyTimaTableCard;
