import isEmpty from "lodash/isEmpty";
import types from "./types";
import { combineReducers } from "redux";
import { getAuthState, defaultState } from "./utils";
import _ from "lodash";

const auth = (authState = getAuthState(), action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user || {},
        token: action.token,
      };

    case types.UPDATE_PROFILE_PICTURE: {
      const newAuthState = { ...authState };
      newAuthState.user.profilePicture = action.profilePicture;
      return newAuthState;
    }

    case types.CLEAN_STATE:
      return defaultState.auth;

    default:
      return authState;
  }
};

const isLoading = (loadingState = defaultState.isLoading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.isLoading;

    default:
      return loadingState;
  }
};

const lookups = (lookupState = defaultState.lookups, action) => {
  switch (action.type) {
    case types.SET_LEVELS: {
      const newState = { ...lookupState };
      newState.levels = action.data;
      return newState;
    }

    case types.SET_LEVEL_GRADES: {
      const { levelId, data } = action.data;
      const newState = { ...lookupState };
      newState[`levelGrades_${levelId}`] = data;
      return newState;
    }

    case types.REMOVE_SCHOOL_LEVEL: {
      const newState = { ...lookupState };
      delete newState[`levelGrades_${action.levelId}`];
      return newState;
    }

    case types.SET_LOOKUP_SCHOOL_CATEGORIES: {
      const newState = { ...lookupState };
      newState.schoolCategories = action.data;
      return newState;
    }

    case types.SET_LOOKUP_DISTRICTS: {
      const newState = { ...lookupState };
      newState.districts = action.data;
      return newState;
    }

    case types.SET_LOOKUP_SECTORS: {
      const newState = { ...lookupState };
      newState.sectors = action.data;
      return newState;
    }

    case types.SET_LOOKUP_CELLS: {
      const newState = { ...lookupState };
      newState.cells = action.data;
      return newState;
    }

    case types.SET_LOOKUP_VILLAGES: {
      const newState = { ...lookupState };
      newState.villages = action.data;
      return newState;
    }

    case types.CLEAN_STATE:
      return defaultState.lookups;

    default:
      return lookupState;
  }
};

const appData = (appState = defaultState.appData, action) => {
  switch (action.type) {
    // =============schools============== //
    case types.SET_SCHOOLS: {
      const newState = { ...appState };
      newState.schools = action.data;
      return newState;
    }

    case types.ADD_NEW_SCHOOL: {
      const newState = { ...appState };
      newState.schools.push(action.data);
      return newState;
    }

    case types.REMOVE_SCHOOL: {
      const newState = { ...appState };

      newState.schools.splice(
        newState.schools.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    case types.UPDATE_SCHOOL: {
      const newState = { ...appState };

      newState.schools[
        newState.schools.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    // =============headTeachers============== //
    case types.SET_HEAD_TEACHERS: {
      const newState = { ...appState };
      newState.headTeachers = action.data;
      return newState;
    }

    case types.ADD_NEW_HEAD_TEACHER: {
      const newState = { ...appState };
      newState.headTeachers.push(action.data);
      return newState;
    }

    case types.UPDATE_HEAD_TEACHER: {
      const newState = { ...appState };

      newState.headTeachers[
        newState.headTeachers.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_HEAD_TEACHER: {
      const newState = { ...appState };

      newState.headTeachers.splice(
        newState.headTeachers.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    case types.REMOVE_HEAD_TEACHER_SCHOOL: {
      const newState = { ...appState };

      const schools =
        newState.headTeachers[
          newState.headTeachers.findIndex(({ id }) => id === action.data.userId)
        ].schools;

      schools.splice(
        schools.findIndex(({ id }) => id === action.schoolId),
        1
      );

      newState.headTeachers[
        newState.headTeachers.findIndex(({ id }) => id === action.data.userId)
      ].schools = schools;

      return newState;
    }

    // =============schoolSubjects============== //
    case types.SET_SCHOOL_SUBJECTS: {
      const newAppState = { ...appState };
      newAppState.schoolSubjects = action.data;
      return newAppState;
    }

    case types.ADD_NEW_SCHOOL_SUBJECT: {
      const newAppState = { ...appState };
      newAppState.schoolSubjects.push(action.data);
      return newAppState;
    }

    case types.UPDATE_SCHOOL_SUBJECT: {
      const newState = { ...appState };

      newState.schoolSubjects[
        newState.schoolSubjects.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_SCHOOL_SUBJECT: {
      const newState = { ...appState };

      newState.schoolSubjects.splice(
        newState.schoolSubjects.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    // =============classRooms============== //
    case types.SET_CLASS_ROOMS: {
      const newAppState = { ...appState };
      newAppState.classRooms = action.data || [];
      return newAppState;
    }

    case types.ADD_NEW_CLASS_ROOM: {
      const newState = { ...appState };

      newState.classRooms.push(action.data);

      return newState;
    }

    case types.UPDATE_CLASS_ROOM: {
      const newState = { ...appState };

      newState.classRooms[
        newState.classRooms.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_CLASS_ROOM: {
      const newState = { ...appState };

      newState.classRooms.splice(
        newState.classRooms.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    // =============gradeSubjects============== //
    case types.SET_SCHOOL_LEVEL_GRADE_SUBJECTS: {
      const newAppState = { ...appState };
      newAppState.gradeSubjects = action.data || [];
      return newAppState;
    }

    case types.ADD_NEW_SCHOOL_LEVEL_GRADE_SUBJECT: {
      const newState = { ...appState };

      newState.gradeSubjects.push(action.data);

      return newState;
    }

    case types.UPDATE_SCHOOL_LEVEL_GRADE_SUBJECT: {
      const newState = { ...appState };

      newState.gradeSubjects[
        newState.gradeSubjects.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_SCHOOL_LEVEL_GRADE_SUBJECT: {
      const newState = { ...appState };

      newState.gradeSubjects.splice(
        newState.gradeSubjects.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    // =============teachers============== //
    case types.SET_TEACHERS: {
      const newState = { ...appState };
      newState.teachers = action.data;
      return newState;
    }

    case types.ADD_NEW_TEACHER: {
      const newState = { ...appState };
      newState.teachers.push(action.data);
      return newState;
    }

    case types.UPDATE_TEACHER: {
      const newState = { ...appState };

      newState.teachers[
        newState.teachers.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_TEACHER: {
      const newState = { ...appState };

      newState.teachers.splice(
        newState.teachers.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    // ============schoolPeriods============ //
    case types.SET_SCHOOL_PERIODS: {
      const newState = { ...appState };
      newState.schoolPeriods = action.data;
      return newState;
    }

    case types.ADD_NEW_SCHOOL_PERIOD: {
      const newState = { ...appState };
      newState.schoolPeriods.push(action.data);
      return newState;
    }

    case types.UPDATE_SCHOOL_PERIOD: {
      const newState = { ...appState };

      newState.schoolPeriods[
        newState.schoolPeriods.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_SCHOOL_PERIOD: {
      const newState = { ...appState };
      newState.schoolPeriods.splice(
        newState.schoolPeriods.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    // ============teacherPeriods=============== //
    case types.SET_TEACHER_PERIODS: {
      const newState = { ...appState };
      newState.teacherPeriods = action.data;
      return newState;
    }

    // ==============gradePeriods============= //
    case types.SET_SCHOOL_LEVEL_GRADE_PERIODS: {
      const newAppState = { ...appState };
      newAppState.gradePeriods = action.data || [];
      return newAppState;
    }

    case types.ADD_NEW_SCHOOL_LEVEL_GRADE_PERIOD: {
      const newState = { ...appState };

      newState.gradePeriods.push(action.data);

      return newState;
    }

    case types.REMOVE_SCHOOL_LEVEL_GRADE_PERIOD: {
      const newState = { ...appState };

      newState.gradePeriods.splice(
        newState.gradePeriods.findIndex(({ id }) => id === action.id),
        1
      );

      return newState;
    }

    case types.UPDATE_SCHOOL_LEVEL_GRADE_PERIOD: {
      const newState = { ...appState };

      newState.gradePeriods[
        newState.gradePeriods.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    // ==============classRoomTeachers============= //
    case types.SET_CLASS_ROOM_TEACHERS: {
      const newAppState = { ...appState };
      newAppState.classRoomTeachers = action.data || [];
      return newAppState;
    }

    case types.ADD_NEW_CLASS_ROOM_TEACHER: {
      const newState = { ...appState };

      newState.classRoomTeachers.push(action.data);

      return newState;
    }

    case types.UPDATE_CLASS_ROOM_TEACHER: {
      const newState = { ...appState };

      newState.classRoomTeachers[
        newState.classRoomTeachers.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      return newState;
    }

    case types.REMOVE_CLASS_ROOM_TEACHER: {
      const newState = { ...appState };

      newState.classRoomTeachers.splice(
        newState.classRoomTeachers.findIndex(({ id }) => id === action.userId),
        1
      );

      return newState;
    }

    // ===============classTimeTable============ //
    case types.SET_CLASS_TIMETABLE: {
      const newAppState = { ...appState };
      newAppState.classTimeTable = action.data || [];
      return newAppState;
    }

    //==============teacherTimeTable==============//
    case types.SET_TEACHER_TIMETABLE: {
      const newAppState = { ...appState };
      newAppState.teacherTimeTable = action.data || [];
      return newAppState;
    }

    //==============teacherClasses==============//
    case types.SET_TEACHER_CLASSES: {
      const newAppState = { ...appState };
      newAppState.teacherClasses = action.data || [];
      return newAppState;
    }

    //==============classStudents==============//
    case types.SET_CLASS_STUDENTS: {
      const newAppState = { ...appState };
      newAppState.classStudents = action.data || [];
      return newAppState;
    }

    case types.ADD_NEW_STUDENT: {
      const newAppState = { ...appState };
      const classStudents = [...(newAppState.classStudents || [])];
      const teacherClasses = [...(newAppState.teacherClasses || [])];

      classStudents.push(action.data);

      teacherClasses[
        teacherClasses.findIndex(({ classId }) => classId === action.classId)
      ].numberOfStudents++;

      newAppState.classStudents = classStudents;
      newAppState.teacherClasses = teacherClasses;
      return newAppState;
    }

    case types.UPDATE_STUDENT: {
      const newAppState = { ...appState };
      const classStudents = [...(newAppState.classStudents || [])];

      classStudents[
        classStudents.findIndex(({ id }) => id === action.data.id)
      ] = action.data;

      newAppState.classStudents = classStudents;
      return newAppState;
    }

    case types.REMOVE_STUDENT: {
      const newAppState = { ...appState };
      const classStudents = [...(newAppState.classStudents || [])];
      const teacherClasses = [...(newAppState.teacherClasses || [])];

      classStudents.splice(
        classStudents.findIndex(({ id }) => id === action.studentId),
        1
      );

      teacherClasses[
        teacherClasses.findIndex(({ classId }) => classId === action.classId)
      ].numberOfStudents--;

      newAppState.classStudents = classStudents;
      newAppState.teacherClasses = teacherClasses;

      return newAppState;
    }

    //==============dailyTeacherPeriods==============//
    case types.SET_DAILY_TEACHER_PERIODS: {
      const newAppState = { ...appState };
      newAppState.dailyTeacherPeriods = action.data || [];
      return newAppState;
    }

    //==============teacherAttendances==============//
    case types.SET_TEACHER_ATTENDANCES: {
      const newAppState = { ...appState };
      newAppState.teacherAttendances = action.data || [];
      return newAppState;
    }

    //==============schoolTimeTableClasses==============//
    case types.SCHOOL_TIME_TABLE_CLASSES: {
      const newAppState = { ...appState };
      newAppState.schoolTimeTableClasses = action.data || [];
      return newAppState;
    }

    case types.CLEAN_STATE:
      return defaultState.appData;

    default:
      return appState;
  }
};

const selectedSchool = (
  selectedSchoolState = defaultState.selectedSchool,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_SCHOOL: {
      const newState = { ...action.data };
      const schoolLevels = [...(newState.schoolLevels || [])];

      newState.schoolLevels = [...schoolLevels].sort((a, b) =>
        a.levelId > b.levelId ? 1 : b.levelId > a.levelId ? -1 : 0
      );

      newState.schoolLevels.forEach((schoolLevel) => {
        const schoolLevelGrades = [...(schoolLevel.schoolLevelGrades || [])];

        schoolLevel.schoolLevelGrades = schoolLevelGrades.sort((a, b) =>
          a.gradeId > b.gradeId ? 1 : b.gradeId > a.gradeId ? -1 : 0
        );
      });

      return newState;
    }

    case types.ADD_SCHOOL_LEVEL: {
      const newState = { ...selectedSchoolState };
      const schoolLevels = [...(newState.schoolLevels || [])];
      schoolLevels.push({ ...action.data });

      newState.schoolLevels = [...schoolLevels].sort((a, b) =>
        a.levelId > b.levelId ? 1 : b.levelId > a.levelId ? -1 : 0
      );
      return newState;
    }

    case types.ADD_SCHOOL_LEVEL_GRADE: {
      const { levelId, data } = action.data;
      const newState = { ...selectedSchoolState };
      const schoolLevels = [...(newState.schoolLevels || [])];

      const index = schoolLevels.findIndex(({ level }) => level.id === levelId);
      const schoolLevelGrades = schoolLevels[index].schoolLevelGrades || [];
      schoolLevelGrades.push(data);
      schoolLevels[index].schoolLevelGrades = [...schoolLevelGrades].sort(
        (a, b) => (a.gradeId > b.gradeId ? 1 : b.gradeId > a.gradeId ? -1 : 0)
      );
      newState.schoolLevels = schoolLevels;
      return newState;
    }

    case types.UPDATE_SCHOOL_LEVEL_GRADE: {
      const { levelId, schoolLevelGradeId, data } = action.data;
      const newState = { ...selectedSchoolState };
      const schoolLevels = [...(newState.schoolLevels || [])];
      const levelIndex = schoolLevels.findIndex(
        ({ level }) => level.id === levelId
      );
      const schoolLevelGrades =
        schoolLevels[levelIndex].schoolLevelGrades || [];

      const gradeIndex = schoolLevelGrades.findIndex(
        ({ id }) => id === schoolLevelGradeId
      );
      schoolLevelGrades[gradeIndex] = data;

      schoolLevels[levelIndex].schoolLevelGrades = [...schoolLevelGrades];

      newState.schoolLevels = schoolLevels;
      return newState;
    }

    case types.REMOVE_SCHOOL_LEVEL: {
      const newState = { ...selectedSchoolState };
      newState.schoolLevels.splice(
        newState.schoolLevels.findIndex(
          ({ id }) => id === action.schoolLevelId
        ),
        1
      );
      return newState;
    }

    case types.REMOVE_SCHOOL_LEVEL_GRADE: {
      const newState = { ...selectedSchoolState };
      const index = newState.schoolLevels.findIndex(
        ({ id }) => id === action.schoolLevelId
      );

      newState.schoolLevels[index].schoolLevelGrades.splice(
        newState.schoolLevels[index].schoolLevelGrades.findIndex(
          ({ id }) => id === action.schoolLevelGradeId
        ),
        1
      );

      return newState;
    }

    case types.CLEAN_STATE:
      return defaultState.selectedSchool;

    default:
      return selectedSchoolState;
  }
};

export default combineReducers({
  auth,
  isLoading,
  lookups,
  appData,
  selectedSchool,
});
