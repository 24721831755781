import _ from "lodash";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Fab,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLevels } from "../store/actions";
import { Link, Route, Switch, useLocation, Redirect } from "react-router-dom";
import Subjects from "./Subjects";
import Teachers from "./Teachers";
import Levels from "./Levels";
import Periods from "./Periods";
import ClassSettings from "./ClassSettings";

const Settings = (props) => {
  const location = useLocation();
  const { store, getLevels } = props;

  useEffect(() => {
    getLevels();
  }, []);

  return (
    <div className="">
      <div className="text-center  text-md-left">School settings</div>

      <div className=" row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left px-0 px-md-2">
          <Link to="/in/settings/levels" className="mr-2 ">
            <Button
              size="small"
              // variant="outlined"
              className={`${
                location.pathname.includes("/in/settings/levels")
                  ? "outlined active  mb-2"
                  : " mb-2"
              } `}
            >
              Levels
            </Button>
          </Link>

          <Link to="/in/settings/teachers" className="mr-2 ">
            <Button
              size="small"
              className={`${
                location.pathname.includes("/in/settings/teachers")
                  ? "outlined active mb-2"
                  : "mb-2"
              } `}
            >
              Teachers
            </Button>
          </Link>
          <Link to="/in/settings/subjects" className="mr-2">
            <Button
              size="small"
              className={`${
                location.pathname.includes("/in/settings/subjects")
                  ? "outlined active mb-2"
                  : "mb-2"
              } `}
            >
              Subjects
            </Button>
          </Link>
          <Link to="/in/settings/periods" className="mr-2">
            <Button
              size="small"
              className={`${
                location.pathname.includes("/in/settings/periods")
                  ? "outlined active mb-2"
                  : "mb-2"
              } `}
            >
              Periods
            </Button>
          </Link>
        </div>
        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1"
                >
                  {role.name}
                </span>
              ))}
            {store.selectedSchool.schoolLevels && (
              <Chip
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar alt="school">
                    <span className="material-icons">school</span>
                  </Avatar>
                }
                label={store.selectedSchool.name.toUpperCase()}
                className="mr-1"
              />
            )}
          </span>
        </div>
      </div>

      <Switch>
        <Route path="/in/settings/subjects" component={Subjects} />
        <Route path="/in/settings/teachers" component={Teachers} />
        <Route path="/in/settings/levels" component={Levels} />
        <Route path="/in/settings/periods" component={Periods} />
        <Route
          path="/in/settings"
          component={() => <Redirect to="/in/settings/levels" />}
        />
      </Switch>
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getLevels,
})(Settings);
