import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { addNewSchoolPeriod } from "../store/actions";
import { connect } from "react-redux";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const PeriodFormDialog = (props) => {
  const {
    store,
    addNewSchoolPeriod,
    closePeriodFormDialog,
    openPeriodFormDialog,
    selectedSchoolLevel,
    filterLevelPeriod,
    formData,
    setFormData,
    isEditing,
  } = props;

  useEffect(() => {
    if (!!selectedSchoolLevel && openPeriodFormDialog && !isEditing) {
      setFormData({
        ...formData,
        periodNo: filterLevelPeriod(selectedSchoolLevel.id).length + 1,
        schoolLevelId: selectedSchoolLevel.id,
        schoolId: store.selectedSchool.id,
        durationMin: "40",
      });
    }
  }, [openPeriodFormDialog]);

  useEffect(() => {
    if (
      !!formData.startAt &&
      !!formData.durationMin &&
      formData.startAt.toString() !== "Invalid Date"
    ) {
      const endAt = new Date(formData.startAt);
      endAt.setMinutes(endAt.getMinutes() + +formData.durationMin);

      setFormData({ ...formData, endAt });
    } else {
      setFormData({ ...formData, endAt: null });
    }
  }, [formData.startAt, formData.durationMin]);

  const onSave = () => {
    const startAt =
      formData.startAt.getHours().toString().padStart(2, "0") +
      ":" +
      formData.startAt.getMinutes().toString().padStart(2, "0");

    const endAt =
      formData.endAt.getHours().toString().padStart(2, "0") +
      ":" +
      formData.endAt.getMinutes().toString().padStart(2, "0");
    addNewSchoolPeriod(
      { ...formData, startAt, endAt },
      setFormData,
      closePeriodFormDialog
    );
  };

  return (
    <>
      <Dialog open={openPeriodFormDialog} fullWidth scroll="paper">
        <DialogTitle>
          {isEditing ? "Edit" : "Add"} period{" "}
          {!!selectedSchoolLevel && (
            <span className="text-primary">
              @{selectedSchoolLevel.level.name}
            </span>
          )}
          <IconButton
            className="close-btn mr-3"
            onClick={() => closePeriodFormDialog()}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Period No"
                type="text"
                disabled
                variant="outlined"
                className="mt-3 text-dark"
                name="periodNo"
                style={{ backgroundColor: "#eee" }}
                value={formData.periodNo}
                onChange={(e) => {
                  return;
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Minutes"
                autoFocus
                type="number"
                variant="outlined"
                className="mt-3 "
                name="durationMin"
                value={formData.durationMin}
                onChange={(e) => {
                  const durationMin = e.target.value;
                  setFormData({ ...formData, durationMin });
                }}
              />
            </div>
            <div className="col-12 col-md-6 ">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <TimePicker
                  ampm={false}
                  closeOnSelect
                  label="Start At"
                  value={formData.startAt}
                  onChange={(newValue) => {
                    setFormData({ ...formData, startAt: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      className="mt-3 "
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <TimePicker
                  ampm={false}
                  closeOnSelect
                  readOnly
                  label="End At"
                  value={formData.endAt}
                  onChange={(newValue) => {
                    return;
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      className="mt-3 "
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>

            <div className="col-12 ">
              <FormControl fullWidth className="mt-3">
                <InputLabel id="periodTypeId">Period Type</InputLabel>
                <Select
                  labelId="periodTypeId"
                  name="periodTypeId"
                  value={formData.periodTypeId}
                  label="Period Type"
                  onChange={(e) => {
                    const periodTypeId = e.target.value;
                    setFormData({ ...formData, periodTypeId });
                  }}
                >
                  <MenuItem value={1}>Teaching</MenuItem>
                  <MenuItem value={2}>Break</MenuItem>
                  <MenuItem value={3}>Lunch</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            onClick={onSave}
            size="large"
            variant="contained"
            className="mr-2"
            disabled={
              !(
                formData.periodNo &&
                formData.durationMin &&
                formData.startAt &&
                formData.endAt &&
                formData.periodTypeId
              ) || store.isLoading
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          <Button
            size="large"
            variant="outlined"
            onClick={() => closePeriodFormDialog()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  // getSchools,
  addNewSchoolPeriod,
})(PeriodFormDialog);
