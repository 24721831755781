import { Avatar, Chip } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

import AttendanceCard from "../components/AttendanceCard";
const Attendances = (props) => {
  const { store } = props;
  return (
    <div className=" mt-2">
      <div className="mt-2 row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left">
          Attendances
        </div>

        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          {store.selectedSchool.schoolLevels && (
            <Chip
              color="primary"
              variant="outlined"
              avatar={
                <Avatar alt="school">
                  <span className="material-icons">school</span>
                </Avatar>
              }
              label={store.selectedSchool.name.toUpperCase()}
              className="mr-1"
            />
          )}
        </div>
      </div>

      <AttendanceCard />
      <AttendanceCard />
      <AttendanceCard />
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps)(Attendances);
