import { Avatar, Button, Chip } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import ClassAttendances from "./ClassAttendances";
import ClassTimeTable from "./ClassTimeTables";
import MyClassesTimaTable from "./MyClassesTimaTable";
import MyDailyTimaTable from "./MyDailyTimaTable";
import MyWeeklyTimeTable from "./MyWeeklyTimeTable";
import TeacherTimeTable from "./TeacherTimeTables";

const TimeTables = (props) => {
  const location = useLocation();
  const { store } = props;
  return (
    <div className="">
      <div className="mt-2 text-center text-md-left">Timetables</div>
      <div className=" row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left">
          {!location.pathname.includes("/in/time-tables/classes") && (
            <>
              {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
                <Link to="/in/time-tables/my-day" className="mr-2 ">
                  <Button
                    size="small"
                    className={`${
                      location.pathname.includes("/in/time-tables/my-day")
                        ? "outlined active  mb-2"
                        : " mb-2"
                    } `}
                  >
                    My Day
                  </Button>
                </Link>
              )}

              {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
                <Link to="/in/time-tables/my-week" className="mr-2 ">
                  <Button
                    size="small"
                    className={`${
                      location.pathname.includes("/in/time-tables/my-week")
                        ? "outlined active  mb-2"
                        : " mb-2"
                    } `}
                  >
                    My Week
                  </Button>
                </Link>
              )}

              {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
                <Link to="/in/time-tables/class-attendances" className="mr-2 ">
                  <Button
                    size="small"
                    className={`${
                      location.pathname.includes(
                        "/in/time-tables/class-attendances"
                      )
                        ? "outlined active  mb-2"
                        : " mb-2"
                    } `}
                  >
                    Archied attendances
                  </Button>
                </Link>
              )}
            </>
          )}
        </div>

        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1 "
                >
                  {role.name}
                </span>
              ))}
            {store.selectedSchool.schoolLevels && (
              <Chip
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar alt="school">
                    <span className="material-icons">school</span>
                  </Avatar>
                }
                label={store.selectedSchool.name.toUpperCase()}
                className="mr-1"
              />
            )}
          </span>
        </div>
      </div>
      <Switch>
        <Route path="/in/time-tables/teacher" component={TeacherTimeTable} />
        <Route path="/in/time-tables/classes" component={ClassTimeTable} />
        <Route
          path="/in/time-tables/class-attendances"
          component={ClassAttendances}
        />
        <Route path="/in/time-tables/my-day" component={MyDailyTimaTable} />
        <Route path="/in/time-tables/my-week" component={MyWeeklyTimeTable} />
        <Route
          path="/in/time-tables/my-classes"
          component={MyClassesTimaTable}
        />

        {/* <Route
          path="/in/time-tables"
          component={() => <Redirect to="/in/time-tables/teacher" />}
        /> */}
      </Switch>
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps)(TimeTables);
