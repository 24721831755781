import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  getClassStudentsForAttendance,
  removeStudent,
  addStudentOnAttendance,
} from "../store/actions";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AttendanceDetailCard from "./AttendanceDetailCard";
import StudentFormDialog from "./StudentFormDialog";

const ClassStudents = (props) => {
  const {
    store,
    isDialogOpen,
    closeDialog,
    // period,
    getClassStudentsForAttendance,
    // removeStudent,
    period,
    addStudentOnAttendance,
  } = props;

  const [openStudentFormDialog, setOpenStudentFormDialog] = useState(false);

  useEffect(() => {
    if (isDialogOpen)
      getClassStudentsForAttendance(
        period.classId,
        period.periodId,
        period.dayId,
        period.subjectId
      );
  }, [isDialogOpen]);

  const maxDateOfBirth = new Date();

  maxDateOfBirth.setFullYear(maxDateOfBirth.getFullYear() - 2);

  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    genderId: "",
    dateOfBirth: maxDateOfBirth,
    guardianFirstName: "",
    guardianLastName: "",
    guardianPhone: "",
    guardianEmail: "",
    classId: period.classId,
  });

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Dialog open={isDialogOpen} fullWidth maxWidth="md" scroll="paper">
        <DialogTitle className="text-primary">
          <span>Attendance</span>{" "}
          <span className="text-dark d-none d-md-inline mr-2">
            {" "}
            @{period.levelName} {period.gradeId}
            {period.className}
          </span>
          <span className="text-dark d-inline d-md-none mr-2 ">
            {" "}
            @{period.acronym}
            {period.gradeId}
            {period.className}
          </span>
          {/* <Button
            variant="contained"
            className="d-inline-flex "
            color="info"
            size="small"
            onClick={() => setOpenStudentFormDialog(true)}
          >
            <span className="material-icons ">person_add_alt_1</span>
            <span className="d-none d-md-inline ml-1">Add New</span>
          </Button> */}
          <IconButton className="close-btn mr-3" onClick={closeDialog}>
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "70vh" }}>
          <List>
            <Divider />

            {!!store.appData.classStudents &&
              store.appData.classStudents.map((student) => (
                <ClassStudentListItem
                  store={store}
                  key={student.id}
                  student={student}
                  period={period}
                  removeStudent={removeStudent}
                  setFormData={setFormData}
                  setIsEditing={setIsEditing}
                  setOpenStudentFormDialog={setOpenStudentFormDialog}
                  addStudentOnAttendance={addStudentOnAttendance}
                />
              ))}
          </List>

          {store &&
            store.appData.classStudents &&
            !store.appData.classStudents.length && (
              <div className="text-center">No student found</div>
            )}
        </DialogContent>
      </Dialog>

      <StudentFormDialog
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        period={period}
        openStudentFormDialog={openStudentFormDialog}
        setOpenStudentFormDialog={setOpenStudentFormDialog}
        maxDateOfBirth={maxDateOfBirth}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getClassStudentsForAttendance,
  removeStudent,
  addStudentOnAttendance,
})(ClassStudents);

const ClassStudentListItem = (props) => {
  const {
    store,
    student,
    period,
    removeStudent,
    setIsEditing,
    setOpenStudentFormDialog,
    addStudentOnAttendance,
  } = props;

  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showReasonDialog, setShowReasonDialog] = useState(false);
  const [formData, setFormData] = useState(null);

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar
            alt={student.firstName}
            sx={{ width: 50, height: 50 }}
            src={
              student.profilePicture
                ? `data:image/png;base64,${student.profilePicture}`
                : defaultProfile
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {student.firstName} {student.lastName}
            </span>
          }
          secondary={
            <span>
              {student.guardianPhone}
              {student.isActive && (
                <span className="badge badge-success ml-1">Active</span>
              )}
            </span>
          }
        />

        <Box className="d-none d-md-block">
          <div className="text-right">
            {" "}
            {(!student.statusId || student.statusId === 1 || edit) && (
              <button
                style={{ width: "105px" }}
                disabled={(student.statusId === 1 && !edit) || store.isLoading}
                onClick={() =>
                  addStudentOnAttendance(
                    {
                      ...period,
                      statusId: 1,
                      studentId: student.id,
                    },
                    setEdit
                  )
                }
                type="button"
                className="btn btn-success btn-sm mr-1 d-inline-flex align-items-center"
              >
                <span className="material-icons mr-1">done_all</span> Attended
              </button>
            )}
            {(!student.statusId || student.statusId === 2 || edit) && (
              <button
                style={{ width: "105px" }}
                disabled={(student.statusId === 2 && !edit) || store.isLoading}
                onClick={() => {
                  setFormData({
                    ...period,
                    statusId: 2,
                    studentId: student.id,
                  });
                  setShowReasonDialog(true);
                }}
                type="button"
                className="btn btn-info btn-sm mr-1  d-inline-flex align-items-center"
              >
                <span className="material-icons mr-1">rule</span> Lated
              </button>
            )}
            {(!student.statusId || student.statusId === 3 || edit) && (
              <button
                style={{ width: "105px" }}
                disabled={(student.statusId === 3 && !edit) || store.isLoading}
                onClick={() => {
                  setFormData({
                    ...period,
                    statusId: 3,
                    studentId: student.id,
                  });
                  setShowReasonDialog(true);
                }}
                type="button"
                className="btn btn-danger btn-sm mr-1  d-inline-flex align-items-center"
              >
                <span className="material-icons mr-1">close</span> Missed
              </button>
            )}
            {!!student.statusId && (
              <button
                type="button"
                className="btn btn-secondary btn-sm mr-1  d-inline-flex align-items-center"
                onClick={() => setEdit(!edit)}
              >
                {edit && <span className="material-icons">close</span>}
                {!edit && <span className="material-icons">edit</span>}
              </button>
            )}
          </div>
          {!!student.reason && (
            <div className="text-right">
              <small>{student.reason}</small>{" "}
            </div>
          )}
        </Box>
      </ListItem>
      <Divider className="my-1" />

      <Dialog open={showReasonDialog} fullWidth maxWidth="xs">
        <DialogTitle style={{ backgroundColor: "#0f7197", color: "white" }}>
          <span>{`Reason for ${
            formData?.statusId === 2 ? "late" : "missing"
          }`}</span>

          <IconButton
            className="close-btn mr-3"
            onClick={() => {
              setShowReasonDialog(false);
              setFormData(null);
            }}
          >
            <span className="material-icons text-white">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-4">
          <div className="mt-3">
            <TextField
              fullWidth
              multiline
              size="small"
              rows={4}
              autoFocus
              name="reason"
              type="text"
              label={`Reason for ${
                formData?.statusId === 2 ? "late" : "missing"
              }`}
              variant="outlined"
              placeholder={`Reason for ${
                formData?.statusId === 2 ? "late" : "missing"
              }`}
              value={formData?.reason || ""}
              onChange={(e) => {
                if (!!formData)
                  if (!!formData)
                    setFormData({
                      ...formData,
                      reason: e.target.value || "",
                    });
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3">
          <Button
            disabled={store.isLoading || !formData?.reason}
            variant="contained"
            color="primary"
            onClick={() => {
              addStudentOnAttendance(
                {
                  ...formData,
                },
                setEdit,
                setShowReasonDialog
              );
            }}
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
