import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import defaultProfile from "../assets/default-profile.jpg";
import { connect } from "react-redux";
import AvatarEditor from "react-avatar-editor";

import { logout, updateUserProfilePicture } from "../store/actions";

const Drawer = (props) => {
  const {
    drawerState,
    setDrawerState,
    store,
    logout,
    updateUserProfilePicture,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const [avatar, setAvatar] = useState({
    selectedImage: "",
    scaleValue: 1,
  });

  const [editor, setEditor] = useState(null);

  const onFileChange = (e) => {
    const selectedImage = e.target.files[0];
    if (
      selectedImage.type.endsWith("png") ||
      selectedImage.type.endsWith("jpg") ||
      selectedImage.type.endsWith("jpeg")
    ) {
      setAvatar({ ...avatar, selectedImage });
    }
  };

  const onScaleChane = (e) => {
    const scaleValue = +e.target.value;
    setAvatar({ ...avatar, scaleValue });
  };

  const onCrop = () => {
    if (!!avatar.selectedImage) {
      const formData = new FormData();

      formData.append(
        "profilePicture",
        editor.getImageScaledToCanvas().toDataURL().split(",")[1]
      );
      formData.append("id", 1);

      updateUserProfilePicture(formData);
    }
  };

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const isAdmin = (roles) => {
    return roles.find(({ id }) => id === 1);
  };

  const isHeadTeacherOrAdmin = (roles) => {
    return roles.find(({ id }) => id === 1 || id === 2 || id === 4);
  };

  const getTimetableLinkBasedOnUserRole = () => {};

  return (
    <React.Fragment>
      <div
        id="side-drawer"
        className={`position-fixed  ${drawerState ? "active" : ""}`}
      >
        <div>
          <div
            className="sidebar-user mb-2"
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
            {store.auth.user && store.auth.user.profilePicture && (
              <img
                src={`data:image/png;base64,${store.auth.user.profilePicture}`}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            {store.auth.user && !store.auth.user.profilePicture && (
              <img
                src={defaultProfile}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            <div
              style={{
                background: "#fff",
                position: "absolute",
                overflow: "visible",
                top: "0",
                marginTop: "25%",
                marginLeft: "45%",
                height: "32px",
                borderRadius: "50%",
                width: "32px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                }}
                className="photo-icon material-icons mt-1"
                data-toggle="modal"
                data-target="#profilePictureModel"
              >
                add_a_photo
              </span>
            </div>
            <div className="fw-bold">
              {store.auth.user.lastName} {store.auth.user.firstName}
            </div>
            {store.auth.user.roles && store.auth.user.roles.lenght && (
              <small style={{ color: "#07ff07" }}>
                {store.auth.user.roles[0].name}
              </small>
            )}
          </div>
          <ul className="sidebar-nav">
            {/* <li
              className={`sidebar-item  ${
                location.pathname.includes("/in/dashboard") ? "active" : ""
              }`}
            >
              <Link
                className="sidebar-link"
                to="/in/dashboard"
                onClick={() => {
                  setDrawerState(false);
                }}
              >
                <i className="fas fa-chart-line"></i>{" "}
                <span className="align-middle">Dashboard</span>
              </Link>
            </li> */}
            {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
              <li
                className={`sidebar-item  ${
                  location.pathname.includes("/in/time-tables/my-day") ||
                  location.pathname.includes("/in/time-tables/my-week")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/time-tables/my-day"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">perm_contact_calendar</span>{" "}
                  <span className="align-middle">Timetable/Attendances</span>
                </Link>
              </li>
            )}
            {/* {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
              <li
                className={`sidebar-item  ${
                  location.pathname.includes("/in/class-attendances")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/class-attendances"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">event_available</span>{" "}
                  <span className="align-middle">My Class Attendances</span>
                </Link>
              </li>
            )} */}
            {/* {store.auth.user.roles.findIndex(({ id }) => id !== 3) >= 0 && (
              <li
                className={`sidebar-item  ${
                  location.pathname.includes("/in/time-tables/classes")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/time-tables/classes"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">calendar_month</span>{" "}
                  <span className="align-middle">School Timetable</span>
                </Link>
              </li>
            )} */}

            {store.auth.user.roles.findIndex(({ id }) => id === 3) >= 0 && (
              <li
                className={`sidebar-item ${
                  location.pathname.includes("/in/class-settings")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/class-settings"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">settings_applications</span>{" "}
                  <span className="align-middle">My Class Settings</span>
                </Link>
              </li>
            )}

            {store.auth.user.roles.findIndex(
              ({ id }) => id === 2 || id === 4
            ) >= 0 && (
              <li
                className={`sidebar-item  ${
                  location.pathname.includes("/in/time-tables/classes")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/time-tables/classes"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">calendar_month</span>{" "}
                  <span className="align-middle">School Timetable</span>
                </Link>
              </li>
            )}

            {store.auth.user.roles.findIndex(
              ({ id }) => id === 2 || id === 1 || id === 4
            ) >= 0 && (
              <li
                className={`sidebar-item  ${
                  location.pathname.includes("/in/school-attendances")
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className="sidebar-link d-flex"
                  to="/in/school-attendances"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <span className="material-icons">fact_check</span>{" "}
                  <span className="align-middle">School Attendances</span>
                </Link>
              </li>
            )}

            {!!store &&
              !!store.auth &&
              !!store.auth.user &&
              !!isHeadTeacherOrAdmin(store.auth.user.roles) && (
                <li
                  className={`sidebar-item  ${
                    location.pathname.includes("/in/settings") ? "active" : ""
                  }`}
                >
                  <Link
                    className="sidebar-link d-flex"
                    to="/in/settings"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <span className="material-icons">settings</span>{" "}
                    <span className="align-middle">School Settings</span>
                  </Link>
                </li>
              )}
            {!!store &&
              !!store.auth &&
              !!store.auth.user &&
              !!isAdmin(store.auth.user.roles) && (
                <li
                  className={`sidebar-item  ${
                    location.pathname.includes("/in/security") ? "active" : ""
                  }`}
                >
                  <Link
                    className="sidebar-link"
                    to="/in/security"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <i className="fas fa-school"></i>{" "}
                    <span className="align-middle">School Registration</span>
                  </Link>
                </li>
              )}
          </ul>
        </div>
        <button
          onClick={() => {
            logout(history);
          }}
          className="btn btn-block logout"
          style={{ position: "absolute", bottom: "0" }}
        >
          <i
            className="fas fa-sign-out-alt mr-2 mb-2"
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </button>
      </div>

      <div
        className="modal fade "
        id="profilePictureModel"
        role="dialog"
        aria-labelledby="profilePictureModelTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profilePictureModelTitle">
                Change profile picture
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center pb-0">
              <div className="d-flex  justify-content-center">
                <div className="form-group" style={{ width: "240px" }}>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={onFileChange}
                    className="form-control-file"
                  />
                </div>
              </div>

              <AvatarEditor
                image={avatar.selectedImage}
                width={192}
                height={192}
                border={25}
                scale={avatar.scaleValue}
                ref={setEditorRef}
                rotate={0}
                borderRadius={100}
              />
              <br />

              <input
                type="range"
                style={{ width: "100%" }}
                min="1"
                max="2"
                step="0.01"
                name="scaleValue"
                value={avatar.scaleValue}
                onChange={onScaleChane}
              />
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={onCrop}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="side-drawer-void"
        className={`position-fixed ${drawerState ? "d-block" : "d-none"} `}
        onClick={() => {
          setDrawerState(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { logout, updateUserProfilePicture })(
  Drawer
);
