import React, { useState } from "react";
import { passwordReset } from "../store/actions";
import { connect } from "react-redux";

const ResetPassword = (props) => {
  const { setShowResetPassword, passwordReset, store } = props;
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.email) return;

    passwordReset(formData.email, setShowResetPassword);
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <form className="text-center" onSubmit={onSubmit}>
          <p className="text-info mt-3">Reset password</p>
          <div className="input-group my-3">
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fas fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) => {
                const email = e.target.value;
                setFormData({ ...formData, email });
              }}
            />
          </div>

          <div className="d-flex justify-content-center mt-3 ">
            <button type="submit" name="submit" className="btn btn-info">
              Reset
            </button>
            <button
              onClick={() => {
                setShowResetPassword(false);
              }}
              type="button"
              className="btn ml-3"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  passwordReset,
})(ResetPassword);
