import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { saveSchoolHeadTeacher } from "../store/actions";
import { connect } from "react-redux";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const HeadTeacherFormDialog = (props) => {
  const {
    store,
    levelFormDialogOpen,
    handleLevelFormDialogClose,
    // getSchools,
    saveSchoolHeadTeacher,
    formData,
    setFormData,
    isEditing,
    setIsEditing,
  } = props;
  const [selectedLevel, setSelectedLevel] = useState("");

  // useEffect(() => {
  //   getSchools();
  // }, []);

  const onSave = () => {
    saveSchoolHeadTeacher(
      {
        id: formData.id,
        sdmsStaffCode: formData.sdmsStaffCode,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        idNumber: formData.idNumber,
        genderId: formData.genderId,
      },
      handleLevelFormDialogClose,
      setFormData,
      setIsEditing
    );
  };

  return (
    <>
      <Dialog open={levelFormDialogOpen} fullWidth scroll="paper">
        <DialogTitle className="text-primary">
          Add Head Teacher
          <IconButton
            className="close-btn mr-3"
            onClick={handleLevelFormDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <TextField
                size="small"
                fullWidth
                autoFocus
                label="SDMS Staff Code"
                variant="outlined"
                className="mt-3 "
                name="sdmsStaffCode"
                value={formData.sdmsStaffCode}
                onChange={(e) => {
                  const sdmsStaffCode = e.target.value;
                  setFormData({ ...formData, sdmsStaffCode });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                className="mt-3 "
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  const firstName = e.target.value;
                  setFormData({ ...formData, firstName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                className="mt-3 "
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  const lastName = e.target.value;
                  setFormData({ ...formData, lastName });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                disabled={isEditing}
                fullWidth
                label="Email Address"
                variant="outlined"
                className="mt-3 "
                name="email"
                value={formData.email}
                onChange={(e) => {
                  const email = e.target.value;
                  setFormData({ ...formData, email });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                className="mt-3 "
                name="phone"
                value={formData.phone}
                onChange={(e) => {
                  const phone = e.target.value;
                  setFormData({ ...formData, phone });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                fullWidth
                label="ID Number"
                variant="outlined"
                className="mt-3 "
                name="idNumber"
                value={formData.idNumber}
                onChange={(e) => {
                  const idNumber = e.target.value;
                  setFormData({ ...formData, idNumber });
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mt-3">
                <InputLabel>Gender</InputLabel>
                <Select
                  labelId="Gender"
                  name="genderId"
                  value={formData.genderId}
                  label="Gender"
                  onChange={(e) => {
                    const genderId = e.target.value;
                    setFormData({ ...formData, genderId });
                  }}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {isEditing && !!formData.schools && (
            <Card variant="outlined" className="mt-2 bg-light">
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h5" component="span">
                    Schools:
                  </Typography>
                  {formData.schools.map((school, index) => (
                    <Chip
                      key={index}
                      label={school.name}
                      variant="outlined"
                      color="primary"
                    />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            onClick={onSave}
            size="large"
            variant="contained"
            className="mr-2"
            disabled={
              !(
                formData.sdmsStaffCode &&
                formData.firstName &&
                formData.lastName &&
                formData.email &&
                formData.phone &&
                formData.idNumber &&
                formData.genderId
              ) || store.isLoading
            }
          >
            {store.isLoading ? "Wait..." : "Save"}
          </Button>
          {/* <Button
            size="large"
            variant="outlined"
            onClick={handleLevelFormDialogClose}
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  // getSchools,
  saveSchoolHeadTeacher,
})(HeadTeacherFormDialog);
