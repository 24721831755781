import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getClassStudentsForAttendance } from "../store/actions";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AttendanceDetailCard from "./AttendanceDetailCard";

const AttendanceDetailDialog = (props) => {
  const {
    store,
    attendanceDetailDialogOpen,
    handleAttendanceDetailDialogClose,
    getClassStudentsForAttendance,
    attendance,
  } = props;

  useEffect(() => {
    if (attendanceDetailDialogOpen)
      getClassStudentsForAttendance(
        attendance.classId,
        attendance.periodId,
        attendance.dayId,
        attendance.subjectId
      );
  }, [attendanceDetailDialogOpen]);

  return (
    <>
      <Dialog open={attendanceDetailDialogOpen} fullWidth scroll="paper">
        <DialogTitle className="text-primary">
          Attendance Details
          <IconButton
            className="close-btn mr-3"
            onClick={handleAttendanceDetailDialogClose}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!!store.appData.classStudents &&
            store.appData.classStudents.map((student) => (
              <AttendanceDetailCard
                key={student.id}
                student={student}
                attendance={attendance}
              />
            ))}
        </DialogContent>
        {/* <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            size="large"
            variant="outlined"
            onClick={handleAttendanceDetailDialogClose}
          >
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { getClassStudentsForAttendance })(
  AttendanceDetailDialog
);
