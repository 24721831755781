import _ from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SchoolLevel from "../components/SchoolLevelCard";
import LevelFormDialog from "../components/LevelFormDialog";
import {
  getSchoolTeachers,
  uploadSchoolTeachers,
  getSelectedSchool,
  removeSchoolTeacher,
  setAsDOS,
} from "../store/actions";
import { Link } from "react-router-dom";
import TeacherFormDialog from "../components/TeacherFormDialog";
import defaultProfile from "../assets/default-profile.jpg";
import UploadExcelFile from "../components/UploadExcelFile";
import { onError } from "../store/utils";
import readXlsxFile from "read-excel-file";
import TEACHERS_TEMPLATE from "../assets/Teachers_Template.xlsx";

const Teachers = (props) => {
  const {
    store,
    getSchoolTeachers,
    uploadSchoolTeachers,
    getSelectedSchool,
    removeSchoolTeacher,
    setAsDOS,
  } = props;

  const [levelFormDialogOpen, setLevelFormDialogOpen] = useState(false);
  const handleLevelFormDialogOpen = () => {
    setLevelFormDialogOpen(true);
  };
  const handleLevelFormDialogClose = () => {
    setLevelFormDialogOpen(false);
    setFormData({
      id: null,
      sdmsStaffCode: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      idNumber: "",
      genderId: "",
    });
    setIsEditing(false);
  };

  useEffect(() => {
    if (!!store.selectedSchool.id) getSchoolTeachers(store.selectedSchool.id);
  }, [store.selectedSchool]);

  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    sdmsStaffCode: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    idNumber: "",
    genderId: "",
  });

  const [file, setFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const doUploadFile = async () => {
    const dataToUpload = await readXlsxFile(file).then((rows) => {
      let tempData = [];

      if (!!rows.length) {
        const header = rows[0];

        if (
          header &&
          header[0] &&
          header[0].toLowerCase().includes("sdmsstaffcode") &&
          header[1] &&
          header[1].toLowerCase().includes("lastname") &&
          header[2] &&
          header[2].toLowerCase().includes("firstname") &&
          header[3] &&
          header[3].toLowerCase().includes("nidnumber") &&
          header[4] &&
          header[4].toLowerCase().includes("gender") &&
          header[5] &&
          header[5].toLowerCase().includes("email") &&
          header[6] &&
          header[6].toLowerCase().includes("phone")
        ) {
          rows.shift();
          rows.forEach((cols) => {
            const item = {
              schoolId: store.selectedSchool.id,
              sdmsStaffCode: cols[0] && cols[0] !== "" ? parseInt(cols[0]) : "",
              lastName: cols[1] && cols[1] !== "" ? cols[1].toString() : "",
              firstName: cols[2] && cols[2] !== "" ? cols[2].toString() : "",
              idNumber: cols[3] && cols[3] !== "" ? cols[3].toString() : "",
              genderId: cols[4] && cols[4] !== "" ? cols[4].toString() : "",
              email: cols[5] && cols[5] !== "" ? cols[5].toString() : "",
              phone: cols[6] && cols[6] !== "" ? cols[6].toString() : "",
            };
            tempData.push(item);
          });
        } else {
          onError({ message: "Invalid excel file format" });

          return false;
        }
      } else {
        onError({ message: "Invalid excel file format" });
        return false;
      }

      return tempData;
    });

    if (!!dataToUpload && !!dataToUpload.length) {
      uploadSchoolTeachers({ data: dataToUpload }, setShowDialog);
    }
  };

  return (
    <>
      <Card variant="outlined" className="level-card mt-2 mb-3">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            <strong>
              {" "}
              {!!store.appData.teachers ? +store.appData.teachers.length : "0"}
            </strong>{" "}
            Teachers
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <a className="text-light d-flex mr-2" href={TEACHERS_TEMPLATE}>
            <span className="material-icons">file_download</span>
            <span className="d-none d-md-inline">Download template</span>
          </a>
          <Button
            variant="contained"
            size="small"
            component="label"
            color="secondary"
            onClick={() => setShowDialog(true)}
          >
            <span className="material-icons">file_upload</span>
            <span className="d-none d-md-inline"> Upload Teachers</span>
          </Button>
        </div>
        <Divider />
        <CardContent>
          <List>
            <Divider />
            {!!store.appData.teachers &&
              store.appData.teachers.map((teacher, index) => (
                <TeacherListItem
                  teacher={teacher}
                  index={index}
                  setSelectedTeacher={setSelectedTeacher}
                  removeSchoolTeacher={removeSchoolTeacher}
                  setAsDOS={setAsDOS}
                  selectedSchool={store.selectedSchool}
                  key={teacher.id}
                  handleLevelFormDialogOpen={handleLevelFormDialogOpen}
                  setIsEditing={setIsEditing}
                  setFormData={setFormData}
                  store={store}
                />
              ))}
          </List>
          {store &&
            store.appData.teachers &&
            !store.appData.teachers.length && (
              <div className="text-center">Not found</div>
            )}
        </CardContent>
      </Card>

      <TeacherFormDialog
        handleFormDialogClose={handleLevelFormDialogClose}
        formDialogOpen={levelFormDialogOpen}
        formData={formData}
        setFormData={setFormData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

      {showDialog && (
        <UploadExcelFile
          file={file}
          setFile={setFile}
          setShowDialog={setShowDialog}
          showDialog={showDialog}
          onSave={() => doUploadFile()}
        />
      )}

      <div className="fab-container pr-3 pb-3">
        <Tooltip title="Add New Teacher">
          <span className="fab-btn">
            <Fab color="primary" onClick={handleLevelFormDialogOpen}>
              <span className="material-icons">add</span>
            </Fab>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getSchoolTeachers,
  uploadSchoolTeachers,
  getSelectedSchool,
  removeSchoolTeacher,
  setAsDOS,
})(Teachers);

const TeacherListItem = (props) => {
  const {
    setSelectedTeacher,
    teacher,
    index,
    removeSchoolTeacher,
    setAsDOS,
    selectedSchool,
    handleLevelFormDialogOpen,
    setIsEditing,
    setFormData,
    store,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isDOS = (roles) => {
    return roles.find(({ id }) => id === 4);
  };

  return (
    <React.Fragment>
      <ListItem
        className="mb-1 mt-1"
        style={{
          backgroundColor: "#e4eef5",
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt="User profile picture"
            src={
              teacher.profilePicture
                ? `data:image/png;base64,${teacher.profilePicture}`
                : defaultProfile
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {index + 1}. {teacher.firstName} {teacher.lastName}
            </span>
          }
          secondary={teacher.email}
        />

        <span>
          <Box>
            {!!teacher.roles?.find(({ id }) => id === 2) && (
              <span className="badge badge-success">HeadTeacher</span>
            )}
            {isDOS(teacher.roles) && (
              <span className="badge badge-primary">DOS</span>
            )}
            {!!teacher.sdmsStaffCode && (
              <Tooltip title="SDMS Staff Code">
                <Chip
                  style={{ borderRadius: "0px" }}
                  variant="outlined"
                  className="small-chip ml-2 py-0"
                  size="small"
                  label={teacher.sdmsStaffCode}
                />
              </Tooltip>
            )}
            <IconButton
              className="ml-2"
              onClick={handleOpenMenu}
              aria-label="settings"
              color="info"
            >
              <span className="material-icons">more_vert</span>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <MenuItem disabled>MENUS</MenuItem>
              <Divider className="my-1" />

              <MenuItem
                disabled={store.isLoading}
                onClick={() => {
                  setFormData(teacher);
                  setIsEditing(true);
                  handleCloseMenu();
                  handleLevelFormDialogOpen();
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-2">visibility</span>
                Details/Update
              </MenuItem>
              <Divider className="my-1" />

              {!!store?.auth?.user?.roles?.find(
                ({ id }) => id === 2 || id === 1
              ) && (
                <span>
                  <MenuItem
                    disabled={
                      store.isLoading ||
                      !!teacher.roles?.find(({ id }) => id === 2)
                    }
                    onClick={() => {
                      setAsDOS(
                        {
                          id: teacher.id,
                          isDOS: !isDOS(teacher.roles),
                          schoolId: selectedSchool.id,
                        },
                        handleCloseMenu
                      );
                    }}
                    className={`text-${
                      !isDOS(teacher.roles) ? "primary" : "danger"
                    } font-weight-light`}
                  >
                    {!isDOS(teacher.roles) && (
                      <span className="material-icons mr-2">person_4</span>
                    )}
                    {isDOS(teacher.roles) && (
                      <span className="material-icons mr-2">person_off</span>
                    )}
                    Set as {isDOS(teacher.roles) && <>Not</>} DOS
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )}

              <MenuItem
                disabled={store.isLoading}
                onClick={() => {
                  handleCloseMenu();
                  removeSchoolTeacher({
                    id: teacher.id,
                    schoolId: selectedSchool.id,
                  });
                }}
                className="text-danger font-weight-light"
              >
                <span className="material-icons mr-2">delete</span> Delete
              </MenuItem>
            </Menu>
          </Box>
        </span>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
