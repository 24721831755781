import { TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";
import { changePassword } from "../store/actions";

const Profile = (props) => {
  const { store, changePassword } = props;

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    email: "",
  });

  const onSubmit = () => {
    changePassword({ ...formData, email: store.auth.user.email });
  };

  useEffect(() => {
    setFormData({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      email: "",
    });
  }, [showPasswordForm]);

  return (
    <div className="text-center mt-2 ">
      <span className="text-uppercase text-primary font-weight-bold ">
        My Profile - ID:{store.auth.user.id}
      </span>

      <div className=" mb-2 mt-2 text-center">
        {store.auth.user && store.auth.user.profilePicture && (
          <img
            style={{ height: "64px", width: "64px" }}
            src={`data:image/png;base64,${store.auth.user.profilePicture}`}
            className="img-fluid rounded-circle mb-2"
            alt="Linda Miller"
          />
        )}
        {store.auth.user && !store.auth.user.profilePicture && (
          <img
            style={{ height: "64px", width: "64px" }}
            src={defaultProfile}
            className="img-fluid rounded-circle mb-2"
            alt="Linda Miller"
          />
        )}

        <div style={{ marginLeft: "3%", marginTop: "-2%" }}>
          <div
            style={{
              // position: "absolute",
              overflow: "visible",
              // top: "0",
              backgroundColor: "#fff",
              height: "32px",
              width: "32px",
              borderRadius: "50%",
            }}
            className="text-center d-inline-block"
          >
            <span
              style={{
                cursor: "pointer",
              }}
              className="photo-icon material-icons mt-1"
              data-toggle="modal"
              data-target="#profilePictureModel"
            >
              add_a_photo
            </span>
          </div>
        </div>

        {/* <div className="fw-bold">
          {store.auth.user.lastName} {store.auth.user.firstName}
        </div> */}
        {store.auth.user.roles && store.auth.user.roles.lenght && (
          <small style={{ color: "#07ff07" }}>
            {store.auth.user.roles[0].name}
          </small>
        )}
      </div>
      {/* <div className="row mt-2  mb-2">
        <div className="col-6 text-right">UserId:</div>
        <div className="col-6 text-left">{store.auth.user.id}</div>
      </div> */}
      <div className="row  mb-2">
        <div className="col-6 text-right">Names:</div>
        <div className="col-6 text-left">
          {store.auth.user.firstName} {store.auth.user.lastName}
        </div>
      </div>
      <div className="row  mb-2">
        <div className="col-6 text-right">Email:</div>
        <div className="col-6 text-truncate text-left">
          {store.auth.user.email}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-6 text-right">Phone Number:</div>
        <div className="col-6 text-left">{store.auth.user.phone}</div>
      </div>
      <div className="row  mb-2">
        <div className="col-6 text-right">NID Number:</div>
        <div className="col-6 text-left">{store.auth.user.idNumber}</div>
      </div>
      <div className="row  mb-2">
        <div className="col-6 text-right">Gender:</div>
        <div className="col-6 text-left">{store.auth.user.gender.name}</div>
      </div>
      {!!store.auth.user.schools && (
        <div className="row  mb-2">
          <div className="col-6 text-right">schools:</div>
          <div className="col-6 text-left">
            {store.auth.user.schools.map((school, index) => (
              <small className="badge badge-dark mr-1" key={index}>
                {school.name}
              </small>
            ))}
          </div>
        </div>
      )}
      {!!store.auth.user.roles && (
        <div className="row  mb-2">
          <div className="col-6 text-right">Roles:</div>
          <div className="col-6 text-left">
            {store.auth.user.roles.map((role, index) => (
              <small className="badge badge-info mr-1" key={index}>
                {role.name}
              </small>
            ))}
          </div>
        </div>
      )}

      {!showPasswordForm && (
        <div className="row pb-3">
          <div className="col-12 text-center ">
            <button
              onClick={() => setShowPasswordForm(true)}
              type="button"
              className="btn btn-link mr-2"
            >
              CHANGE PASSWORD
            </button>
          </div>
        </div>
      )}

      {showPasswordForm && (
        <div
          className="mb-2  py-2 px-2 "
          style={{ backgroundColor: "#f9f9f9" }}
        >
          <span className="text-uppercase text-primary font-weight-bold ">
            Change Password
          </span>
          <span
            style={{
              overflow: "visible",
              backgroundColor: "#eee",
              height: "32px",
              width: "32px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="float-right d-flex justify-content-center align-items-center"
            onClick={() => setShowPasswordForm(false)}
          >
            X
          </span>
          <div className="row  mb-2 mt-3">
            <div className="col-6 text-right d-none d-md-block">
              Old Password:
            </div>
            <div className="col-12 col-md-6 text-left">
              <TextField
                fullWidth
                size="small"
                type="password"
                value={formData.oldPassword}
                label="Old Password"
                variant="outlined"
                onChange={(e) => {
                  const oldPassword = e.target.value;
                  setFormData({ ...formData, oldPassword });
                }}
              />
            </div>
          </div>
          <div className="row  mb-2">
            <div className="col-6 text-right d-none d-md-block">
              New Password:
            </div>
            <div className="col-12 col-md-6 text-left">
              <TextField
                fullWidth
                size="small"
                type="password"
                label="New Password"
                value={formData.newPassword}
                variant="outlined"
                onChange={(e) => {
                  const newPassword = e.target.value;
                  setFormData({ ...formData, newPassword });
                }}
              />
            </div>
          </div>
          <div className="row  mb-3">
            <div className="col-6 text-right d-none d-md-block">
              Confirm New Password:
            </div>
            <div className="col-12 col-md-6 text-left">
              <TextField
                fullWidth
                size="small"
                type="password"
                value={formData.confirmNewPassword}
                label="Confirm New Password"
                variant="outlined"
                onChange={(e) => {
                  const confirmNewPassword = e.target.value;
                  setFormData({ ...formData, confirmNewPassword });
                }}
              />
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-12 text-center ">
              <button
                disabled={store.isLoading}
                onClick={onSubmit}
                type="button"
                className="btn btn-primary mr-2"
              >
                {store.isLoading ? "Please, Wait..." : "CHANGE PASSWORD"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { changePassword })(Profile);
