import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  getSchoolTeachers,
  getClassRoomTeachers,
  saveClassRoomTeacher,
  removeClassRoomTeacher,
  addTeacherSubject,
  removeTeacherSubject,
} from "../store/actions";

import types from "../store/types";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import defaultProfile from "../assets/default-profile.jpg";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";

const ClassTeachersDialog = (props) => {
  const {
    store,
    schoolLevel,
    schoolLevelGrade,
    classRoom,
    isTeachersDialogOpen,
    setIsTeachersDialogOpen,
    getSchoolTeachers,
    getClassRoomTeachers,
    saveClassRoomTeacher,
    removeClassRoomTeacher,
    addTeacherSubject,
    removeTeacherSubject,
  } = props;

  useEffect(() => {
    if (!!isTeachersDialogOpen) {
      if (!store.appData.teachers || !store.appData.teachers.length)
        getSchoolTeachers(store.selectedSchool.id);

      getClassRoomTeachers(classRoom.id);
    }
  }, [isTeachersDialogOpen]);

  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedTeacher2, setSelectedTeacher2] = useState(null);
  const [openAddTeacherSubjectForm, setOpenAddTeacherSubjectForm] =
    useState(false);

  const filterTeachers = () => {
    if (!!store.appData.teachers && !!store.appData.classRoomTeachers)
      return store.appData.teachers.filter(
        (teacher) =>
          store.appData.classRoomTeachers.findIndex(
            (classRoomTeacher) => classRoomTeacher.id === teacher.id
          ) < 0
      );
    else return [];
  };

  const dispatch = useDispatch();

  return (
    <Dialog open={isTeachersDialogOpen} fullWidth maxWidth="md">
      <DialogTitle style={{ backgroundColor: "#0f7197", color: "white" }}>
        <span>
          {schoolLevel.level.name}
          {schoolLevelGrade.grade.name}
          {classRoom.name} Teachers
        </span>

        <IconButton
          className="close-btn mr-3"
          onClick={() => {
            setIsTeachersDialogOpen(false);
            dispatch({
              type: types.SET_CLASS_ROOM_TEACHERS,
              data: [],
            });
          }}
        >
          <span className="material-icons text-white">close</span>
        </IconButton>
      </DialogTitle>
      <div className="px-4 mt-3">
        <Autocomplete
          disablePortal
          size="small"
          defaultValue={null}
          value={selectedTeacher}
          options={filterTeachers()}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          onChange={(event, newValue) => {
            setSelectedTeacher(newValue || null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select teacher" />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Avatar
                style={{ border: "1px solid #d1cbcb" }}
                alt={option.firstName}
                className="mr-2"
                src={
                  option.profileImage
                    ? `data:image/jpeg;base64,${option.profileImage}`
                    : defaultProfile
                }
              />
              {option.firstName + " " + option.lastName}
            </Box>
          )}
        />
        <button
          style={{ borderRadius: "25px" }}
          onClick={() => {
            saveClassRoomTeacher(
              {
                classId: classRoom.id,
                userId: selectedTeacher.id,
              },
              setSelectedTeacher
            );
          }}
          type="button"
          disabled={isEmpty(selectedTeacher) || store.isLoading}
          className="btn btn-primary btn-block text-uppercase  mt-1"
        >
          {store.isLoading ? "Wait..." : "Save"}
        </button>
      </div>

      <DialogContent>
        <div style={{ minHeight: "49vh" }}>
          <List>
            <Divider />
            {!!store.appData.classRoomTeachers &&
              store.appData.classRoomTeachers.map((teacher, index) => (
                <TeacherListItem
                  teacher={teacher}
                  setSelectedTeacher={setSelectedTeacher2}
                  setOpenAddTeacherSubjectForm={setOpenAddTeacherSubjectForm}
                  removeClassRoomTeacher={removeClassRoomTeacher}
                  selectedTeacher={store.selectedTeacher}
                  classRoom={classRoom}
                  key={teacher.id}
                  index={index}
                  removeTeacherSubject={removeTeacherSubject}
                />
              ))}
          </List>
          {store &&
            (!store.appData.classRoomTeachers ||
              (!!store.appData.classRoomTeachers &&
                !store.appData.classRoomTeachers.length)) && (
              <div className="text-center">Not found</div>
            )}
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-3"></DialogActions>

      <AddTeacherSubjectForm
        selectedTeacher={selectedTeacher2}
        openAddTeacherSubjectForm={openAddTeacherSubjectForm}
        setOpenAddTeacherSubjectForm={setOpenAddTeacherSubjectForm}
        gradeSubjects={store.appData.gradeSubjects}
        addTeacherSubject={addTeacherSubject}
        classRoomTeachers={store.appData.classRoomTeachers}
        isLoading={store.isLoading}
      />
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getSchoolTeachers,
  getClassRoomTeachers,
  saveClassRoomTeacher,
  removeClassRoomTeacher,
  addTeacherSubject,
  removeTeacherSubject,
})(ClassTeachersDialog);

const TeacherListItem = (props) => {
  const {
    setSelectedTeacher,
    teacher,
    removeClassRoomTeacher,
    classRoom,
    index,
    setOpenAddTeacherSubjectForm,
    removeTeacherSubject,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem
        className=" mb-1 mt-1 pr-0 p-md-3"
        style={{ backgroundColor: "#e4eef5" }}
      >
        <ListItemAvatar>
          <Avatar
            alt="User profile picture"
            src={
              teacher.profilePicture
                ? `data:image/png;base64,${teacher.profilePicture}`
                : defaultProfile
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span className="font-weight-bold ">
              {index + 1}. {teacher.firstName} {teacher.lastName}
            </span>
          }
          secondary={
            <>
              {!!teacher.userClasses &&
                teacher.userClasses[0].subjects.map((subject, index) => (
                  <Chip
                    className="mr-1"
                    key={subject.id}
                    component="span"
                    size="small"
                    variant="outlined"
                    style={{ maxWidth: "100%" }}
                    label={
                      <span
                        style={{ maxWidth: "100%" }}
                        className="mr-1  d-inline-block text-truncate"
                      >
                        {index + 1}: {subject.name}
                      </span>
                    }
                    onDelete={() =>
                      removeTeacherSubject({
                        userClassId: teacher.userClasses[0].id,
                        subjectId: subject.id,
                      })
                    }
                  />
                ))}
              {(!teacher.userClasses ||
                (!!teacher.userClasses &&
                  !teacher.userClasses[0].subjects.length)) && (
                <small className="text-danger ml-1">No subject assigned</small>
              )}
            </>
          }
        />
        <span>
          <Box>
            <IconButton
              className="ml-2"
              onClick={handleOpenMenu}
              aria-label="settings"
              color="info"
            >
              <span className="material-icons">more_vert</span>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <MenuItem disabled>MENUS</MenuItem>
              <Divider className="my-1" />

              <MenuItem
                onClick={() => {
                  setSelectedTeacher(teacher);
                  setOpenAddTeacherSubjectForm(true);
                  handleCloseMenu();
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-2">add</span> Assign subject
              </MenuItem>
              <Divider className="my-1" />

              {/* <MenuItem
                onClick={() => {
                  handleCloseMenu();
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-2">edit</span> Edit
              </MenuItem>
              <Divider className="my-1" /> */}

              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  removeClassRoomTeacher({
                    userId: teacher.id,
                    classId: classRoom.id,
                  });
                }}
                className="text-danger font-weight-light"
              >
                <span className="material-icons mr-2">delete</span> Delete
              </MenuItem>
            </Menu>
          </Box>
        </span>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

const AddTeacherSubjectForm = (props) => {
  const {
    selectedTeacher,
    openAddTeacherSubjectForm,
    setOpenAddTeacherSubjectForm,
    gradeSubjects,
    addTeacherSubject,
    classRoomTeachers,
    isLoading,
  } = props;

  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);

  const filterSubjects = () => {
    if (!!gradeSubjects && !!selectedTeacher) {
      return gradeSubjects.filter(
        (gradeSubject) =>
          classRoomTeachers.findIndex(
            ({ userClasses }) =>
              userClasses[0].subjects.findIndex(
                (subject) => gradeSubject.subject.id === subject.id
              ) >= 0
          ) < 0
      );
    } else return [];
  };

  // const [formData, setFormData] = useState({
  //   userClassId: !!selectedTeacher ? selectedTeacher.userClasses[0].id : null,
  //   subjectId: "",
  // });

  const onSave = () => {
    addTeacherSubject(
      {
        // ...formData,
        userClassId: selectedTeacher.userClasses[0].id,
        subjectIds: selectedSubjectIds,
      },
      setSelectedSubjectIds,
      setOpenAddTeacherSubjectForm
    );
    // console.log(formData);
  };

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedSubjectIds(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Dialog open={openAddTeacherSubjectForm} fullWidth maxWidth="sm">
      <DialogTitle>
        Assign subject
        <IconButton
          className="close-btn mr-3"
          onClick={() => setOpenAddTeacherSubjectForm(false)}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ minHeight: "50vh" }}>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="demo-multiple-checkbox-label">
              Select subject
            </InputLabel>
            {/* <InputLabel id="subject">Select subject</InputLabel>
          <Select
            labelId="subject"
            name="subject"
            value={formData.subjectId}
            label="Select subject"
            onChange={(e) => {
              const subjectId = e.target.value;
              setFormData({ ...formData, subjectId });
            }}
          >
            {filterSubjects().map((gradeSubject) => (
              <MenuItem key={gradeSubject.id} value={gradeSubject.subject.id}>
                {gradeSubject.subject.name}
              </MenuItem>
            ))}
          </Select> */}
            <Select
              multiple
              value={selectedSubjectIds}
              onChange={handleRoleChange}
              input={
                <OutlinedInput id="selectSubjectIds" label="Select subject" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((subjectId) => (
                    <span key={subjectId} className="badge badge-primary">
                      {
                        filterSubjects()?.find((e) => e.subjectId === subjectId)
                          .subject.name
                      }
                    </span>
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {filterSubjects().map((gradeSubject) => (
                <MenuItem
                  key={gradeSubject.subjectId}
                  value={gradeSubject.subjectId}
                >
                  <Checkbox
                    checked={selectedSubjectIds.includes(
                      gradeSubject.subjectId
                    )}
                  />
                  <ListItemText primary={gradeSubject.subject.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* <div style={{ minHeight: "50vh" }}>
          <Autocomplete
            className="mt-2"
            fullWidth
            size="small"
            disablePortal
            defaultValue={null}
            value={selectedSubject}
            options={filterSubjects()}
            getOptionLabel={(option) => option.subject.name}
            renderInput={(params) => (
              <TextField {...params} label="Select subject" />
            )}
            onChange={(event, newValue) => {
              setSelectedSubject(newValue);
            }}
          />
        </div> */}
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-3">
        <Button disabled={isLoading} variant="contained" onClick={onSave}>
          {!isLoading ? "Save" : "Wait..."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
