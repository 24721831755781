import _ from "lodash";
import { Badge, Button, Chip, Fab, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SchoolLevelCard from "../components/SchoolLevelCard";
import LevelFormDialog from "../components/LevelFormDialog";
import { connect } from "react-redux";
import {
  getLevels,
  getSelectedSchool,
  getSchoolSbjects,
  getSchoolTeachers,
  getSchoolPeriods,
} from "../store/actions";
import { Link } from "react-router-dom";

const Levels = (props) => {
  const {
    store,
    getLevels,
    getSelectedSchool,
    getSchoolSbjects,
    getSchoolTeachers,
    getSchoolPeriods,
  } = props;

  const [levelFormDialogOpen, setLevelFormDialogOpen] = useState(false);
  const handleLevelFormDialogOpen = () => {
    setLevelFormDialogOpen(true);
  };
  const handleLevelFormDialogClose = () => {
    setLevelFormDialogOpen(false);
  };

  useEffect(() => {
    getLevels();
  }, []);

  useEffect(() => {
    if (!!store.selectedSchool.id) {
      getSchoolSbjects(store.selectedSchool.id);
      getSchoolTeachers(store.selectedSchool.id);
      getSchoolPeriods(store.selectedSchool.id);
    }
  }, [store.selectedSchool]);

  return (
    <>
      <div className="row mt-2 d-flex justify-content-center  ">
        {store &&
          store.selectedSchool.schoolLevels &&
          store.selectedSchool.schoolLevels.map((schoolLevel, index) => (
            <div
              key={"schoolLevel-" + schoolLevel.id.toString()}
              className={`mb-4 col-12 ${
                store.selectedSchool.schoolLevels.length === 4
                  ? "col-lg-3"
                  : store.selectedSchool.schoolLevels.length === 3
                  ? "col-lg-4"
                  : store.selectedSchool.schoolLevels.length === 2
                  ? "col-lg-6"
                  : ""
              }  `}
            >
              <SchoolLevelCard schoolLevel={schoolLevel} />
            </div>
          ))}
        {store &&
          store.selectedSchool.schoolLevels &&
          !store.selectedSchool.schoolLevels.length && (
            <div>No level added</div>
          )}
        {_.isEmpty(store.selectedSchool) && (
          <div className="row mt-5 d-flex justify-content-center ">
            Select school
          </div>
        )}
      </div>

      <LevelFormDialog
        handleLevelFormDialogClose={handleLevelFormDialogClose}
        levelFormDialogOpen={levelFormDialogOpen}
      />

      <div className="fab-container pr-3 pb-3">
        <Tooltip title="Add new level">
          <span className="fab-btn">
            <Fab
              color="primary"
              onClick={handleLevelFormDialogOpen}
              disabled={
                store &&
                store.lookups &&
                store.selectedSchool.schoolLevels &&
                store.lookups.levels &&
                store.selectedSchool.schoolLevels.length >=
                  store.lookups.levels.length
              }
            >
              <span className="material-icons">add</span>
            </Fab>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  getLevels,
  getSelectedSchool,
  getSchoolSbjects,
  getSchoolTeachers,
  getSchoolPeriods,
})(Levels);
