import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import DailyClassTimeTable from "../components/DailyClassTimeTable";
import WeeklyClassTimeTable from "../components/WeeklyClassTimeTable";
import { getSchoolTimeTableClasses } from "../store/actions";
import ClassTimeTableDialog from "../components/ClassTimeTableDialog";

const ClassTimeTables = (props) => {
  const { store, getSchoolTimeTableClasses } = props;
  useEffect(() => {
    if (store.selectedSchool && store.selectedSchool.id)
      getSchoolTimeTableClasses(store.selectedSchool.id);
  }, [store.selectedSchool]);

  return (
    <>
      <Card variant="outlined" className="level-card ">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            {store && store.appData.schoolTimeTableClasses && (
              <span>{store.appData.schoolTimeTableClasses.length}</span>
            )}{" "}
            Classes
          </Typography>
        </div>

        <CardContent>
          <List>
            <Divider />

            {store &&
              store.appData.schoolTimeTableClasses &&
              store.appData.schoolTimeTableClasses.map((item, index) => (
                <ClassItem key={index} item={item} />
              ))}
          </List>
        </CardContent>
        <CardActions
          className="pr-4 pb-2"
          style={{ position: "absolute", bottom: "0", right: "0" }}
        ></CardActions>
      </Card>
    </>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { getSchoolTimeTableClasses })(
  ClassTimeTables
);

const ClassItem = (props) => {
  const { item } = props;
  const [isTimeTableDialogOpen, setIsTimeTableDialogOpen] = useState(false);

  return (
    <Fragment>
      <ListItem disablePadding className="py-2">
        <ListItemAvatar>
          <Avatar>
            <span style={{ fontSize: "14px" }}>
              {item.acronym}
              {item.gradeId}
              {item.className}
            </span>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <span>
              {item.levelName}
              {item.gradeId}
              {item.className}
            </span>
          }
          secondary={
            <span>
              {item.numberOfSubjects} Subjects | {item.numberOfTeachers}{" "}
              Teachers | {item.numberOfStudents} Students
            </span>
          }
        />
        <span>
          <Tooltip title="View Timetable">
            <Button
              variant="outlined"
              size="small"
              className="mb-2"
              onClick={setIsTimeTableDialogOpen}
            >
              <span className="material-icons mr-1">visibility</span> View
            </Button>
          </Tooltip>
        </span>
      </ListItem>
      <Divider />
      <ClassTimeTableDialog
        schoolLevel={{
          levelId: item.levelId,
          level: {
            name: item.levelName,
          },
        }}
        schoolLevelGrade={{
          id: item.schoolLevelGradeId,
          grade: {
            name: item.gradeId,
          },
        }}
        classRoom={{
          id: item.classId,
          name: item.className,
        }}
        isTimeTableDialogOpen={isTimeTableDialogOpen}
        setIsTimeTableDialogOpen={setIsTimeTableDialogOpen}
      />
    </Fragment>
  );
};
