import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { addSchoolLevel } from "../store/actions";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
const TeacherCard = () => {
  return (
    <Card variant="outlined" className="d-flex mb-3">
      <CardMedia
        component="img"
        sx={{ width: 96 }}
        image={defaultProfile}
        alt="Hodari"
      />
      <Box>
        <CardContent className="d-flex justify-content-between py-0">
          <div>
            <Typography variant="subtitle1">Umugwaneza Hodari</Typography>
            <div className="d-flex flex-wrap align-items-center pb-0">
              <Typography
                color="text.secondary"
                className="d-flex flex-wrap align-items-center mb-2  mr-2"
              >
                <span className="mr-1">18 Subjects</span>
                <span className="material-icons d-none d-md-inline">
                  arrow_right
                </span>
                <span className="mr-1">5 Classrooms</span>
                <span className="material-icons d-none d-md-inline">
                  arrow_right
                </span>
                <span className="mr-1"> 17 Periods</span>
              </Typography>
            </div>
            <Button variant="outlined" size="small" className="mb-2">
              <span className="material-icons mr-1">visibility</span> View
            </Button>
          </div>
        </CardContent>
      </Box>
    </Card>
  );
};

export default TeacherCard;
