import React, { useState } from "react";
import Footer from "../components/Footer";
import logo from "../assets/logo.png";
import Login from "../components/Login";
import ResetPassword from "../components/ResetPassword";
import { connect } from "react-redux";

const Welcome = (props) => {
  // const { store } = props;

  const [showResetPassword, setShowResetPassword] = useState(false);

  return (
    <div id="Container">
      <div id="main-conent">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center pt-1  ">
            <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3  py-4 ">
              <div
                className=" pb-5"
                style={{
                  borderRadius: "15px",
                  border: "1px solid #17a2b8",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  className="d-flex justify-content-center align-items-center   login-header  py-3"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <img src={logo} className="brand_logo mr-2" alt="Logo" />
                  <div className="brand_logo_container ">
                    <strong style={{ fontSize: "24px" }}>
                      <span style={{ color: "#4bb9dd" }}>Keza</span>
                      Class
                    </strong>
                  </div>
                </div>
                {!showResetPassword && (
                  <Login setShowResetPassword={setShowResetPassword} />
                )}
                {showResetPassword && (
                  <ResetPassword setShowResetPassword={setShowResetPassword} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer" className="text-center pt-3">
        <Footer />
      </footer>
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps)(Welcome);
