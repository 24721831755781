import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";

import moment from "moment";

import React, { useEffect, useState } from "react";
import AttendanceDetailDialog from "./AttendanceDetailDialog";
const AttendanceCard = (props) => {
  const { attendance } = props;

  const [attendanceDetailDialogOpen, setAttendanceDetailDialogOpen] =
    useState(false);
  const handleAttendanceDetailDialogOpen = () => {
    setAttendanceDetailDialogOpen(true);
  };
  const handleAttendanceDetailDialogClose = () => {
    setAttendanceDetailDialogOpen(false);
  };

  return (
    <>
      <Card className="mt-3">
        <CardContent className="d-flex flex-wrap justify-content-center align-items-center pb-0">
          <Typography
            color="text.secondary"
            className="d-flex align-items-center mb-2  mr-2"
          >
            <span className="material-icons">watch_later</span>{" "}
            {moment(attendance.createdOn).format("LL")} Period:
            {attendance.periodNo}
          </Typography>
          {/* <span className="material-icons d-none d-md-inline">arrow_right</span> */}
          <Typography
            color="text.secondary"
            className="d-flex align-items-center mb-2  mr-2"
          >
            <span className="material-icons mr-1">school</span>{" "}
            {attendance.levelName}
            {attendance.gradeId}
            {attendance.className}
          </Typography>
          {/* <span className="material-icons d-none d-md-inline">arrow_right</span> */}
          <Typography
            color="text.secondary"
            className="d-flex align-items-center mb-2 mr-2"
          >
            <span className="material-icons mr-1">auto_stories</span>{" "}
            {attendance.subjectName}
          </Typography>
          {/* <span className="material-icons d-none d-md-inline">arrow_right</span> */}
          <Typography
            color="text.secondary"
            className="d-flex align-items-center mb-2  mr-2"
          >
            <span className="material-icons mr-1">person</span>
            {attendance.firstName} {attendance.lastName}
          </Typography>
        </CardContent>
        <CardActions className="d-flex justify-content-center flex-wrap ">
          <div className="mb-3 mr-4">
            <span className="badge badge-success mr-1">
              {attendance.attended} Attended
              <span className="badge badge-light mx-1">
                {attendance.attendedMale} Boys
              </span>
              <span className="badge badge-light mr-1">
                {attendance.attendedFemale} Girls
              </span>
            </span>
            <span className="badge badge-info mr-1">
              {attendance.lated} Lated
              <span className="badge badge-light mx-1">
                {attendance.latedMale} Boys
              </span>
              <span className="badge badge-light mr-1">
                {attendance.latedFemale} Girls
              </span>
            </span>
            <span className="badge badge-danger mr-1">
              {attendance.missed} Missed
              <span className="badge badge-light mx-1">
                {attendance.missedMale} Boys
              </span>
              <span className="badge badge-light mr-1">
                {attendance.missedFemale} Girls
              </span>
            </span>
          </div>

          <div className="mb-3 ">
            <Button
              onClick={handleAttendanceDetailDialogOpen}
              variant="outlined"
              size="small"
            >
              <span className="material-icons mr-1">visibility</span> View
            </Button>
          </div>
        </CardActions>
      </Card>

      <AttendanceDetailDialog
        attendance={attendance}
        handleAttendanceDetailDialogClose={handleAttendanceDetailDialogClose}
        attendanceDetailDialogOpen={attendanceDetailDialogOpen}
      />
    </>
  );
};

export default AttendanceCard;
