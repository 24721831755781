import React, { useEffect, useState } from "react";
import {
  removeSchoolLevel,
  getLevelGrades,
  removeSchoolLevelGrade,
} from "../store/actions";
import { connect, useDispatch } from "react-redux";
import types from "../store/types";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import GradeFormDialog from "./GradeFormDialog";
import ClassesCardDialog from "./ClassesCardDialog";
//import ClassFormDialog from "./ClassFormDialog";

const SchoolLevelCard = (props) => {
  const {
    store,
    schoolLevel,
    removeSchoolLevel,
    getLevelGrades,
    removeSchoolLevelGrade,
  } = props;

  //GradeFormDialog
  const [gradeFormDialogOpen, setGradeFormDialogOpen] = useState(false);
  const handleGradeFormDialogOpen = () => {
    setGradeFormDialogOpen(true);
  };
  const handleGradeFormDialogClose = () => {
    setGradeFormDialogOpen(false);
  };

  // Delete school level
  const handleRemoveSchoolLevel = () => {
    removeSchoolLevel(schoolLevel.id);
  };

  useEffect(() => {
    getLevelGrades(schoolLevel.levelId);
  }, []);

  return (
    <React.Fragment>
      <Card variant="outlined">
        <div
          className=" d-flex align-items-center text-white p-2"
          style={{ backgroundColor: "#0f7197" }}
        >
          <Typography sx={{ fontSize: 16 }} className="pl-3" gutterBottom>
            <strong>{schoolLevel.level.name}</strong> level
          </Typography>

          <Button
            variant="contained"
            className="ml-2"
            color="info"
            size="small"
            onClick={handleGradeFormDialogOpen}
            disabled={
              !!(
                schoolLevel.schoolLevelGrades &&
                store.lookups[`levelGrades_${schoolLevel.levelId}`] &&
                schoolLevel.schoolLevelGrades.length >=
                  store.lookups[`levelGrades_${schoolLevel.levelId}`].length
              )
            }
          >
            Add Grade
          </Button>
        </div>
        <Divider />

        <CardContent className="level-card ">
          <List>
            <Divider />
            {schoolLevel.schoolLevelGrades &&
              schoolLevel.schoolLevelGrades.map((schoolLevelGrade, index) => (
                <SchoolLevelGrade
                  key={
                    schoolLevel.id.toString() + schoolLevelGrade.id.toString()
                  }
                  schoolLevel={schoolLevel}
                  schoolLevelGrade={schoolLevelGrade}
                  removeSchoolLevelGrade={removeSchoolLevelGrade}
                />
              ))}
          </List>
          {((schoolLevel.schoolLevelGrades &&
            !schoolLevel.schoolLevelGrades.length) ||
            (schoolLevel && !schoolLevel.schoolLevelGrades)) && (
            <div className="text-center">No grade added</div>
          )}
        </CardContent>
        <CardActions className="py-4 ">
          <div
            className="pr-4 pb-2"
            style={{ position: "absolute", right: "0" }}
          >
            <Button
              onClick={handleRemoveSchoolLevel}
              size="small"
              color="error"
              disabled={
                !!(
                  schoolLevel &&
                  schoolLevel.schoolLevelGrades &&
                  schoolLevel.schoolLevelGrades.length
                )
              }
            >
              Delete
            </Button>
          </div>
        </CardActions>
      </Card>

      <GradeFormDialog
        handleGradeFormDialogClose={handleGradeFormDialogClose}
        gradeFormDialogOpen={gradeFormDialogOpen}
        schoolLevel={schoolLevel}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, {
  removeSchoolLevel,
  getLevelGrades,
  removeSchoolLevelGrade,
})(SchoolLevelCard);

const SchoolLevelGrade = (props) => {
  const { schoolLevel, schoolLevelGrade, removeSchoolLevelGrade } = props;
  const dispatch = useDispatch();

  //ClassFormDialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    dispatch({ type: types.SET_CLASS_ROOMS, data: [] });

    setIsDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItem className=" mb-1 mt-1" style={{ backgroundColor: "#e4eef5" }}>
        <ListItemAvatar>
          <Avatar>
            <span>
              {schoolLevel.level.acronym}
              {schoolLevelGrade.grade.name}
            </span>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${schoolLevel.level.name} ${schoolLevelGrade.grade.name}`}
          secondary={`${schoolLevelGrade.classes.length} Class${
            schoolLevelGrade.classes.length > 1 ? "es" : ""
          }`}
        />

        <Box>
          <IconButton
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
            color="info"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem disabled>MENUS</MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                openDialog();
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-2">open_in_new</span> Classes
            </MenuItem>
            <Divider className="my-1" />

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                removeSchoolLevelGrade(schoolLevel.id, schoolLevelGrade.id);
              }}
              className="text-danger font-weight-light"
            >
              <span className="material-icons mr-2">delete</span> Delete
            </MenuItem>
          </Menu>
        </Box>
      </ListItem>
      <Divider className="my-1" />

      <ClassesCardDialog
        schoolLevel={schoolLevel}
        schoolLevelGrade={schoolLevelGrade}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
      />
    </React.Fragment>
  );
};
