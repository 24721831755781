import { Avatar, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AttendanceCard from "../components/AttendanceCard";

import { getSchoolAttendances } from "../store/actions";
const SchoolAttendances = (props) => {
  const { store, getSchoolAttendances } = props;

  useEffect(() => {
    if (store.selectedSchool && store.selectedSchool.id)
      getSchoolAttendances(store.selectedSchool.id);
  }, [store.selectedSchool]);
  return (
    <div className=" mt-2">
      <div className="mt-2 row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left">
          Attendances
        </div>
        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1 "
                >
                  {role.name}
                </span>
              ))}
            {store.selectedSchool.schoolLevels && (
              <Chip
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar alt="school">
                    <span className="material-icons">school</span>
                  </Avatar>
                }
                label={store.selectedSchool.name.toUpperCase()}
                className="mr-1"
              />
            )}
          </span>
        </div>
      </div>

      {store &&
        store.appData.teacherAttendances &&
        store.appData.teacherAttendances.map((attendance, index) => (
          <AttendanceCard key={index} attendance={attendance} />
        ))}

      <div className="text-center">
        {store &&
          store.appData.teacherAttendances &&
          !store.appData.teacherAttendances.length && (
            <div>No attendances found</div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { getSchoolAttendances })(
  SchoolAttendances
);
