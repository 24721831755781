import React from "react";
import { connect } from "react-redux";

import HeadTeachers from "./HeadTeachers";
import Schools from "./Schools";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Fab,
  IconButton,
  Tooltip,
} from "@mui/material";

import { Link, Route, Switch, useLocation, Redirect } from "react-router-dom";

const Security = (props) => {
  const { store } = props;
  const location = useLocation();
  return (
    <div className="">
      <div className=" row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left px-0 px-md-2 ">
          <div className="text-center mt-2 ml-2 text-md-left">Security</div>
        </div>
        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1 "
                >
                  {role.name}
                </span>
              ))}
          </span>
        </div>
      </div>
      <div className=" row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left">
          <Link to="/in/security/schools" className="mr-2 ">
            <Button
              size="small"
              className={`${
                location.pathname.includes("/in/security/schools")
                  ? "outlined active  mb-2"
                  : " mb-2"
              } `}
            >
              Schools
            </Button>
          </Link>
          <Link to="/in/security/head-teachers" className="mr-2">
            <Button
              size="small"
              className={`${
                location.pathname.includes("/in/security/head-teachers")
                  ? "outlined active mb-2"
                  : "mb-2"
              } `}
            >
              Head Teachers
            </Button>
          </Link>
        </div>
      </div>

      <Switch>
        <Route path="/in/security/schools" component={Schools} />
        <Route path="/in/security/head-teachers" component={HeadTeachers} />
        <Route
          path="/in/security"
          component={() => <Redirect to="/in/security/schools" />}
        />
      </Switch>
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps)(Security);
