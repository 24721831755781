import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { addSchoolLevel } from "../store/actions";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
const AttendanceDetailCard = (props) => {
  const { student, attendance } = props;
  return (
    <Card variant="outlined" className="d-flex mb-3">
      <CardMedia
        component="img"
        sx={{ width: 60 }}
        image={student.profilePicture || defaultProfile}
        alt="Hodari"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }} className="py-0">
          <Typography variant="subtitle1">
            {student.firstName} {student.lastName}
          </Typography>
          {student.statusId === 1 && (
            <Typography variant="subtitle1" className="d-flex text-success">
              <span className="material-icons">done</span> Attended
            </Typography>
          )}
          {student.statusId === 2 && (
            <Typography variant="subtitle1" className="d-flex text-info">
              <span className="material-icons mr-1">rule</span> Lated
            </Typography>
          )}
          {student.statusId === 3 && (
            <Typography variant="subtitle1" className="d-flex text-danger">
              <span className="material-icons">close</span> Missed
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default AttendanceDetailCard;
