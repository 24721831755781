import { Avatar, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AttendanceCard from "../components/AttendanceCard";

import { getTeacherAttendances } from "../store/actions";
const Attendances = (props) => {
  const { store, getTeacherAttendances } = props;

  useEffect(() => {
    getTeacherAttendances();
  }, []);
  return (
    <div className=" mt-2">
      {/* <div className="mt-2 row ">
        <div className="col-12  col-md-6 my-1 text-center text-md-left">
          Attendances
        </div>
        <div className="col-12 col-md-6 my-1 text-center text-md-right order-first order-md-last">
          <span>
            {store &&
              store.auth.user &&
              store.auth.user.roles.map((role) => (
                <span
                  key={role.id}
                  className="badge badge-pill badge-secondary py-1 "
                >
                  {role.name}
                </span>
              ))}
            {store.selectedSchool.schoolLevels && (
              <Chip
                color="primary"
                variant="outlined"
                avatar={
                  <Avatar alt="school">
                    <span className="material-icons">school</span>
                  </Avatar>
                }
                label={store.selectedSchool.name.toUpperCase()}
                className="mr-1"
              />
            )}
          </span>
        </div>
      </div> */}

      {store &&
        store.appData.teacherAttendances &&
        store.appData.teacherAttendances.map((attendance, index) => (
          <AttendanceCard key={index} attendance={attendance} />
        ))}
    </div>
  );
};

const mapStateToProps = (store) => {
  return { store };
};

export default connect(mapStateToProps, { getTeacherAttendances })(Attendances);
